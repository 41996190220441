import {AfterViewInit, Component, EventEmitter, Input, IterableDiffers, OnInit, Output} from '@angular/core';
import { Template } from '@app/template.service';
import {TranslateService} from '@ngx-translate/core';
import { BaseService } from '@app/base.service';
import {UtilityService} from '@app/shared/utility.service';
import {AuthenticationService} from '@app/auth/login/login.service';
import {Router} from '@angular/router';
import {TemplateInitModule} from '@app/templateInit.module';
import { interval } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

   title = null;
   user;
   iterableDiffer;
   lenguage = 'it';
   sub;

  constructor(
    private translate: TranslateService,
    private utilityService: UtilityService,
    private authenticationService: AuthenticationService,
    //private headerService: HeaderService,
    private router: Router,
    private IterableDiffer: IterableDiffers
  ) {
    this.iterableDiffer = this.IterableDiffer.find([]).create(null);
  }


  ngOnInit() {
    this.user = this.utilityService.user;
    console.log(this.user);
    this.sub = interval(10000).subscribe(x => { });
  }

  ngAfterViewInit() {
    Template.initDDL();
  }

  getNotification() {

  }

  useLang(lang: string) {
    if (lang === 'en') {
      this.lenguage = 'gb';
    } else {
      this.lenguage = lang;
    }
    this.translate.use(lang);
  }

  setIconLenguage() {
      return 'flag-icon flag-icon-' + this.lenguage;
  }

  // getNotification() {
  //
  // }
  logOut() {
    this.authenticationService.LogOut().subscribe((res: any) => {
          this.router.navigate(['/login']);
    });
  }
}
