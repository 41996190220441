import {NgModule} from '@angular/core';
import {DetailsNavComponent} from '@app/shared/detailsNavigation/detailsNav.component';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [DetailsNavComponent],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule
  ],
  exports: [DetailsNavComponent]
})
export class DetailsNavModule {}
