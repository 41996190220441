import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

@Pipe({name: 'numberPipe'})
export class NumberPipe implements PipeTransform {

  constructor(public decimalPipe: DecimalPipe) {}

  transform(value: any) {
    let initial = Array.from(this.decimalPipe.transform(value, '1.2-2'));

    initial = initial.filter(this.deleteComma);

    let result = '';

    initial.forEach((res: any) => result += res);

    return result;
  }

  deleteComma(value) {
    return value !== ',';
  }
}
