
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  Directive, DoCheck,
  ElementRef, Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[DmSelect]'
})
export class SelectDirective implements OnInit, OnChanges, AfterContentInit, AfterViewChecked, AfterContentChecked, DoCheck {

  constructor(private el: ElementRef) {}

  @Input() refresh: any;
  @Input() disabled = false;

  ngOnInit(): void {
    // $(this.el.nativeElement).formSelect();
    this.el.nativeElement.onchange = () => { console.log('ciao');  $(this.el.nativeElement).formSelect(); };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.el.nativeElement.onchange = () => { console.log('ciao');  $(this.el.nativeElement).formSelect(); };
    if (this.disabled) {
      $(this.el.nativeElement).prop('disabled', true);
      $(this.el.nativeElement).formSelect();
    } else {
      $(this.el.nativeElement).removeAttr('disabled');
      $(this.el.nativeElement).formSelect();
    }
  }
  ngAfterContentInit(): void {
    this.el.nativeElement.onchange = () => { console.log('ciao');  $(this.el.nativeElement).formSelect(); };
    $(this.el.nativeElement).formSelect();
  }

 ngAfterViewChecked(): void {
  // risponde quando angular controlla il contenuto della direttiva
 }

  ngAfterContentChecked(): void {

  }

  ngDoCheck(): void {
  }
}
