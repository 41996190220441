import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportTableComponent } from './report-table.component';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {DirectiveModule} from "@app/shared/directive/directive.module";

@NgModule({
  declarations: [ReportTableComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  exports: [ReportTableComponent]
})
export class ReportTableModule { }
