import {
  Component,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormArrayName,
  FormBuilder,
  AbstractControl
} from '@angular/forms';
import { EventEmitter } from 'events';
import { FornitoriService } from '../fornitori.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UtilityService} from '@app/shared/utility.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {FornitoriListaModel} from '@app/shared/models/db_tables/fornitori-lista.model';
import Swal from 'sweetalert2';
import {ResizedEvent} from 'angular-resize-event';
import {HeightTabDirective} from '@app/shared/directive/heightTab.directive';

declare const $: any;
declare const M: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnChanges {

  @Output() AddFornitori = new EventEmitter();
  @ViewChild('cap') cap: ElementRef;
  @ViewChild('tel') tel: ElementRef;
  @ViewChild('mail') email: ElementRef;
  @ViewChild('div') chips: ElementRef;
  @ViewChild('height') Heightdiv: ElementRef;
  @ViewChild('input') radioButton: ElementRef;
  @ViewChild('inputPaese') paese: ElementRef;
  @ViewChild('chipMercio') chipMercio: ElementRef;
  @ViewChild('chipProdotti') chipProdotti: ElementRef;
  @ViewChild(HeightTabDirective) heightDirective: HeightTabDirective;

  constructor( private fornitoriservice: FornitoriService,
               private translate: TranslateService,
               private utilityservice: UtilityService,
               private formBuilder: FormBuilder,
               private activatedRoute: ActivatedRoute,
               private utilityService: UtilityService,
               private router: Router) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {

      this.translate.get('breadcrumbs.suppliers.list').subscribe((app: string) => {
        utilityService.Navigation[this.basePosBreadcrumbs] = {title: app, path: '/suppliers'};
      });

      if (!this.supplierid) {
        this.translate.get('breadcrumbs.suppliers.new').subscribe((app: string) => {
          utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/suppliers/list'};
        });
      }

      $('.select').formSelect();

      this.translate.get('Suppliers.New.currency').subscribe((app: string) => {
        this.valuta = app;
      });
    });

  }

  @ViewChild('Currency') Currency;
  modificata = false;

  disableautocomplete = true;

  titleButton = 'Salva';

  fornitoriform: FormGroup = null;

  referenteform: FormGroup = null;

  comunication: string;

  supplierid: number;
  fornitore: FornitoriListaModel;


  updateconti = false;

  email2 = '';

  viewAddIndirizzo = false;
  viewAddConto = false;
  check = 'none';
  dettaglio = false;

  currencies = [];
  terminipagamento;
  terminiresa;

  fornitori = {
    referents: [],
    bank_accounts: {},
    email_type: '',
    notification_level: '',
    tag: [],
    addresses: {},
    email2: '',
    price_list: {
    },
    commercial_categories: [],
    categories: []
  };

 indirizzo = {
   via: '',
   citta: '',
   provincia: '',
   cap: null,
   paese: '',
   description: '',
   default: false,
 };
 nuovoAddress = false;

 tagRef = [];

 conto = {
   bank_name: '',
   iban: ''
 };
  nuovoConto = false;

 listaindirizzi = [];
 listaindirizziInvariata = [];

 listaconti: any = [];
 listacontiInvatiata: any = [];

 modifica = false;

 app;

 Disable: boolean;

 posBreadcrumbs;
 basePosBreadcrumbs;
 valuta;

 radioButtonActivated = true;

 email2Changed = false;

 user;

 suppliersnav = [];

  categoriesMerciologiche = [];
  categoriesProdotto = [];

  CatMerciologiche =  {};
  CatProdotto =  {};
  index;

  adressesPatch = [];
  bankPatch = [];

  ngOnInit() {
      this.user =  this.utilityService.user;
      this.initForms();
      this.getCategories();
      this.comunication = 'none';

      $('.tabs').tabs();

      $('.tooltipped').tooltip();

      this.basePosBreadcrumbs = this.utilityService.Navigation.length - 1;
      // this.baseBreadcrumbs();

    this.activatedRoute
      .paramMap
      .subscribe(
        (paramsMap: ParamMap) => {
          // se l'url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
          this.supplierid = +paramsMap.get('id');
              if (this.supplierid) {

                this.posBreadcrumbs = this.utilityService.Navigation.length;

                this.getSupplierById();

              } else {
                this.radioButtonActivated = false;
                this.translate.get('breadcrumbs.suppliers.new').subscribe((app: string) => {
                  this.posBreadcrumbs = this.utilityService.Navigation.length;
                  this.utilityService.Navigation.push({title: app, path: '/suppliers/new'});
                });

                this.fornitoriform.enable();
                this.referenteform.enable();
              }

              this.translate.get('Suppliers.New.currency').subscribe((app: string) => {
                this.valuta = app;
              });
            });


      this.fornitoriservice.GetDDlValuta().subscribe((res) => {
        res.data.forEach((r) => {
          this.currencies.push({label: r.label, value: r.id});
        });
       });
      this.fornitoriservice.GetDDlterminiConsegna().subscribe((res) => {
          this.terminiresa = res.data;
        });
      this.fornitoriservice.GetDDlTerminiPagamento().subscribe((res) => {
          this.terminipagamento = res.data;
        });
  }

  getSupplierById() {
    this.fornitoriservice.getSupplierById(this.supplierid).subscribe((res: any) => {
      this.fornitore = res.data;
      this.suppliersnav = res.stats.resultSetIDs;
      this.index = res.stats.resultSetIDs.indexOf(this.supplierid) + 1;

      this.utilityService.Navigation.push({title: this.fornitore.r_sociale, path: '/suppliers/' + this.supplierid});

      this.email2 = this.fornitore.email2;
      this.fornitoriform.patchValue(this.fornitore);
      this.fornitoriform.controls.currency.setValue(this.fornitore.price_list.currency);
      if (this.fornitore.referents.length > 0) {
        this.referenteform.patchValue(this.fornitore.referents[0]);
        this.fornitore.referents.forEach((ref: any) => {
          ref.tags.forEach((tags) => {
            this.tagRef.push({tag: tags});
          });
        });
      }
      if (this.fornitore.commercial_categories) {
        this.categoriesMerciologiche = [];
        this.fornitore.commercial_categories.forEach((cat) => {
          this.categoriesMerciologiche.push({tag: cat});
        });
        console.log('Merciologicheeeee', this.categoriesMerciologiche);
      }
      if (this.fornitore.categories) {
        this.categoriesProdotto = [];
        this.fornitore.categories.forEach((cat: any) => {
          this.categoriesProdotto.push({tag: cat.label});
        });
      }
      this.getListAddres('start');
      this.listaconti = this.fornitore.bank_accounts;
      this.listacontiInvatiata = JSON.parse(JSON.stringify(this.fornitore.bank_accounts));
      this.check = this.fornitore.email_type;
      $('select').formSelect(); // da fare nella direttiva
      this.dettaglio = true;
      this.Disable = true;
      this.titleButton = 'Modifica';

      this.check = this.fornitore.email_type;
      this.comunication = this.fornitore.notification_level;

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  getCategories() {
    this.fornitoriservice.getCategoriesCommercia().subscribe((res) => {
      console.log(res);
      res.data.forEach((cat) => {
        this.CatMerciologiche[cat] = null;
      });
    });
    this.fornitoriservice.getCategoriesProducts().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatProdotto[cat.label] = null;
      });
    });
  }

  validateFormControl(abstractControl: AbstractControl, modificata): object {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  ActivateLabel(dettaglio) {
    if (dettaglio) {
      return 'active';
    }
  }

  ngAfterViewInit(): void {}

  initForms() {
    this.fornitoriform = this.formBuilder.group({
      r_sociale: this.utilityservice.standardFormControl(true, true),
      piva: this.utilityservice.standardFormControl(true, true),
      tel: this.utilityservice.telephoneFormControl(true, 10, '', true),
      email: this.utilityservice.emailFormControl(),
      website: this.utilityservice.standardFormControl(false, true),
      delivery_term: this.utilityservice.standardFormControl(false, true),
      payment_term: this.utilityservice.standardFormControl(false, true),
      currency: this.utilityservice.standardFormControl(false, true),
      fax: this.utilityService.telephoneFormControl(true, 10, '', true),
      ritardi: this.utilityService.standardFormControl(false, false),
      scadenze: this.utilityService.standardFormControl(false, false)
  });

    this.referenteform = this.formBuilder.group({
      first_name: this.utilityservice.standardFormControl(),
      last_name: this.utilityservice.standardFormControl(),
      email: this.utilityservice.emailFormControl(),
      tags: this.utilityservice.standardFormControl(false),
    });
  }

  checklength(maxlength) {
    if (maxlength === 5) {
      if (this.cap.nativeElement.value.length > 5) {
        this.cap.nativeElement.value = this.cap.nativeElement.value.slice(0, 5);
      }
    }
  }

  addFornitori(option) {
    this.modificata = true;

    if (this.titleButton === 'Modifica') {
      // Abilità la modifica del form
      if (this.user.group !== 'supplier') {
        this.fornitoriform.enable();
        this.referenteform.enable();
        this.Disable = false;
        this.radioButtonActivated = false;
        const children = Array.from(this.chips.nativeElement.children);
        children.forEach( (res: any) => res.disabled = this.Disable);
        const childrenMerciologiche = Array.from(this.chipMercio.nativeElement.children);
        childrenMerciologiche.forEach( (res: any) => res.disabled = this.Disable);
        const childrenProdotto = Array.from(this.chipProdotti.nativeElement.children);
        childrenProdotto.forEach( (res: any) => res.disabled = this.Disable);
        this.titleButton = 'Salva';
        $('select').formSelect();
        this.disableautocomplete = false;
        this.modifica = true;
      } else {
        this.radioButtonActivated = false;
        this.titleButton = 'Salva';
      }
    } else {

      console.log(this.fornitoriform);
      this.fornitoriform.enable();
      if (this.fornitoriform.valid && this.comunication !== '') {

        if (this.dettaglio) {
          // modifica
          this.salvaModifiche();
        } else {
          this.newSupplier();
        }
      } else {
        M.toast({html: 'Compila tutti i campi'});
      }

      this.fornitori = {
        referents: [],
        bank_accounts: {},
        email_type: '',
        notification_level: '',
        tag: [],
        addresses: {},
        email2: '',
        price_list: {
        },
        commercial_categories: [],
        categories: []
      };
    }
  }

  newSupplier() {

    const currency = {
      currency: this.fornitoriform.controls.currency.value
    };

    this.fornitori = this.fornitoriform.value;
    this.fornitori.referents = [];
    this.fornitori.referents.push(this.referenteform.value);
    this.fornitori.bank_accounts = this.listaconti;
    this.fornitori.price_list = currency;

    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    this.fornitori.referents[0].tags = chip;

    const chipmercio = this.chipMercio.nativeElement.textContent.split('close');
    chipmercio.pop();
    this.fornitori.commercial_categories = chipmercio;

    const chiprodotto = this.chipProdotti.nativeElement.textContent.split('close');
    chiprodotto.pop();
    this.fornitori.categories = chiprodotto;

    /*
    if (this.check !== 'none') {
      if (this.comunication !== 'none') {
        this.fornitori.email_type = this.check;
      }
      this.fornitori.notification_level = this.comunication;
      if (this.comunication === 'secondary_mail') {
        this.fornitori.email2 = this.email.nativeElement.value;
      }
    }
*/
    this.fornitori.email_type = this.check;
    this.fornitori.notification_level = this.comunication;

    if (this.check === 'none') {
      this.fornitori.email2 = null;
    }

    if (this.comunication === 'secondary_mail') {
      if (this.email2.includes('@')) {
        this.fornitori.email2 = this.email2;
      } else {
        M.toast({html: 'Email secondaria non valida'});
        return;
      }
    }

    if (this.comunication === 'main_mail') {
      this.fornitori.email2 = this.fornitoriform.get('email').value;
    }

    if (this.comunication === 'referent_mail') {
      this.fornitori.email2 = this.referenteform.get('email').value;
    }

    this.fornitori.addresses = this.listaindirizzi;

    console.log(this.fornitoriform);
    if (this.referenteform.controls.email.value !== '') {
      if (this.referenteform.controls.email.valid) {
          this.fornitoriservice.CreateSupplier(this.fornitori).subscribe((res) => {
            this.router.navigate(['/dashboard/suppliers/' + res.data.id]);
          });
      } else {
        M.toast({html: 'Email referente non valida'});
      }
    } else {
      this.fornitoriservice.CreateSupplier(this.fornitori).subscribe((res) => {
        this.router.navigate(['/dashboard/suppliers/' + res.data.id]);
      });
    }
  }

  salvaModifiche() {
    const dirtyFields: any = {};
    const dirtyFieldsRef: any = {};
    const currency = {
      currency: this.fornitoriform.controls.currency.value
    };
    if (currency.currency !== 'none') {
      dirtyFields.price_list = currency;
    }
    for (const control in this.fornitoriform.controls) {

      if (this.fornitoriform.get(control).dirty) {
        dirtyFields[control] = this.fornitoriform.get(control).value;
      }
    }

    if (this.email2Changed) {
      dirtyFields.email_type = this.check;
      dirtyFields.notification_level = this.comunication;

      if (this.check === 'none') {
        dirtyFields.email2 = null;
      }


      if (this.comunication === 'secondary_mail') {
        dirtyFields.email2 = this.email2;
      }

      if (this.comunication === 'main_mail') {
        dirtyFields.email2 = this.fornitoriform.get('email').value;
      }

      if (this.comunication === 'referent_mail') {
        dirtyFields.email2 = this.referenteform.get('email').value;
      }
    }

    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    dirtyFieldsRef.tags = chip;
    const chipmercio = this.chipMercio.nativeElement.textContent.split('close');
    chipmercio.pop();
    dirtyFields.commercial_categories = chipmercio;
    const chiprodotto = this.chipProdotti.nativeElement.textContent.split('close');
    chiprodotto.pop();
    dirtyFields.categories = chiprodotto;



    for (const control in this.referenteform.controls) {
      // if (this.referenteform.get(control).dirty) {

      dirtyFieldsRef[control] = this.referenteform.get(control).value;
      // }
    }

    if (Object.keys(dirtyFieldsRef).length > 0) {
      dirtyFields.referents = [dirtyFieldsRef];
    }

    if (dirtyFieldsRef) {
      this.fornitoriservice.UpdateReferents(dirtyFieldsRef, this.supplierid, this.fornitore.referents[0].id).subscribe();
    }
    if (dirtyFields) {
      this.fornitoriservice.UpdateSupplier(dirtyFields, this.supplierid).subscribe(() => {
        this.fornitoriform.disable();
        this.referenteform.disable();
        this.titleButton = 'Modifica';
        this.disableautocomplete = true;
        this.Disable = true;
        this.modifica = false;
        this.bankPatch = [];
        this.adressesPatch = [];
        this.radioButtonActivated = true;
        $('select').formSelect();
      });
    }
  }

  AnnullaModifche() {
    this.fornitoriservice.getSupplierById(this.supplierid).subscribe((res: any) => {
      this.fornitore = res.data;
      this.listaindirizzi = res.data.addresses;
      this.listaconti = res.data.bank_accounts;
      this.check = res.data.email_type;
      this.fornitoriform.reset(this.fornitore);
    });

    if (this.adressesPatch.length > 0) {
      console.log(this.listaindirizziInvariata);
      this.listaindirizziInvariata.forEach((indirizzo) => {
        this.adressesPatch.forEach((id) => {
         if (indirizzo.id === id.id && id.action === 'update') {
           this.fornitoriservice.UpdateAddress(indirizzo, this.supplierid, id.id).subscribe(() => {
             this.getSupplierById();
           });
         }
         if (indirizzo.id === id.id && id.action === 'delete') {
           this.fornitoriservice.CreateAddress(indirizzo, this.supplierid).subscribe(() => {
             this.getSupplierById();
           });
         }
         if (id.action === 'add') {
           this.fornitoriservice.DeleteAddress(this.supplierid, id.id).subscribe(() => {
             this.getSupplierById();
           });
         }
        });
      });
      this.adressesPatch = [];
    }

    if (this.bankPatch.length > 0) {
      this.listacontiInvatiata.forEach((banche) => {
        this.bankPatch.forEach((id) => {
          if (banche.id === id.id && id.action === 'update') {
            this.fornitoriservice.Updatebank(banche, this.supplierid, id.id).subscribe();
            this.getSupplierById();
          }
          if (banche.id === id.id && id.action === 'delete') {
            this.fornitoriservice.Updatebank(banche, this.supplierid, id.id).subscribe();
            this.getSupplierById();
          }
          if (id.action === 'add') {
            this.fornitoriservice.DeleteBank(this.supplierid, id.id).subscribe();
            this.getSupplierById();
          }
        });
      });
      this.bankPatch = [];
    }

    const children = Array.from(this.chips.nativeElement.children);

    this.referenteform.patchValue(this.fornitore.referents[0]);

    for (const control in children) {
      if (this.chips.nativeElement.children[control].tagName === 'INPUT') {
        this.chips.nativeElement.children[control].value = null;
      }
    }

    this.tagRef = [];
    this.fornitore.referents.forEach((ref: any) => {
      ref.tags.forEach((tags) => {
        this.tagRef.push({tag: tags});
      });
    });

    this.titleButton = 'Modifica';
    this.disableautocomplete = true;
    this.Disable = true;

    children.forEach( (res: any) => res.disabled = this.Disable);
    this.modifica = false;
    this.fornitoriform.disable();
    this.referenteform.disable();
    $('select').formSelect();

    this.radioButtonActivated = true;
  }

  clean() {
    $('select').formSelect();
    $('.chips').chips({
      data: []
    });
    this.fornitoriform.reset();
    this.referenteform.reset();
    this.Currency.clear();
    this.listaconti = [];
    this.listaindirizzi = [];
    this.check = 'none';

    const children = {} = Array.from(this.chips.nativeElement.children);
    for ( const control in children) {
      if (this.chips.nativeElement.children[control].tagName === 'INPUT') {
        this.chips.nativeElement.children[control].value = null;
      }
    }

    this.tagRef = [];
    // $('.chips').chips();
    this.check = 'none';
  }

  addIndirizzo() {
    if (this.indirizzo.via !== '' && this.indirizzo.citta !== '' && this.indirizzo.provincia !== ''
      && (this.indirizzo.cap !== null && this.cap.nativeElement.value.length === 5) && this.indirizzo.paese !== '') {
      if (this.dettaglio) {
        this.fornitoriservice.CreateAddress(this.indirizzo, this.supplierid).subscribe((res: any) => {
          console.log(res);
          this.adressesPatch.push({id: res.data.id, action: 'add'});
          this.getListAddres();
        });
      } else {
        this.listaindirizzi.push(this.indirizzo);
      }

      this.indirizzo = {
        via: '',
        citta: '',
        provincia: '',
        cap: null,
        paese: '',
        description: '',
        default: false
      };
      this.paese.nativeElement.value = ''; // Svuoto input da codice che con l'ngmodal non svuota
      this.nuovoAddress = false;
      this.viewAddIndirizzo = false;
    } else {
      console.log('non entrato', this.indirizzo);
    }
  }

  deleteIndirizzo(i, id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questa voce del listino',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'La voce del listino è stata eliminata con successo',
          'success'
        );
        this.adressesPatch.push({id: id, action: 'delete'});
        this.fornitoriservice.DeleteAddress(this.supplierid, id).subscribe((res) => {
          this.getListAddres();
        });
      }
      });
  }

   UpdateIndirizzo(id, label, value) {
    if (this.dettaglio) {
      if (this.adressesPatch.length > 0) {
        this.adressesPatch.forEach((ID) => {
          if (ID.id !== id) {
            this.adressesPatch.push({id: id, action: 'update'});
          }
        });
      } else {
        this.adressesPatch.push({id: id, action: 'update'});
      }
      const patch = {};
      patch[label] = value;
      this.fornitoriservice.UpdateAddress(patch, this.supplierid, id).subscribe();
    }
  }

  ViewAdd() {
    this.nuovoAddress = true;
  }

  getListAddres(command?) {
    this.fornitoriservice.GetListAddress(this.supplierid).subscribe((ris: any) => {
      this.listaindirizzi = ris.data;
      if (command === 'start') {
        this.listaindirizziInvariata = JSON.parse(JSON.stringify(this.listaindirizzi));
      }
    });
  }

  addConto() {
    if (this.conto.bank_name !== '' && this.conto.iban !== '' ) {
      if (this.dettaglio) {
        this.fornitoriservice.CreateBank(this.conto, this.supplierid).subscribe((res: any) => {
          this.bankPatch.push({id: res.data.id, action: 'add'});
          this.getListBank();
        });
      } else {
        this.listaconti.push(this.conto);
      }

      this.conto = {
        iban: '',
        bank_name: ''
      };

      this.nuovoConto = false;
      this.viewAddConto = false;
    }
  }

  deleteConto(id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questa voce del listino',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'La voce del listino è stata eliminata con successo',
          'success'
        );
        this.bankPatch.push({id: id, action: 'delete'});
        this.fornitoriservice.DeleteBank(this.supplierid, id).subscribe(() => {
          this.getListBank();
        });
      }
    });
  }

  UpdateConto(Conto) {
    if (this.bankPatch.length > 0) {
      this.bankPatch.forEach((ID) => {
        if (ID.id !== Conto.id) {
          this.bankPatch.push({id: Conto.id, action: 'update'});
        }
      });
    } else {
      this.bankPatch.push({id: Conto.id, action: 'update'});
    }
    this.fornitoriservice.Updatebank(Conto, this.supplierid, Conto.id).subscribe(() => {
      this.getListBank();
      this.updateconti = false;
    });
  }

  getListBank(command?) {
    this.fornitoriservice.GetListBank(this.supplierid).subscribe((ris) => {
      this.listaconti = ris.data;
      if (command === 'start') {
        this.listacontiInvatiata = JSON.parse(JSON.stringify(this.listaconti));
      }
    });
  }

  ViewAddConto() {
    this.nuovoConto = true;
  }

// Funzione per autocomplete
  Termini(event, type) {
    console.log('noooooooooooooooooo', event);
    if (type === 'currency') {
      this.fornitoriform.controls.currency.setValue(event.value);
      console.log('ciao', event);
    }
  }

  buttonActivation() {
    if (this.dettaglio) {
      if (!this.modifica) {
        return 'disable';
      }
    }
  }

  disable() {
    if (this.Disable) {
      return 'dotted';
    }
  }

  onResize(resize: ResizedEvent) {
    console.log('ciao', resize);
    this.heightDirective.setHeight(resize.newHeight);
  }

  baseBreadcrumbs() {
    this.translate.get('breadcrumbs.suppliers.list').subscribe((app: string) => {

      this.utilityService.Navigation.push({title: app, path: '/suppliers'});
    });
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  titleButtonControl() {
    if (this.titleButton === 'Modifica' && this.checkPermission('suppliers:update')) {
      return true;
    }
    if (this.titleButton === 'Salva' && this.checkPermission('suppliers:update')) {
      return true;
    }

    return false;
  }
}
