import {Component, OnInit, ViewChild, ElementRef, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import { Template } from '@app/template.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {selectValueAccessor} from '@angular/forms/src/directives/shared';
import * as moment from 'moment';
import {ServerBaseResponse} from '@app/shared/models/server-base-response';
import {lifecycleHooksMethods} from 'codelyzer/noLifeCycleCallRule';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import set = Reflect.set;
import {UtilityService} from '@app/shared/utility.service';
import {del} from 'selenium-webdriver/http';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UtentiService} from '@app/dashboard/utenti/utenti.service';
import {DataTableDirective} from 'angular-datatables';
import { AutocompleteComponent } from '@app/shared/autocomplete/autocomplete.component';
import {UtenteAutenticatoModel} from '@app/shared/models/db_tables/utente-autenticato.model';
import {FornitoriService} from '@app/dashboard/fornitori/fornitori.service';

@Component({
  selector: 'app-list',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {
    language: {},
  };

  orderby = 'none';

  users = [];

  selected = [];
  checkrow = false;
  select = false; // per far apparrire o sparire i bottoni nelle actionbar

  refresh = true;

  posBreadcrumbs;

  user;

  categoriesFilter: any;
  filter = {search: {}, filters: {}};
  arrayFilterMultiple = [];
  chips: any = [];

  // per pagination
  totalPage: number;

constructor(
    private utentiService: UtentiService,
    private translate: TranslateService,
    private utilityService: UtilityService,
    private dropzone: ElementRef,
    private fornitoriService: FornitoriService) {

      this.translate.onLangChange
                .subscribe((event: LangChangeEvent) => {
                    this.translate.get('breadcrumbs.users.list').subscribe((app: string) => {
                      utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/users/'};
                    });
                    this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
                      this.dtOptions.language = x;
                    });
                });
  }

ngOnInit() {
    this.user =  this.utilityService.user;
    this.getUsersList();
    this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
        this.dtOptions.language = x;

    });

    this.translate.get('breadcrumbs.users.list').subscribe((app: string) => {
      this.utilityService.Navigation = [];
      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: app, path: '/users'});
    });
  }

deleteUser(id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questo utente',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminato!',
          "L'utente è stata eliminato con successo",
          'success'
        );
        this.utentiService.DeleteUser(id).subscribe(() => {
          this.getUsersList();
        }
      );
      }
    });
  }

  deleteUsersSelected() {
  console.log(this.selected);
  this.utentiService.MassDeleteUsers({IDs: this.selected}).subscribe((res: any) => {
    this.getUsersList();
  });
  }

getUsersList(filter?, cat = null) {
    if (sessionStorage.getItem('filterCatUsers') && sessionStorage.getItem('filterCatUsers') !== 'undefined') {
      const filterControl = JSON.parse(sessionStorage.getItem('filterUsers'));
      this.categoriesFilter = JSON.parse(sessionStorage.getItem('filterCatUsers'));
      this.chips = JSON.parse(sessionStorage.getItem('filterChipUsers'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filter = filterControl;
        // this.getListRows(false, JSON.parse(sessionStorage.getItem('filterRows')), true);
      }
    }
    this.utentiService.GetUsersList(this.translate.currentLang, filter).subscribe((res: any) => {
      this.users = res.data;
      if (!sessionStorage.getItem('filterCatUsers') || sessionStorage.getItem('filterCatUsers') === 'undefined') {
        // this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
        this.categoriesFilter = res.filtersList;
      }
      this.totalPage = res.pagination.totalPages;
      console.log(this.categoriesFilter);
    });
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
        this.select = false;
      }
    }
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.users.forEach((user) => {
        this.selected.push(user._id);
      });
      $('tbody').find('tr').addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      this.selected = [];
      $('tbody').find('tr').removeClass('selected-table');
      this.select = false;
      console.log(this.selected);
    }
  }

  emitSearch(event) {
    console.log('save Search');
    if (event.action !== 'delete') {
      if (this.filter.search[event.property] === undefined) {
        this.filter.search[event.property] = [];
      }
      this.filter.search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter();
    }
  }

  saveFilter(event) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        console.log('1 if multi');
        if (this.filter.filters[event.type] === undefined) {
          console.log('init array multi');
          this.filter.filters[event.type] = [];
        }
        console.log('add filter multi into array');
        this.filter.filters[event.type].push(event.value);
      } else {
        console.log('not multi');
        this.filter.filters[event.type] = event.value;
      }
    } else {
      console.log('delete');
      delete this.filter.filters[event.type];
    }
    console.log('save filter');
    this.Searchwithfilter(event.cat);
  }

  Searchwithfilter(cat?) {
    console.log('apply');
    if (cat !== undefined) {
      sessionStorage.setItem('filterCatUsers', JSON.stringify(cat));
    }
    sessionStorage.setItem('filterUsers', JSON.stringify(this.filter));
    this.getUsersList(this.filter);
    //   sessionStorage.setItem('filterCat', JSON.stringify(cat));
    //   sessionStorage.setItem('filter', JSON.stringify(this.filter));
  }

  deleteFilter(items) {
    console.log('delete chip');
    this.filter = {search: {}, filters: {}};
    this.arrayFilterMultiple = [];
    let isSearch = false;
    const categories: Array<any> = this.categoriesFilter.search;
    const categoriesFilters: Array<any> = this.categoriesFilter.filters;
    console.log('lenght items', items.length);
    console.log('items', items);
    if (items.length > 0) {
      items.forEach((item) => {
        console.log('items foreach', item.tag);
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter.search[cat.name] === undefined) {
              this.filter.search[cat.name] = [];
            }
            this.filter.search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  console.log('false');
                  option.active = false;
                } else {
                  console.log('trueeeeeeeeee');
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Group') {
                      // aggiungere su questo if il filter.name del filtro a selezione multipla
                      this.filter.filters[filter.name] = option.value;
                      console.log('dentro if', this.filter);
                    } else {
                      if (this.filter.filters[filter.name] === undefined) {
                        this.filter.filters[filter.name] = [];
                        console.log('status init');
                      }
                      console.log(option.value);
                      this.filter.filters[filter.name].push(option.value);
                      this.arrayFilterMultiple.push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type  && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  console.log('no option', item.tag);
                  const res = item.tag.split(' ');
                  this.filter.filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    console.log(this.categoriesFilter);
    console.log(this.filter);
    this.Searchwithfilter(this.categoriesFilter);
  }

  pagination(page) {
    console.log(page);
    this.filter['pagination'] = page;
    console.log(this.filter);
    this.getUsersList(this.filter);
  }

  orderByHeader(element) {
    // Todo
    if (element === this.orderby) {
      this.orderby = element + '_less';
    } else {
      this.orderby = element;
    }
  }
}
