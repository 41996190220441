import {Component, DoCheck, Input, IterableDiffers, OnInit} from '@angular/core';
import {UtilityService} from '@app/shared/utility.service';

declare const $: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'DM-breadCrumbs',
  templateUrl: './breadCrumbs.component.html',
  styleUrls: ['./breadCrumbs.component.scss'],
})

export class BreadCrumbsComponent implements OnInit, DoCheck {

  iterableDiffer;
  constructor(
    private utilityService: UtilityService,
    private IterableDiffer: IterableDiffers
  ) {
    this.iterableDiffer = this.IterableDiffer.find([]).create(null);
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.utilityService.Navigation);
    if (changes) {
      this.utilityService.Navigation.forEach((res: any) => {
        if (res.title === this.utilityService.Navigation[this.utilityService.Navigation.length - 1].title) {
          this.utilityService.Navigation.splice(this.utilityService.Navigation.indexOf(res) + 1);
        }
      });
    }
  }

  ngOnInit() {
  }
}
