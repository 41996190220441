import { NgModule } from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import { ListaComponent } from './lista/lista.component';
import { FormComponent } from './form/form.component';
import { DataTablesModule } from 'angular-datatables';
import { MzFeatureDiscoveryModule } from 'ngx-materialize';
import { RouterModule } from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DirectiveModule} from '@app/shared/directive/directive.module';
import {DropdownModule} from '@app/shared/dropdown/dropdown.module';
import {BtnAddModule} from '@app/shared/btnAdd/btnAdd.module';
import {DashboardModule} from '@app/dashboard/dashboard.module';
import {MultifilterModule} from '@app/shared/multifilter/multifilter.module';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {BreadCrumbsModule} from '@app/shared/breadCrumbs/breadCrumbs.module';
import {CustomPipeModule} from '@app/shared/customPipes/customPipe.module';
import {DetailsNavModule} from '@app/shared/detailsNavigation/detailsNav.module';
import {PaginationModule} from "@app/shared/pagination/pagination.module";

@NgModule({
  declarations: [ListaComponent, FormComponent],
  imports: [
    CommonModule,
    DataTablesModule,
    TranslateModule,
    RouterModule,
    MzFeatureDiscoveryModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    DropdownModule,
    BtnAddModule,
    DashboardModule,
    MultifilterModule,
    NgxDropzoneModule,
    BreadCrumbsModule,
    CustomPipeModule,
    DetailsNavModule,
    PaginationModule
  ]
})
export class ListinoModule { }
