import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {Reset_passwordComponent} from '@app/auth/reset_password/reset_password.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SetpasswordComponent} from "@app/auth/set_password/setpassword.component";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [LoginComponent, Reset_passwordComponent, SetpasswordComponent],
  exports: [LoginComponent, Reset_passwordComponent, SetpasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule
  ]
})
export class AuthModule { }
