import {Component, OnInit, ViewChild, ElementRef, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, FormControl, Validators, AbstractControl, FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute, RouterStateSnapshot, ParamMap} from '@angular/router';
import {UtilityService} from '@app/shared/utility.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import {UtentiService} from '@app/dashboard/utenti/utenti.service';
import { invalid } from '@angular/compiler/src/render3/view/util';
import * as EmailValidator from 'email-validator';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ChecklistModule } from 'angular-checklist';
import {AuthenticationService} from '@app/auth/login/login.service';

declare const  M: any; // usata per i toast
declare const $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @ViewChild('dropzone') drop: ElementRef;

  dettaglio = false;
  modifica = false;
  modificata = false;
  passCorretta = false;

  // opzioni aggiuntive per modal
  // dismissibile se false non fa chiudere il modal se clicchi fuori
  optionsModal = {dismissible: false};

  password = {
    pass: '',
    conf_pass: ''
  };
  oldPass = '';

  utenteForm: FormGroup = null;

  userId;
  appUtente;
  posBreadcrumbs;
  basePosBradcrumbs;
  utente;
  img;
  languages;
  mail;
  Groups;
  permission = [];
  orderperm;
  supplierperm;
  productperm;
  dependecies = {
    suppliers: [],
    orders: [],
    products: []
  };

  permissionGroup: any;
  userPermission = [];
  label = false;

  options = {
    onCloseEnd: () => {

    }
  };

  user;
  usersnav;
  index;

  constructor(private formBuilder: FormBuilder,
              private utilityservice: UtilityService,
              private utentiService: UtentiService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private ngxDropZone: NgxDropzoneModule,
              private checkListModule: ChecklistModule) {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.get('breadcrumbs.users.list').subscribe((app: string) => {
          utilityservice.Navigation[this.basePosBradcrumbs] = {title: app, path: '/users'};
        });
        if (!this.userId) {
          this.translate.get('breadcrumbs.users.form').subscribe((app: string) => {
            utilityservice.Navigation[this.posBreadcrumbs] = {title: app, path: '/users/new'};
          });
        }
      });
  }

  ngOnInit(): void {
    this.user = this.utilityservice.user;


    this.GetDDL();



    $('select').formSelect();
    /*
        this.translate.get('breadcrumbs.users.list').subscribe((app: string) => {
          this.basePosBradcrumbs = this.utilityservice.Navigation.length;
          this.utilityservice.Navigation.push({title: app, path: '/users'});
        });
    */
    this.InitForms();


    this.activatedRoute.paramMap.subscribe(
      (paramsMap: ParamMap) => {
        // se l’url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
        this.userId = +paramsMap.get('id');
        if (this.userId) {
          this.dettaglio = true;
          this.utentiService.GetUser(this.userId).subscribe((res: any) => {
            this.usersnav = res.stats.resultSetIDs;
            this.index = res.stats.resultSetIDs.indexOf(this.userId) + 1;

            this.utenteForm.patchValue(res.data);
            this.utenteForm.disable();
            this.label = true;
            const app = this.utenteForm.controls.first_name.value + ' ' + this.utenteForm.controls.last_name.value;
            this.utilityservice.Navigation.push({title: app, path: '/users/' + this.userId});
            this.selectActivation();
            this.utente = res.data;
            this.mail = this.utente.email;
            this.userPermission = this.utente.permissions;
            console.log(this.mail);
          });
        } else {
          this.InitForms();
          this.translate.get('breadcrumbs.users.form').subscribe((app: string) => {
            this.posBreadcrumbs = this.utilityservice.Navigation.length;
            this.utilityservice.Navigation.push({title: app, path: '/users/new'});
          });
        }
      });
  }

  GetDDL() {
    this.utentiService.DDLPermissions().subscribe((res: any) => {
      console.log('res: ', res);
      this.permissionGroup = res.data;
    });
    this.utentiService.DDLLingue().subscribe((l: any) => {
      console.log(l);
      this.languages = l;
      console.log(this.languages);
    });
    this.utentiService.DDLGroup().subscribe((res) => {
      this.Groups = res.data;
      console.log(this.Groups);
    });
  }

  InitForms() {
    if (!this.dettaglio) {
      this.utenteForm = this.formBuilder.group({
        first_name: this.utilityservice.standardFormControl(true, false),
        last_name: this.utilityservice.standardFormControl(true, false),
        group: this.utilityservice.standardFormControl(true, false),
        email: this.utilityservice.standardFormControl(true, false),
        lang: this.utilityservice.standardFormControl(true, false),
        permissions: this.utilityservice.standardFormControl(false, false)
      });
    } else {
      this.utenteForm = this.formBuilder.group({
        first_name: this.utilityservice.standardFormControl(true, false),
        last_name: this.utilityservice.standardFormControl(true, false),
        group: this.utilityservice.standardFormControl(true, false),
        email: this.utilityservice.standardFormControl(true, false),
        lang: this.utilityservice.standardFormControl(true, false),
        permissions: this.utilityservice.standardFormControl(false, false)
      });
    }
  }

  reset() {
    console.log(this.mail);
    if (EmailValidator.validate(this.mail)) {

      console.log(this.mail);

      this.authenticationService.ResetMail(this.mail)
        .subscribe((res: any) => {
          setTimeout(() => {
          }, 1500);

        }, (err) => {
          setTimeout(() => {
            console.log(err);
            M.toast({html: err.error.errors});
          }, 1500);
        });
    } else {
      M.toast({
        html: '<i class="material-icons dp48 left" style="color: red;">new_releases</i>' +
          '<p style="color: red">Inserisci una mail valida</p>', classes: 'rounded'
      });
    }
  }

  invio() {

    // this.utenteForm.controls.permissions.setValue(this.userPermission)

    if (this.utenteForm.controls.group.value === 'user') {
      this.permission.push(this.orderperm);
      this.permission.push(this.supplierperm);
      this.permission.push(this.productperm);
      this.utenteForm.controls.permissions.setValue(this.permission);
    }
    console.log(this.permission);


    if (this.utenteForm.valid) {

      this.utentiService.NewUser(this.utenteForm.value).subscribe((res: any) => {
        console.log(this.utenteForm.value);
        this.router.navigate(['/dashboard/users']);

        this.utenteForm.reset();
        this.selectActivation();
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi del utente'});
    }
  }

  modificaUtente() {
    this.modifica = true;
    this.utenteForm.enable();
    this.selectActivation();
  }

  cancel() {
    this.modifica = false;
    this.utenteForm.reset(this.utente);
    this.selectActivation();
    this.utenteForm.disable();
    this.activateLabel();
  }

  patchSend() {

    console.log("valori: ", this.utenteForm.value);
    console.log(this.utenteForm.valid);
    if (this.utenteForm.valid) {

      if (this.utenteForm.controls.group.value === 'user') {
        this.permission.push(this.orderperm);
        this.permission.push(this.supplierperm);
        this.permission.push(this.productperm);
        this.utenteForm.controls.permissions.setValue(this.permission);
        this.utenteForm.controls.permissions.markAsDirty();
      }
      console.log(this.utenteForm.get('permissions').value);


      console.log(this.utenteForm);
      const dirtyValues = {};

      for (const control in this.utenteForm.controls) {
        if (this.utenteForm.get(control).dirty) {
          dirtyValues[control] = this.utenteForm.get(control).value;
        }
      }

      console.log("dirtyValues: ", dirtyValues);

      // const formData = new FormData();
      // formData.append('file', this.img);

      // this.utentiService.UploadProfilePic(this.userId, formData).subscribe();

      this.utentiService.UpdateUser(this.userId, dirtyValues).subscribe((res: any) => {
        this.router.navigate(['/dashboard/users/' + this.userId]);

        this.modifica = false;
        this.utenteForm.disable();
        this.selectActivation();
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi del utente'});
    }
  }

  changePass() {
    if (this.password.pass === this.password.conf_pass) {
      if (this.password.pass.length >= 8) {
        const patchPass = {
          oldPass: this.oldPass,
          newPass: this.password.pass
        };
        this.utentiService.changePass(this.userId, patchPass).subscribe(() => {
          $('#PassModal').modal('close');
          this.password.pass = '';
          this.password.conf_pass = '';
          this.oldPass = '';
        });
      } else {
        M.toast({html: 'Passwords troppo corta, deve avere minimo 8 caratteri'});
      }
    } else {
      M.toast({html: 'Passwords diverse'});
    }
  }

  isProfile() {
    if (this.user.id === this.utente.id) {
      return true;
    } else {
      return false;
    }
  }


  formDataControl(pass, modificata) {
    return this.utilityservice.FormClassValidator(pass, modificata);
  }

  activateLabel() {
    if (this.label) {
      return 'active';
    }
  }

  clear() {
    this.utenteForm.reset();
    this.selectActivation();
    this.userPermission = [];
    $('select').formSelect();
  }

  selectActivation() {
    if (this.dettaglio) {
      if (!this.modifica) {
        $('select').prop('disabled', true);
        $('select').formSelect();
      } else {
        $('select').removeAttr('disabled');
        $('select').formSelect();
      }
    }
  }

  onSelect(event) {
    console.log('aaaaaaaaaaaaaaaaa');
    console.log(event);
    this.img = event.addedFiles;
    console.log(this.img);
  }

  onRemove(event) {
    console.log('elemento rimossso');
    this.img.splice(this.img.indexOf(event), 1);
  }

  ShowModalUpload() {
    $('#PassModal').modal('open');
  }

  ShowModalPerm() {
    $('#permissionModal').modal('open');
  }

  DismissPerm() {
    $('#permissionModal').modal('close');
  }

  addDependencies(perm) {
    console.log('perm: ', perm);

    const checked = this.userPermission.find((element) => {
      return element === perm.value;
    });

    if (!checked) {
      perm.dependencies.forEach(element => {

        const exist = this.userPermission.find((el) => {
          return el === element;
        });

        if (exist === undefined) {
          this.userPermission.push(element);
        }
      });
    }
    console.log(this.userPermission);
  }


  selectAll() {
    console.log(this.permission);
   /* const arrayApp = [];
    this.userPermission = [];

    this.permissionGroup.forEach(element => {
      element.permissions.forEach(elem => {
        arrayApp.push(elem.value);
      });
    });

    this.userPermission = arrayApp;*/
  }

  unselectAll() {
    this.userPermission = [];
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  selectDependecies(i) {
    for (let permissionGroupElementKey in this.permissionGroup[i]) {
      if (this.permissionGroup[i][permissionGroupElementKey].id === this.orderperm || this.permissionGroup[i][permissionGroupElementKey].id === this.supplierperm || this.permissionGroup[i][permissionGroupElementKey].id === this.productperm) {
        console.log(this.permissionGroup[i][permissionGroupElementKey].dependencies);
        this.permissionGroup[i][permissionGroupElementKey].dependencies.forEach((dependece) => {
          if (dependece.includes('suppliers')) {
            this.supplierperm = dependece;
            this.dependecies.suppliers.push(dependece);
            setTimeout(() => {$('select').formSelect(); }, 1);
          } else if (dependece.includes('products')) {
            this.productperm = dependece;
            this.dependecies.products.push(dependece);
            $('select').formSelect();
          } else if (dependece.includes('orders')) {
            this.orderperm = dependece;
            this.dependecies.orders.push(dependece);
            $('select').formSelect();
          }
        });
      }
    }
  }

  ciao(permessi, section) {
    const ciao: Array<any> = this.dependecies[section];
   // console.log(ciao);
    ciao.forEach((s) => {
      //console.log(s);
    });
  }
}
