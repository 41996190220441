import {Component, OnInit} from '@angular/core';
import {GareService} from '@app/dashboard/gare/gare.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UtilityService} from '@app/shared/utility.service';
import {GareListaModel} from '@app/shared/models/db_tables/.gare-lista.model';
import Swal from "sweetalert2";
import {ActivatedRoute, Router} from "@angular/router";
import set = Reflect.set;

declare const $: any;

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  constructor(private competitionsService: GareService,
              private translate: TranslateService,
              private utilityService: UtilityService,
              private router: ActivatedRoute) {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
/*
        this.translate.get('breadcrumbs.competitions.list').subscribe((app: string) => {
          utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/competitions'};
        });
*/
        this.translate.use(event.lang);
        this.translate.get('Suppliers.List.Button.new', {value: 'world'}).subscribe((x: string) => this.new = x);
        this.translate.get('Suppliers.List.Button.import', {value: 'world'}).subscribe((x: string) => this.importa = x);
        this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
          console.log('ciao subscribe', x);
          this.refresh = false;
        });
        setTimeout(() => {
          this.refresh = true;
        }, 0);
      });
  }

  orderby = 'none';

  competitions: Array<GareListaModel> = [];

  selected = [];

  select;

  checkrow = false;

  posBreadcrumbs;
  refresh = true;
  new;
  importa;
  user;

  categoriesFilter: any;
  chips: any = [];
  filter = {search: {}, filters: {}};

  // per paginazione
  totalPage: number;

  ngOnInit() {
    this.user =  this.utilityService.user;
    this.utilityService.Navigation = [];
    this.router.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {
        console.log(params);
        this.getList({search:{}, filters: {status: ['started']}}, true);
      } else {
        console.log('nada');
        this.getList();
      }
    });
    /*
    this.translate.get('breadcrumbs.competitions.list').subscribe((app: string) => {
      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: app, path: '/competitions'});
      console.log(this.posBreadcrumbs);
      console.log(this.utilityService.Navigation.length);
      console.log(this.utilityService.Navigation);
    });
    */
  }

  getList(filter?, query = false) {
    if (query) {
      this.chips.push({type: 'Competition status', tag: 'started', icon: 'filter_list'});
      console.log(this.chips);
    }
    if (sessionStorage.getItem('filterCatGare') && sessionStorage.getItem('filterCatGare') !== 'undefined') {
      const filterControl = JSON.parse(sessionStorage.getItem('filterGare'));
      this.categoriesFilter = JSON.parse(sessionStorage.getItem('filterCatGare'));
      this.chips = JSON.parse(sessionStorage.getItem('filterChipGare'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filter = filterControl;
      }
    }
    this.competitionsService.getLista(this.translate.currentLang, filter).subscribe((res: any) => {
      this.competitions = res.data;
      if (!sessionStorage.getItem('filterCatGare') || sessionStorage.getItem('filterCatGare') === 'undefined') {
        // this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
        this.categoriesFilter = res.filtersList;
      }
      if (query) {
        const categories: Array<any> = this.categoriesFilter.filters;
        categories.forEach((cat) => {
          if (cat.name === 'categories') {
            cat.options.forEach((option) => {
              if (option.label === 'started') {
                option.active = true;
              }
            });
          }
        });
        this.filter = filter;
      }
      this.totalPage = res.pagination.totalPages;
      this.utilityService.Navigation.push({title: 'Gare', path: '/competitions'}); // da sostituire con il commento dopo che le traduzioni sono aggiornate
    });
  }

  deleteCompetitionsSelected() {
    console.log(this.selected);
    this.competitionsService.MassDeleteCompetitions({IDs: this.selected}).subscribe(() => {
      this.select = false;
      this.selected = [];
      this.getList();
    });
  }

  startCompetitionsSelected() {
    console.log(this.selected);
    this.competitionsService.updateCompetition({status: 'started'}, this.selected[0]).subscribe(() => {
      this.select = false;
      this.getList();
    });
  }

  closeCompetitionsSelected() {
    console.log(this.selected);
    this.competitionsService.updateCompetition({status: 'ended'}, this.selected[0]).subscribe(() => {
      this.select = false;
      this.getList();
    });
  }

  setStatusGare(status) {
    this.selected.forEach(s => this.competitions.forEach(c => {
      if (c.id === s) {
        c.status = status;
    }
    }));
  }

  deleteById(id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Sicuro di voler annullare questa gara',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Annullata!',
          'La gara è stata annullata con successo',
          'success'
        );
        this.competitionsService.deleteCompetitionByid(id).subscribe((res) => {
          this.getList();
        });
      }
    });
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      console.log($(input));
      $(input.path[3]).addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
        this.select = false;
      }
    }
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.competitions.forEach((gara) => {
        this.selected.push(gara.id);
      });
      $('tbody').find('tr').addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      this.selected = [];
      $('tbody').find('tr').removeClass('selected-table');
      this.select = false;
      console.log(this.selected);
    }
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  ColorStatus(stato) {
    if (stato === 'inserted') {

      return 'status-opened';

    } else if (stato === 'cancelled') {

      return 'status-canceled';

    } else if (stato === 'started') {

      return 'status-progress';

    } else if (stato === 'ended') {

      return 'status-closed';

    } else if (stato === 'orders') {

      return 'status-order';

    }
  }

  emitSearch(event) {
    if (event.action !== 'delete') {
      if (this.filter.search[event.property] === undefined) {
        this.filter.search[event.property] = [];
      }
      this.filter.search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter();
    }
  }

  saveFilter(event) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        if (this.filter.filters[event.type] === undefined) {
          this.filter.filters[event.type] = [];
        }
        this.filter.filters[event.type].push(event.value);
      } else {
        this.filter.filters[event.type] = event.value;
      }
    } else {
      delete this.filter.filters[event.type];
    }
    this.Searchwithfilter(event.cat);
  }

  Searchwithfilter(cat?) {
    console.log('apply');
    sessionStorage.setItem('filterCatGare', JSON.stringify(cat));
    sessionStorage.setItem('filterGare', JSON.stringify(this.filter));
    this.getList(this.filter);
  }

  deleteFilter(items) {
    this.filter = {search: {}, filters: {}};
    let isSearch = false;
    const categories: Array<any> = this.categoriesFilter.search;
    const categoriesFilters: Array<any> = this.categoriesFilter.filters;
    if (items.length > 0) {
      items.forEach((item) => {
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter.search[cat.name] === undefined) {
              this.filter.search[cat.name] = [];
            }
            this.filter.search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  option.active = false;
                } else {
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Competition status') {
                      this.filter.filters[filter.name] = option.value;
                    } else {
                      if (this.filter.filters[filter.name] === undefined) {
                        this.filter.filters[filter.name] = [];
                      }
                      this.filter.filters[filter.name].push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type  && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  const res = item.tag.split(' ');
                  this.filter.filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    this.Searchwithfilter(this.categoriesFilter);
  }

  pagination(page) {
    console.log(page);
    this.filter['pagination'] = page;
    console.log(this.filter);
    this.getList(this.filter);
  }

  orderByHeader(element) {
    // Todo
    if (element === this.orderby) {
      this.orderby = element + '_less';
    } else {
      this.orderby = element;
    }
  }
}
