import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UtilityService} from '@app/shared/utility.service';
import {RdpService} from '@app/dashboard/Rdp/rdp.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {RDPModel} from '@app/shared/models/db_tables/.rdp.model';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {FornitoriService} from '@app/dashboard/fornitori/fornitori.service';
import {ListinoService} from '@app/dashboard/listino/listino.service';
import {ProdottiListaModel} from '@app/shared/models/db_tables/prodotti-lista.model';
import {FornitoriListaModel} from '@app/shared/models/db_tables/fornitori-lista.model';
import {NumberPipe} from '@app/shared/customPipes/customPipe.component';
import {SettingsService} from '@app/dashboard/settings/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from "rxjs";
import {mergeMap} from "rxjs/operators";
import { RowstatuscolorDirective } from '@app/shared/directive/rowstatuscolor.directive';

declare const M: any;
declare const $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private rdpService: RdpService,
              private fornitoriService: FornitoriService,
              private settingsService: SettingsService,
              private prodottiService: ListinoService,
              private numberPipe: NumberPipe,
              private translate: TranslateService,
              private utilityService: UtilityService,
              private router: Router) {}

  @ViewChild('qta') qta: ElementRef;
  @ViewChild('delivery_date') deliveryDate: ElementRef;
  @ViewChild('chip') chips: ElementRef;
  @ViewChild('label') label: ElementRef;
  @ViewChild('autocomplete') autocomplete;
  @ViewChild('chipMercio') chipMercio: ElementRef;
  @ViewChild('chipProdotti') chipProdotti: ElementRef;

  rdpForm: FormGroup = null;
  prodottoForm: FormGroup = null;
  fornitoreForm: FormGroup = null;


  selected = [];
  checkrow = false;
  select = false; // per far apparrire o sparire i bottoni nelle actionbar

  rdp: RDPModel;

  fornitore: FornitoriListaModel;

  fornitori = [];

  listaprodotti: Array<ProdottiListaModel> = [];
  prodotti = [];
  products = [];
  rows = [];
  prodotto = {
    productsId: 0,
    internal_sku: '',
    description: '',
    requested_qty: 0,
    requested_delivery_date: '',
    categories: []
  };

  // converti in ordine
  currencies = [];
  orderForm: FormGroup = null;
  suppliers: Array<FornitoriListaModel> = [];
  terminiresa;
  terminipagamento;
  addresses = [];

  command;
  rowId;
  dettaglio = false;
  idAllegato;
  file = [];
  commentoRev;
  filename = [];

  rdpsnav;
  modificata = false;
  index;
  nuovoProdotto = false;
  status;
  user;
  rdpId;
  title;
  titlebutton;
  modifica = false;
  refresh = false;

  CatMerciologiche =  {};
  CatProdotto =  {};

  actionSuppEmail: any;
  from;

  ngOnInit() {
    this.user =  this.utilityService.user;
    this.initForms();
    this.getSuppliers();
    this.getProducts();
    this.getCategories();
    this.getAddresses();

    this.activatedRoute
      .paramMap
      .subscribe(
        (paramsMap: ParamMap) => {
          // se l'url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
          this.rdpId = +paramsMap.get('id');
          this.actionSuppEmail = +paramsMap.get('action');
          this.from = paramsMap.get('from');
          if (this.rdpId) {
            if (this.actionSuppEmail) {
              if (this.actionSuppEmail === 'canceled') {
                this.rdpService.changeStatus(this.rdpId, 'canceled').subscribe(() => {
                  this.getRDP();
                });
              }
            }

            this.dettaglio = true;
            this.modifica = false;
            this.getRDP();
          } else {
            this.titlebutton = 'Salva';
            this.command = 'salva';
            this.refresh = true;
            this.title = 'Nuovo RDP';
            this.utilityService.Navigation.push({title: 'Nuovo RDP', path: '/rdps/new'});
          }
        });
  }

  getRDP() {
    this.rdpService.GetRdpByid('true', this.rdpId).subscribe((res: any) => {
      this.rdp = res.data;
      this.status = this.rdp.status;
      // this.rdpsnav = res.stats.resultSetIDs;
      // this.index = res.stats.resultSetIDs.indexOf(this.rdpId) + 1;
      this.title = this.rdp.code; // cambiare con codice rdp
      this.rdpForm.reset(this.rdp);
      this.rdpForm.disable();
      if (this.rdp.Competition != null) {
        this.from = 'gara';
      }


      this.rdp.Rows.forEach(r => {
        if (r.price === null) {
          r.price = 0;
        }
        if (r.qty === null) {
          r.qty = 0;
        }
        this.prodotto['id'] = r.id;
        this.prodotto.productsId = r.productsId;
        // if (r.Product !== null) {
        //   this.prodotto.internal_sku = r.Product.internal_sku;
        //   this.prodotto.description = r.Product.description;
        // } else {
        //   this.prodotto.internal_sku = r.product_text;
        //   this.prodotto.description = r.product_text;
        // }
        // this.prodotto.requested_delivery_date = r.requested_delivery_date;
        // this.prodotto.requested_qty = r.requested_qty;
        console.log(this.prodotto);
        this.products = [];
        this.rows.push(this.prodotto);
        //this.AddProdotto(true);
      });
      console.log(this.rows);
      console.log(this.rdp.Rows.length);
      this.titlebutton = 'Modifica';
      this.command = 'modifica';
      this.refresh = true;
      this.dettaglio = true;
      this.modifica = false;
      this.utilityService.Navigation.push({title: this.rdp.code, path: '/rdps/' + this.rdpId});
    });
  }

  getSuppliers() {
    this.fornitoriService.getListaTable('false', this.translate.currentLang).subscribe((res: any) => {
      this.suppliers = res.data;
      this.suppliers.forEach(f => {
        this.fornitori.push({label: f.r_sociale, value: f.id});
      });
    });
  }

  getCurrencies() {
    this.settingsService.getCurrentCompany().subscribe((res: any) => {
      this.settingsService.getCurrencyList(res.data.id).subscribe((val: any) => {
        this.currencies = [];
        val.data.forEach((v: any) => {
          this.currencies.push({value: v.id, label: v.label});
        });
      });
    });
  }

  getProducts() {
    this.prodottiService.GetListProducts(this.translate.currentLang).subscribe((res: any) => {
      this.listaprodotti = res.data;
      this.listaprodotti.forEach((p: any) => {
        this.prodotti.push({label: p.description, value: p.id, code: p.internal_sku});
      });
    });
  }

  getCategories() {
    this.fornitoriService.getCategoriesCommercia().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatMerciologiche[cat.name] = null;
      });
    });
    this.fornitoriService.getCategoriesProducts().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatProdotto[cat.label] = null;
      });
    });
  }

  getAddresses() {
    this.settingsService.getCurrentCompany().pipe(mergeMap((merge: any) => this.settingsService.getCompanyAddress(merge.data.id)))
      .subscribe(res => {
        this.addresses = res.data;
        const address = res.data.find(ind => ind.default === true);
        this.rdpForm.controls.delivery_address.setValue(address.id);
        $('select').formSelect();
      });
     /* this.addresses = res.data.Addresses;
      const address = res.data.Addresses.find(ind => ind.default === true);
      this.orderForm.controls.delivery_address.setValue(address.id);*/
  }

  initForms() {
    this.prodottoForm = this.formBuilder.group({
      description: this.utilityService.standardFormControl(true, false),
      internal_sku: this.utilityService.standardFormControl(true, false),
      barcode: this.utilityService.standardFormControl(true, false),
      type: this.utilityService.standardFormControl(true, false),
      price: this.utilityService.standardFormControl(true, false),
      categories: this.utilityService.standardFormControl(true, false)
    });

    this.fornitoreForm = this.formBuilder.group({
      r_sociale: this.utilityService.standardFormControl(),
      piva: this.utilityService.standardFormControl(),
      indirizzo_consegna: this.utilityService.standardFormControl(),
      tel: this.utilityService.telephoneFormControl(true, 10, '', true),
      email: this.utilityService.emailFormControl(),
      website: this.utilityService.standardFormControl(),
      delivery_term: this.utilityService.standardFormControl(),
      payment_term: this.utilityService.standardFormControl(),
      currency: this.utilityService.standardFormControl(),
      fax: this.utilityService.telephoneFormControl(true, 10, '', true),
    });

    this.rdpForm = this.formBuilder.group({
      suppliersId: this.utilityService.standardFormControl(true, false),
      Rows: this.utilityService.standardFormControl(true, false),
      notes: this.utilityService.standardFormControl(false, false),
      delivery_address: this.utilityService.standardFormControl(true, false),
    });

    this.orderForm = this.formBuilder.group({
      currency: this.utilityService.standardFormControl(false, false),
      delivery_term: this.utilityService.standardFormControl(false, false),
      payment_term: this.utilityService.standardFormControl(false, false),
    });
  }

  SaveRdp(command) {
    if (command === 'salva') {
      this.rdpForm.controls.Rows.setValue(this.products);
      if (this.rdpForm.valid) {
        this.rdpService.CreateRdp('true', this.rdpForm.value).subscribe((res) => {
          this.router.navigate( ['/dashboard/rdps/' + res.data.id]);
        });
      }
    }
    if (command === 'modifica') {
      this.modifica = true;
      this.rdpForm.enable();
      $('select').formSelect();
      this.titlebutton = 'Salva';
      this.command = 'update';
    }
    if (command === 'update' && this.user.group !== 'supplier') {
      let rows = [];
      this.products.forEach( (x => {x.id = null; rows.push(x);}));
      this.rdp.Rows.forEach( x => rows.push(x));
      console.log('ESISTI? ', rows);
      this.rdpForm.controls.Rows.setValue(rows);
      if (this.rdpForm.valid) {
        this.rdpService.UpdateRdp(this.rdpForm.value, this.rdpId).subscribe(() => {
          this.nuovoProdotto = false;
          this.getRDP();
        });
      }
    } else if (command === 'update') {
      let rows = [];
      this.products.forEach( (x => {x.id = null; rows.push(x);}));
      this.rdp.Rows.forEach( x => rows.push(x));
      console.log('ESISTI? ', rows);
      this.rdpService.UpdateRdp({Rows: rows}, this.rdpId).subscribe(() => {
        this.nuovoProdotto = false;
        this.getRDP();
      });
    }

  }

  sendRdp() {
    if (this.user.group === 'supplier') {
      this.rdpService.changeStatus(this.rdpId, 'answered').subscribe(() => {
        this.getRDP();
      });
    } else {
      this.rdpService.changeStatus(this.rdpId, 'sent').subscribe(() => {
        this.getRDP();
      });
    }
  }

  showOrderModal() {
    const supp = this.suppliers.find(supp => supp.id === this.rdp.suppliersId);
    console.log(supp);
    this.orderForm.patchValue(supp);
    this.orderForm.controls.currency.setValue(supp.price_list.currency);
    forkJoin(
      this.fornitoriService.GetDDlterminiConsegna(),
      this.fornitoriService.GetDDlTerminiPagamento(),
      this.settingsService.getCurrentCompany().pipe(mergeMap((res: any) => this.settingsService.getCurrencyList(res.data.id))))
      .subscribe((result: any) => {
        console.log(result);

        this.terminiresa = result[0].data;

        this.terminipagamento = result[1].data;

        this.currencies = result[2].data.map((r) => {return {value: r.id, label: r.label}; } );

        $('#orderModal').modal('open');
      });
  }

  convertOrder() {
    const convert = {};
    convert['data'] = this.orderForm.value;
    if (this.select) {
      convert['ids'] = this.selected;
      this.rdpService.convertRowsToOrder(convert, 'true').subscribe((res) => {
        console.log(res.data.id);
      //  this.router.navigate(['/dashboard/orders/' + res.data[0].id]);
      });
    } else {
      convert['ids'] = [this.rdpId];
      this.rdpService.convertRdpToOrder(convert, 'true').subscribe((res) => {
        console.log(res.data);
       // this.router.navigate(['/dashboard/orders/' + res.data[0].id]);
      });
    }
    this.orderForm.reset();
    console.log(convert);
  }

  clear() {
    this.rdpForm.reset();
    this.nuovoProdotto = false;
    this.prodotto = {
      productsId: 0,
      internal_sku: '',
      description: '',
      requested_qty: 0,
      requested_delivery_date: '',
      categories: []
    };
    this.products = [];
  }

  undo() {
    this.nuovoProdotto = false;
    this.prodotto = {
      productsId: 0,
      internal_sku: '',
      description: '',
      requested_qty: 0,
      requested_delivery_date: '',
      categories: []
    };
    this.getRDP();
  }

  AddProdotto(bloccaToast = false) {
    console.log(this.prodotto);
    if (this.prodotto.internal_sku !== '' && this.prodotto.description !== '' && this.prodotto.requested_qty !== 0 && this.prodotto.requested_delivery_date !== '') {
      this.nuovoProdotto = false;
      this.products.push(this.prodotto);
      this.prodotto = {
        productsId: 0,
        internal_sku: '',
        description: '',
        requested_qty: 0,
        requested_delivery_date: '',
        categories: []
      };
    }
  }

  addProdotto() { // Da modal
    this.modificata = true;
    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    this.prodottoForm.controls.categories.setValue(chip);

    if (this.prodottoForm.valid) {
      this.prodottiService.postOrdine(this.prodottoForm.value).subscribe(() => {
        $('#modalProdotto').modal('close');
        this.modificata = false;
        this.getProducts();
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi del prodotto'});
    }
  }

  updateProdotto(event, id) {
    this.prodottiService.GetProductsById(event.value).subscribe((p: any) => {
      this.products[id].internal_sku = event.name;
      this.products[id].productsId = event.value;
      this.products[id].description = p.data.description;
    });
  }

  deleteProdotto(idProdotto) {
    this.products.splice(idProdotto, 1);
  }

  parse(quantita, row?, max?) {
    if (quantita) {
      if (parseFloat(quantita) <= max || !max) {
        if (row) {
          row.ordered_qty = parseFloat(quantita);
        }
        return parseFloat(quantita);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  updateRow(id, rpdId, label, value) {
    if (this.dettaglio) {
      const patch = {};
      if (value) {
        patch[label] = value;
      } else {
        patch[label] = null;
      }
      this.rdpService.UpdateRow(patch, rpdId, id).subscribe(() => {
      });
    }
  }

  getProdotto(event) {
    this.prodotto.internal_sku = event.name;
    this.prodotto.productsId = event.value;
    this.prodottiService.GetProductsById(event.value).subscribe((p: any) => {
      this.prodotto.description = p.data.description;
      p.data.categories.forEach((c: any) => {
        this.prodotto.categories.push(c.label);
      });
    });
  }

  showModalProdotto(command) {
    if (command.action === 'Lista') {
      $('#prodottiModal').modal('open');
      $('#prodottiModal').height($('#tableprodotti').height());
    } else {
      this.prodottoForm.controls.internal_sku.setValue(command.value);
      $('#modalProdotto').modal('open');
    }
  }

  showModalFornitori(command, tag?: boolean) {
    $('#fornitoriModal').modal({
        dismissible: false,
      }
    );

    $('#modalFornitore').modal({
        dismissible: false,
        onCloseEnd: () => {
          document.body.style.overflow = 'auto'; }
      }
    );
    if (command === 'Lista' || command.action === 'Lista') {
      if (tag) {
        $('#fornitoriModal').modal('open');
      } else {
        $('#suppliersModal').modal('open');
      }
    } else {
      this.fornitoreForm.enable();
      this.fornitoriService.GetDDlterminiConsegna().subscribe((res) => {
        this.terminiresa = res.data;
      });
      this.fornitoriService.GetDDlTerminiPagamento().subscribe((res) => {
        this.terminipagamento = res.data;
      });
      this.fornitoreForm.controls.r_sociale.setValue( command.value);
      $('#modalFornitore').modal('open');
    }
  }

  showNoteModal() {
    $('#NoteModal').modal({
        dismissible: false,
      }
    );
    $('#NoteModal').modal('open');
    $('#notes').focus();
  }

  ShowAllegati(idrow?) {
    $('#AllegatiModal').modal('open');
  }

  ShowModalUpload(name?) {
    this.idAllegato = name;
    $('#UploadModal').modal('open');
  }

  closeModal(type?) {
    $('#prodottiModal').modal('close');
    if (type === 'RDP') {
      $('#modalRDP').modal('close');
    }
  }

  discardFile(i) {
    this.file.splice(i, 1);
    $('.tooltiped').tooltip('close');
  }

  deleteRdp() {
    this.rdpService.DeleteRdp('true', this.rdpId).subscribe(() => {
      this.router.navigate(['/dashboard/rdps']);
      this.clear();
    });
  }

  SaveFornitore() {
    // creazione fornitore tramite Modal
    this.modificata = true;
    // inserimento
    const currency = {
      currency: this.fornitoreForm.controls.currency.value
    };
    this.fornitore = this.fornitoreForm.value;
    this.fornitore.price_list = currency;
    const chipmercio = this.chipMercio.nativeElement.textContent.split('close');
    chipmercio.pop();
    this.fornitore.commercial_categories = chipmercio;

    const chiprodotto = this.chipProdotti.nativeElement.textContent.split('close');
    chiprodotto.pop();
    this.fornitore.categories = chiprodotto;

    if (this.fornitoreForm.valid) {
      this.fornitoriService.CreateSupplier(this.fornitore).subscribe(() => {
        $('#modalFornitore').modal('close');
        this.getSuppliers();
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi'});
    }
  }

  Termini(event, type) {
    // funzione per prendere il valore della currency dall'autocomplete
    if (type === 'currency supplier') {
      this.fornitoreForm.controls.currency.setValue(event.value);
      this.fornitoreForm.controls.currency.markAsDirty();
    }
    if (type === 'currency order') {
      this.orderForm.controls.currency.setValue(event.value);
      this.orderForm.controls.currency.markAsDirty();
    }
  }

  formControlName(abstractControl: AbstractControl, modificata) {
    return  this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  validateFormControl(abstractControl: AbstractControl, modificata): object {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  searchfornitori(event) {
    this.suppliers = this.fornitori.filter(f => f.r_sociale.toLowerCase().includes(event.toLowerCase()));
  }

  formatValue() {
    if (this.prodottoForm.get('price').value !== null) {
      this.prodottoForm.patchValue({price: this.numberPipe.transform(this.prodottoForm.get('price').value)});
    }
  }

  ViewAdd(type) {
    if (type === 'product') {
      this.nuovoProdotto = true;
    }
  }

  LabelActivation() {
    if (this.dettaglio) {
      return 'active';
    }
  }

  onFilesAdded(files, type) {
    console.log(files);
    if (type === 'add') {
      files.addedFiles.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.addedFiles.forEach(file => {
        this.file.forEach(f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }
  }

  onRemove(event) {
    console.log('elemento rimossso');
    this.file.splice(this.file.indexOf(event), 1);
  }

  Note(notes) {
    if (this.rowId >= 0) {
      this.rdp.Rows[this.rowId].notes = notes;
     // this.updateRow(this.rdp.Rows[this.rowId].id, this.rdp.Rows[this.rowId].rdpId, 'notes', notes);
    }
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('#selected').find('.selected-table').length === 0) {
        if ($('#Allselect').prop('checked')) {
          $('#Allselect').prop('checked', false);
          this.checkrow = !this.checkrow;
        }
        this.select = false;
      }
    }
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.selected = this.rdp.Rows.map(prodotto => prodotto.id);
      $('#selected').find('tr').addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      this.selected = [];
      $('#selected').find('tr').removeClass('selected-table');
      this.select = false;
      console.log(this.selected);
    }
  }

  downloadPDF() {
    this.rdpService.downloadPDF(this.rdpId).subscribe();
  }

  Showdescription() {}

  SaveFile() {}

  Disabled() {
    if (this.from === 'gara' || this.titlebutton === 'Modifica') {
      return 'disable';
    }
  }

  deleteRdpRow(id) {
    this.rdpService.DeleteRdpRow('false', this.rdpId, id).subscribe( (res: any) => {
      this.getRDP();
    });
  }
}
