import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaComponent } from './lista/lista.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BtnAddModule} from "@app/shared/btnAdd/btnAdd.module";
import {DataTablesModule} from "angular-datatables";
import {DropdownModule} from "@app/shared/dropdown/dropdown.module";
import {DirectiveModule} from "@app/shared/directive/directive.module";
import {RouterModule} from "@angular/router";
import {MzFeatureDiscoveryModule, MzValidationModule} from "ngx-materialize";
import {AutocompleteModule} from "@app/shared/autocomplete/autocomplete.module";
import {DashboardModule} from "@app/dashboard/dashboard.module";
import {AngularResizedEventModule} from "angular-resize-event";
import {MultifilterModule} from "@app/shared/multifilter/multifilter.module";
import {BreadCrumbsModule} from "@app/shared/breadCrumbs/breadCrumbs.module";
import {DetailsNavModule} from "@app/shared/detailsNavigation/detailsNav.module";
import {PaginationModule} from "@app/shared/pagination/pagination.module";
import {ChartsModule} from "ng2-charts";

@NgModule({
  declarations: [ListaComponent],
  imports: [
    TranslateModule,
    ChartsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BtnAddModule,
    DataTablesModule,
    DropdownModule,
    DirectiveModule,
    RouterModule,
    MzFeatureDiscoveryModule,
    MzValidationModule,
    AutocompleteModule,
    DashboardModule,
    AngularResizedEventModule,
    MultifilterModule,
    BreadCrumbsModule,
    DetailsNavModule,
    PaginationModule,
  ]
})
export class StatisticheModule { }
