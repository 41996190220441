import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { DataTablesModule } from 'angular-datatables';

import { MzSelectModule, MzModalModule, MzSidenavModule, MzDropdownModule, MzInputModule, MzTooltipModule } from 'ngx-materialize';
import { RouterModule } from '@angular/router';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {DropdownModule} from '@app/shared/dropdown/dropdown.module';
import {DirectiveModule} from '@app/shared/directive/directive.module';
import {TablerowModule} from '@app/shared/tableRow/tablerow.module';
import {BtnAddModule} from '@app/shared/btnAdd/btnAdd.module';
import {TranslateModule} from '@ngx-translate/core';
import {AutocompleteModule} from '@app/shared/autocomplete/autocomplete.module';
import {PipeModule} from '@app/shared/pipe/pipe.module';
import {MultifilterModule} from '@app/shared/multifilter/multifilter.module';
import {DashboardModule} from '@app/dashboard/dashboard.module';
import {AngularResizedEventModule} from 'angular-resize-event';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {BreadCrumbsModule} from '@app/shared/breadCrumbs/breadCrumbs.module';
import {CustomPipeModule} from '@app/shared/customPipes/customPipe.module';
import {DetailsNavModule} from '@app/shared/detailsNavigation/detailsNav.module';
import {PaginationModule} from '@app/shared/pagination/pagination.module';
import {ListaComponent} from '@app/dashboard/Rdp/lista/list.component';
import {FormComponent} from '@app/dashboard/Rdp/form/form.component';
import {StatusModule} from '@app/shared/status/status.module';

@NgModule({
  declarations: [ListaComponent, FormComponent],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    BtnAddModule,
    ReactiveFormsModule,
    DataTablesModule,
    DirectiveModule,
    HttpClientModule,
    DropdownModule,
    TablerowModule,
    MzSelectModule,
    MzSidenavModule,
    MzDropdownModule,
    MzInputModule,
    MzTooltipModule,
    TranslateModule,
    AutocompleteModule,
    PipeModule,
    MultifilterModule,
    DashboardModule,
    AngularResizedEventModule,
    NgxDropzoneModule,
    BreadCrumbsModule,
    CustomPipeModule,
    DetailsNavModule,
    PaginationModule,
    StatusModule,
  ]
})
export class RdpModule { }
