import { Component, OnInit, ViewChild, AfterViewInit, OnChanges, DoCheck, AfterContentInit } from '@angular/core';
import { ListinoService } from '../listino.service';
import {ProdottiListaModel} from '@app/shared/models/db_tables/prodotti-lista.model';
import {DataTableDirective} from 'angular-datatables';
import Swal from 'sweetalert2';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UtilityService} from '@app/shared/utility.service';
import { any } from 'codelyzer/util/function';
import { LanguageVariant } from 'typescript';
import { async } from 'q';
import {map} from 'rxjs/operators';
import {OrdiniListaModel} from '@app/shared/models/db_tables/ordini-lista.model';

declare const $: any;

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  dtOptions: DataTables.Settings = {
    responsive: {
      details: true
    },
    autoWidth: false,
    language: {},
  };

  orderby = 'none';
prodotti: Array<ProdottiListaModel> = [];
filteredprodotti: Array<ProdottiListaModel> = [];

posBreadcrumbs;
selected = [];
checkrow = false;
select = false; // per far apparrire o sparire i bottoni nelle actionbar
filtersLocked: boolean;
listfilter = [];

// per filtro
  categoriesFilter: any;
  chips: any = [];
  filter = {search: {}, filters: {}};

// per paginazione
  totalPage: number;

user;


constructor(
    private listinoService: ListinoService,
    private translate: TranslateService,
    private utilityService: UtilityService
  ) {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.get('breadcrumbs.catalog.list').subscribe((app: string) => {
          utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/list'};
        });
      });
   }

  ngAfterViewInit() {
    this.translate.onLangChange
    .subscribe(() => {
      this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
        this.dtOptions.language = x;
        console.log(x);
      });
    });

    const table = $('#dataTables').DataTable();

    table.ajax.reload();
  }

  ngOnInit() {
    this.user =  this.utilityService.user;
    this.utilityService.Navigation = [];
    this.getListProducts();

    this.translate.get('breadcrumbs.catalog.list').subscribe((app: string) => {
      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: app, path: '/list'});
    });

    this.translate.onLangChange
    .subscribe((event: LangChangeEvent) => {
      this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
        this.dtOptions.language = x;
        console.log(x);
      });
    });
  }

  deleteProduct(id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questo prodotto',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'Il prodotto è stata eliminato con successo',
          'success'
        );
        this.listinoService.DeleteProducts(id).subscribe(() => {
            this.getListProducts();
          }
        );
      }
    });
  }

  deleteProductSelected() {
  // chiamata per eliminazione massiva
    console.log(this.selected);
    this.listinoService.MassDeleteProducts({IDs: this.selected}).subscribe((res: any) => {
      this.getListProducts();
    });
    this.select = false;
    this.selected = [];
  }

  getListProducts(filter?, cat = null) {
    if (sessionStorage.getItem('filterCatProd') && sessionStorage.getItem('filterCatProd') !== 'undefined') {
      const filterControl = JSON.parse(sessionStorage.getItem('filterProd'));
      this.categoriesFilter = JSON.parse(sessionStorage.getItem('filterCatProd'));
      this.chips = JSON.parse(sessionStorage.getItem('filterChipProd'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filter = filterControl;
        // this.getListRows(false, JSON.parse(sessionStorage.getItem('filterRows')), true);
      }
    }
    this.listinoService.GetListProducts(this.translate.currentLang, filter).subscribe((res: any) => {
      this.prodotti = res.data;
      if (!sessionStorage.getItem('filterCatProd') || sessionStorage.getItem('filterCatProd') === 'undefined') {
        // this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
        this.categoriesFilter = res.filtersList;
      }
      this.totalPage = res.pagination.totalPages;
      this.filteredprodotti = this.prodotti;
    });
  }

  prova() {
    this.translate.onLangChange
    .subscribe(() => {
      this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
        this.dtOptions.language = x;
        console.log(x);
      });
    });
  }

  translateType(type) {
    if (type === 'status') {
      return this.translate.get('Orders.filter.status');
    } else if (type === 'supplier') {
      return this.translate.get('Orders.filter.supplier');
    } else if (type === 'order_code') {
      return this.translate.get('Orders.filter.order_code');
    } else if (type === 'allFields') {
      return this.translate.get('Orders.filter.allFields');
    }
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
        this.select = false;
      }
    }
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.prodotti.forEach((prodotto) => {
        this.selected.push(prodotto.id);
      });
      $('tbody').find('tr').addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      this.selected = [];
      $('tbody').find('tr').removeClass('selected-table');
      this.select = false;
      console.log(this.selected);
    }
  }

  emitSearch(event) {
    console.log('save Search');
    if (event.action !== 'delete') {
      if (this.filter.search[event.property] === undefined) {
        this.filter.search[event.property] = [];
      }
      this.filter.search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter();
    }
  }

  saveFilter(event) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        if (this.filter.filters[event.type] === undefined) {
          this.filter.filters[event.type] = [];
        }
        this.filter.filters[event.type].push(event.value);
      } else {
        this.filter.filters[event.type] = event.value;
      }
    } else {
      delete this.filter.filters[event.type];
    }
    this.Searchwithfilter(event.cat);
  }

  Searchwithfilter(cat?) {
    console.log('apply');
    if (cat !== undefined) {
      sessionStorage.setItem('filterCatProd', JSON.stringify(cat));
    }
    sessionStorage.setItem('filterProd', JSON.stringify(this.filter));
    this.getListProducts(this.filter);
    //   sessionStorage.setItem('filterCat', JSON.stringify(cat));
    //   sessionStorage.setItem('filter', JSON.stringify(this.filter));
  }

  deleteFilter(items) {
    console.log('delete chip');
    this.filter = {search: {}, filters: {}};
    let isSearch = false;
    const categories: Array<any> = this.categoriesFilter.search;
    const categoriesFilters: Array<any> = this.categoriesFilter.filters;
    console.log('lenght items', items.length);
    console.log('items', items);
    if (items.length > 0) {
      items.forEach((item) => {
        console.log('items foreach', item.tag);
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter.search[cat.name] === undefined) {
              this.filter.search[cat.name] = [];
            }
            this.filter.search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  console.log('false');
                  option.active = false;
                } else {
                  console.log('trueeeeeeeeee');
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Categoria') {
                      // aggiungere su questo if il filte.name del filtro a selezione multipla
                      this.filter.filters[filter.name] = option.value;
                      console.log('dentro if', this.filter);
                    } else {
                      if (this.filter.filters[filter.name] === undefined) {
                        this.filter.filters[filter.name] = [];
                        console.log('status init');
                      }
                      console.log(option.value);
                      this.filter.filters[filter.name].push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type  && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  console.log('no option', item.tag);
                  const res = item.tag.split(' ');
                  this.filter.filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    console.log(this.categoriesFilter);
    console.log(this.filter);
    this.Searchwithfilter(this.categoriesFilter);
  }

  pagination(page) {
    console.log(page);
    this.filter['pagination'] = page;
    console.log(this.filter);
    this.getListProducts(this.filter);
  }

  orderByHeader(element) {
    // Todo
    if (element === this.orderby) {
      this.orderby = element + '_less';
    } else {
      this.orderby = element;
    }
  }
}
