
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { MzDropdownModule, MzButtonModule } from 'ngx-materialize';
import {PublicComponent} from '@app/public/public.component';
import {DropzoneDirective} from "@app/shared/directive/dropzone/dropzone.directive";
import {SelectDirective} from "@app/shared/directive/select.directive";
import {TooltipDirective} from "@app/shared/directive/tooltip.directive";
import {RowstatuscolorDirective} from "@app/shared/directive/rowstatuscolor.directive";
import {DatapickerDirective} from "@app/shared/directive/datapicker.directive";
import {ShowbuttonDirective} from "@app/shared/directive/showbutton.directive";
import {TagDirective} from "@app/shared/directive/tag.directive";
import {HeightTabDirective} from "@app/shared/directive/heightTab.directive";
import {ModalDirective} from "@app/shared/directive/modal.directive";
import {DropdownDirective} from "@app/shared/directive/dropdown.directive";
import {OrderByDirective} from "@app/shared/directive/orderBy.directive";
import { TabDirective } from './tab.directive';

@NgModule({
  declarations: [
    DropzoneDirective,
    SelectDirective,
    TooltipDirective,
    RowstatuscolorDirective,
    DatapickerDirective,
    ShowbuttonDirective,
    TagDirective,
    HeightTabDirective,
    ModalDirective,
    DropdownDirective,
    OrderByDirective,
    TabDirective
    ],
  exports: [
    DropzoneDirective,
    SelectDirective,
    TooltipDirective,
    RowstatuscolorDirective,
    DatapickerDirective,
    ShowbuttonDirective,
    TagDirective,
    HeightTabDirective,
    ModalDirective,
    DropdownDirective,
    OrderByDirective,
    TabDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    MzDropdownModule,
    MzButtonModule
  ]
})
export class DirectiveModule { }
