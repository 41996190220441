import {AfterContentInit, Directive, ElementRef} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[DMTab]'
})
export class TabDirective implements AfterContentInit {

  constructor(private el: ElementRef) { }

  ngAfterContentInit(): void {
    $(this.el.nativeElement).tabs();
  }

}
