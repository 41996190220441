import {Injectable} from "@angular/core";
import {BaseService} from "@app/base.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ServerBaseResponse} from "@app/shared/models/server-base-response";
import {environment} from "../../../environments/environment";

declare const moment: any;

@Injectable({
  providedIn: 'root'
})
export class RdaService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super();
  }


  GetListRda(loader = 'false', filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas', filters , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetListRdaRows(id, filters?, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + id + '/rows', filters , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  CreateRdaRow(id, row, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + id + '/rows/new', row , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  UpdateRdaRow(idrda, idrow, patch, loader = 'false',): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + idrda + '/rows/' + idrow, patch , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
 DeleteRdaRow(idrda, idrow, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .delete <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + idrda + '/rows/' + idrow, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetRdaByid(id, filters?, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + id, filters, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  CreateRda(Rda, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/new', Rda , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  DeleteRda(loader = 'false', id): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .delete <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + id , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetHistoryRda(loader = 'false', id, filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + id + '/history', filters, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetCurrentCompanies(): Observable <ServerBaseResponse<any>> {
    return this.httpClient.get<ServerBaseResponse<any>>(environment.apiUrl + '/companies/current', {withCredentials: true});
  }
  UpdateRda(idrda, patch, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/' + idrda , patch , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  convertToOrder(convert, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/convert/rdas-to-order', convert, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  convertToRdp(convert, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/convert/rdas-to-rdp', convert, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  convertToCompetition(convert, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/convert/rdas-to-competition', convert, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  MassDeleteRda(rdpsId, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdas/bulk-delete', rdpsId, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
}
