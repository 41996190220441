import {Injectable} from '@angular/core';
import {BaseService} from '@app/base.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConsegneListaModel} from '@app/shared/models/db_tables/.consegne-lista.model';
import {ServerBaseResponse} from '@app/shared/models/server-base-response';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsegneService extends BaseService {

  constructor(translate: TranslateService,
              private httpClient: HttpClient) {
    super();
  }
  getList(loader = 'false', filter?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>( environment.apiUrl + '/deliveries', filter , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
}
