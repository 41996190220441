import { Component, OnInit } from '@angular/core';
import {UtilityService} from "@app/shared/utility.service";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  posBreadcrumbs;
  view = 'table';
  settings = {};

  constructor(private utilityService: UtilityService) { }

  ngOnInit() {
    this.utilityService.Navigation = [];
    this.posBreadcrumbs = this.utilityService.Navigation.length;
    this.utilityService.Navigation.push({title: 'Report', path: '/report'});

    // TODO api per report
    this.settings = [{ label: ['th1', 'th2', 'th3', 'th4'],
                       value: [{data: [6, 7, 10, 1], label: 'example1'},
                              {data: [10, 3, 5, 9], label: 'example2'},
                              {data: [13, 20, 14, 7], label: 'example2'}],
                        title: 'Blocco 1'
                    },
                    { label: ['th1', 'th2', 'th3', 'th4'],
                      value: [{data: [6, 7, 10, 1], label: 'example1'}],
                      title: 'Blocco 2'
                    }];
  }

  changeView(index, view) {
    this.settings[index].view = view;
    console.log(this.settings);
  }

}
