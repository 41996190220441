import {
  AfterContentInit,
  AfterViewChecked,
  Directive,
  ElementRef, Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";

declare const $: any;

@Directive({
  selector: '[DmheightTab]'
})
export class HeightTabDirective implements AfterContentInit {

  constructor(private el: ElementRef) {}

  @Input() DmheightTab = 0;

  ngAfterContentInit(): void {
    this.CalcHeight();
  }
  CalcHeight() {
    let topHeight = 0;
    let currentHeight = 0;
    const obj = $(this.el.nativeElement).children('.height-tab');
    for (let i = 0; i < obj.length; i += 1) {
      currentHeight = $(obj[i]).height();
      if ( currentHeight > topHeight) {
        topHeight = currentHeight + this.DmheightTab;
      }
    }
    this.setHeight(topHeight);
  }
  setHeight(height) {
    $(this.el.nativeElement).children('.height-tab').css('min-height', height + 'px');
  }
}
