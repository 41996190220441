import {
  AfterContentInit,
  AfterViewChecked,
  Directive,
  ElementRef, Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";

declare const $: any;

@Directive({
  selector: '[DmOrderBy]'
})
export class OrderByDirective implements AfterContentInit, OnChanges {

  constructor(private el: ElementRef) {}

  @Input() DmOrderBy = 'none';
  @Input() true;

  ngAfterContentInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    $(this.el.nativeElement).addClass('cursor-pointer');
    if (this.DmOrderBy === this.true) {
      if ($(this.el.nativeElement).children('.material-icons')) {
        $(this.el.nativeElement).children('.material-icons').remove();
      }
      $(this.el.nativeElement).append('<i style="margin-left: 0px !important;" class="material-icons right">expand_more</i>');
    } else if (this.DmOrderBy === this.true + '_less') {
      if ($(this.el.nativeElement).children('.material-icons')) {
        $(this.el.nativeElement).children('.material-icons').remove();
      }
      $(this.el.nativeElement).append('<i style="margin-left: 0px !important;" class="material-icons right">expand_less</i>');
    } else {
      $(this.el.nativeElement).children('.material-icons').remove();
    }
  }
}
