import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'DM-reporTable',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {

 @Input() settings = [];


  constructor() { }

  ngOnInit() {
  }

}
