import {AfterViewInit, ApplicationRef, Component, ElementRef, HostListener, NgZone, OnInit, Type, ViewChild} from '@angular/core';
import { OrdiniService} from '../ordini.service';
import {DataTableDirective} from 'angular-datatables';
import { Template } from '@app/template.service';

import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {selectValueAccessor} from   '@angular/forms/src/directives/shared';
import * as moment from 'moment';
import {OrdiniRigheListaModel} from '@app/shared/models/db_tables/ordini-righe-lista.model';
import {ServerBaseResponse} from '@app/shared/models/server-base-response';
import {OrdiniListaModel} from '@app/shared/models/db_tables/ordini-lista.model';
import {lifecycleHooksMethods} from 'codelyzer/noLifeCycleCallRule';
import {FornitoriService} from '@app/dashboard/fornitori/fornitori.service';
import {map} from 'rxjs/operators';
import Swal from 'sweetalert2';
import set = Reflect.set;
import {UtilityService} from '@app/shared/utility.service';
import {del} from 'selenium-webdriver/http';
import {OrderModel} from '@app/shared/models/db_tables/.order.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MultifilterComponent} from "@app/shared/multifilter/multifilter.component";
import {ActivatedRoute} from "@angular/router";

declare const $: any;
declare const M: any;

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;

  orderby = 'none'
  tabRighe = $('righe').DataTable({
    deferRender: true
  });
  // dtTriggerRows: Subject<any> = new Subject();

  // dtTrigger: Subject<any> = new Subject();
  DetailOrder: any;
  contatoreRighe;
  rowstosend;
  OrderForm: FormGroup = null;
  CodeOrderDetail;
  icontext = 'list';
  propertySearch;
  confirmed = true;
  select = false;
  selectOrders = false;
  moment = moment;
  dtOptions: any = {};
  dtOptionsLogTable: any = {};
  dtOptionsrighe: any = {};
  ordini: Array<OrdiniListaModel> = [];
  modifica = false;
  filtersLocked: boolean;
  visualizza = 'Righe';
  rows: Array<OrdiniRigheListaModel> = [];
  checkrow = false;
  new;
  ShowDescription = false;
  checkRevisione = false;
  note;
  filename = [];
  storicorev;
  commentoRev;
  idAllegato;
  idSaveupdate = { row: null, order: null };
  reverseOrdersRow: any;
  reverseOrder: any;
  idmodifica;
  response_term;
  terminiresa;
  terminipagamento;
  importa;
  refresh = true;
  hourResponseTerm;
  prova = '/13';
  iconVisibility;
  selected = [];
  listfilter = [];
  file = [];
  showRitardo = false;
  setFilter: any; // setta filtro da questo component, attiva onchange del multifilter

  statusRowNoteModal;
  posBreadcrumbs;
  updateRow = {orderid: null, rowId: null};
  raggruppamento = [];

  user;

  rowLoaders = [];

  // per filtro
  categoriesFilter: any;
  categoriesFilterOrders: any;
  chips: any = [];
  chipsOrders: any = [];
  filter = {search: {}, filters: {}};
  filterOrders = {search: {}, filters: {}};

  // per paginazione
  totalPageOrders: number;
  totalPageRows: number;
  totalPageLog: number;

  constructor(
    private ordiniservice: OrdiniService,
    private translate: TranslateService,
    private fornitoriService: FornitoriService,
    private appref: ApplicationRef,
    private utilityService: UtilityService,
    private dropzone: ElementRef,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute,
    private fornitoriservice: FornitoriService) {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
        this.translate.get('Orders.List.Button.new', {value: 'world'}).subscribe((x: string) => {
          this.new = x;
          console.log('ciao dddd');
        });
        this.translate.get('Orders.List.Button.import', {value: 'world'}).subscribe((x: string) => this.importa = x);
        this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
          console.log('ciao subscribe', x);
          this.dtOptions = {
            responsive: {
              details: true
            },
            autoWidth: false,
            language: x,
          };
          this.dtOptionsLogTable = {
            responsive: true,
            autoWidth: false,
            language: x,
          };
          this.refresh = false;

          this.translate.get('breadcrumbs.orders.list').subscribe((app: string) => {
            this.utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/orders'};
          });
        });
        setTimeout(() => {
          this.refresh = true;
        }, 0);
      });
  }

  ngOnInit(): void {
    this.raggruppamento = [
      {label: 'Ordini', active: false },
      {label: 'Righe', active: true }
      ];
    this.user =  this.utilityService.user;
    this.initFormDetail();
    $('.collapsible').collapsible();
    this.ordiniservice.getCurrentCompaniens().subscribe((res: any) => {
      console.log(res);
      this.response_term = res.data.response_term;
    });
    this.utilityService.Navigation = [];

    this.translate.get('breadcrumbs.orders.list').subscribe((app: string) => {
      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: app, path: '/orders'});
    });


    this.router.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {
        console.log(params);
        this.getListRows('false', {search:{}, filters: {status: ['proposed_buyer', 'proposed_supplier']}}, false, true);
      } else {
        console.log('nada');
        this.getListRows('false');
      }
    });

    this.getListOrders();

    this.translate.get('Orders.List.Button.new', {value: 'world'}).subscribe((x: string) => this.new = x);
    this.translate.get('Orders.List.Button.import', {value: 'world'}).subscribe((x: string) => this.importa = x);

  }

  initFormDetail() {
    this.fornitoriservice.GetDDlterminiConsegna().subscribe((res) => {
      this.terminiresa = res.data;
      console.log(this.terminiresa);
    });
    this.fornitoriservice.GetDDlTerminiPagamento().subscribe((res) => {
      this.terminipagamento = res.data;
      console.log(this.terminipagamento);
    });
  }

  textdelay(date) {
    const delay = moment().diff(moment(date), 'days');
    return delay;
  }


  getListRows(loader, filter?, stop = false, query = false) {
    // console.log("loader getListRows: ", loader);
    if (query) {
      this.chips.push({type: 'Competition status', tag: 'proposed buyer', icon: 'filter_list'});
      this.chips.push({type: 'Competition status', tag: 'proposed supplier', icon: 'filter_list'});
      console.log(this.chips);
    }
    if (sessionStorage.getItem('filterCatRows') && sessionStorage.getItem('filterCatRows') !== 'undefined' && !stop) {
      const filterControl = JSON.parse(sessionStorage.getItem('filterRows'));
      this.categoriesFilter = JSON.parse(sessionStorage.getItem('filterCatRows'));
      this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filter = filterControl;
       // this.getListRows(false, JSON.parse(sessionStorage.getItem('filterRows')), true);
      }
    }
    this.ordiniservice.GetListRows(loader, this.translate.currentLang, filter).subscribe((res: any) => {
      this.rows = res.data;

      res.data.forEach( (row) => {
        if (row.status === 'accepted') {
           this.showRitardo = true;
        }
      });
      if (!sessionStorage.getItem('filterCatRows') || sessionStorage.getItem('filterCatRows') === 'undefined') {
        // this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
        this.categoriesFilter = res.filtersList;
      }
      if (query) {
        const categories: Array<any> = this.categoriesFilter.filters;
        categories.forEach((cat) => {
          if (cat.name === 'categories') {
            cat.options.forEach((option) => {
              if (option.label === 'started') {
                option.active = true;
              }
            });
          }
        });
        this.filter = filter;
      }
      this.totalPageRows = res.pagination.totalPages;
      this.rowstosend = this.rows.find(this.rowsToSend);
      this.contatoreRighe = res.stats.rowsToBeSent;
    });
  }

  rowsToSend(row) {
    if (row.status === 'inserted' || 'proposed_buyer') {
      return row;
    }
  }

  getListOrders(loader = 'false', filter = {}) {
    if (sessionStorage.getItem('filterCatOrders') && sessionStorage.getItem('filterCatOrders') !== 'undefined') {
      const filterControl = JSON.parse(sessionStorage.getItem('filterOrders'));
      this.categoriesFilterOrders = JSON.parse(sessionStorage.getItem('filterCatOrders'));
      this.chipsOrders = JSON.parse(sessionStorage.getItem('filterChipOrders'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filterOrders = filterControl;
        // this.getListRows(false, JSON.parse(sessionStorage.getItem('filterRows')), true);
      }
    }
    this.ordiniservice.GetListOrders(loader, this.translate.currentLang, filter)
      .subscribe((res: any) => {
        this.ordini = res.data;
        res.data.map((r) => {
          this.listfilter.push({value: r.code.toString(), name: 'Cerca per codice ordine', property: 'code_order'});
        });
        if (!sessionStorage.getItem('filterCatOrders') || sessionStorage.getItem('filterCatOrders') === 'undefined') {
          // this.chips = JSON.parse(sessionStorage.getItem('filterChipRows'));
          this.categoriesFilterOrders = res.filtersList;
        }
        this.totalPageOrders = res.pagination.totalPages;
        console.log("ordini: ", this.categoriesFilterOrders);
      });
  }

  //  funzione per prove
  ciao(id) {
    console.log(this.rows);
    console.log(this.prova);
    this.select = true;
  }

  ngAfterViewInit(): void {
  }

  saveUpdate(event, row) {
    // funzione per salvattagio modifiche di una singola riga
    this.idSaveupdate.row = row.id;
    this.idSaveupdate.order = row.ordersId;
    this.rowLoaders[row] = true;
    this.ordiniservice.UpdateRows(row.ordersId, row.id, row).subscribe((res: ServerBaseResponse<OrdiniRigheListaModel>) => {
      console.log(res);
      this.getListRows('false');
      this.getListOrders('true');
      setTimeout(() => {
        this.rowLoaders[row] = false;
      }, 500);
    //  this.showNoteModal();
    }, (error1) => {
      this.rowLoaders[row.id] = false;
      M.toast({html: error1.error.errors} );
    });
    //  this.modifica = false;
    $(event.path[3]).removeClass('color-update'); // 3 perchè mi serve prendere il terzo padre(tr)
  }

  patchRow( idrow, idorder, label, value, note) {
    console.log(value);
    console.log('dai sususususussusu');
    const patch = {};
    patch[label] = value;
    this.note = note;
    this.idSaveupdate.row = idrow;
    this.idSaveupdate.order = idorder;
    this.rowLoaders[idrow] = true;
    this.ordiniservice.UpdateRows(idorder, idrow, patch).subscribe((res: ServerBaseResponse<OrdiniRigheListaModel>) => {
      console.log(res);
      this.getListRows('true');
      this.getListOrders('true');
      setTimeout(() => {
        this.rowLoaders[idrow] = false;
      }, 500);
      /*  if (label !== 'notes') {
          this.showNoteModal();
        }*/
    }, (error1) => {
      this.rowLoaders[idrow] = false;
      M.toast({html: error1.error.errors} );
    });
    //  this.modifica = false;
    //  $(event.path[3]).removeClass('color-update'); // 3 perchè mi serve prendere il terzo padre(tr)
  }

  saveNote() {
    console.log('ciao');
    if (this.statusRowNoteModal !== 'accepted' || this.statusRowNoteModal !== 'canceled') {
      const objupdate = {notes: this.note};
      this.rowLoaders[this.updateRow.rowId] = true;
      this.ordiniservice.UpdateRows(this.updateRow.orderid, this.updateRow.rowId, objupdate).subscribe(
        (res: ServerBaseResponse<OrdiniRigheListaModel>) => {
          console.log(res);
          this.getListRows('true');
          this.getListOrders('true');
          setTimeout(() => {
            this.rowLoaders[this.updateRow.rowId] = false;
          }, 500);
        }, (error1) => {
          this.rowLoaders[this.updateRow.rowId] = false;
          M.toast({html: error1.error.errors});
        });
    }
    $('#NoteModal').modal('close');
  }

  dismissNote() {
    $('#NoteModal').modal('close');
  }

  ChangeView(type) {
    this.checkrow = false;
    this.visualizza = type;
    this.selected = [];
    this.selectOrders = false;
    this.select = false;
  }


  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      if (this.visualizza === 'Righe') {
        this.select = true;
      } else {
        this.selectOrders = true;
      }
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('#selected').find('.selected-table').length === 0) {
        if ($('#Allselect').prop('checked')) {
          $('#Allselect').prop('checked', false);
          this.checkrow = !this.checkrow;
        }
        if (this.visualizza === 'Righe') {
          this.select = false;
        } else {
          this.selectOrders = false;
        }
      }
    }
    this.checkOrdersConfrimed();
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.rows.forEach((row) => {
        this.selected.push(row.id);
      });
      $('#selected').find('tr').addClass('selected-table');
      if (this.visualizza === 'Righe') {
        this.select = true;
      } else {
        this.selectOrders = true;
      }
      console.log(this.selected);
    } else {
      this.selected = [];
      $('#selected').find('tr').removeClass('selected-table');
      if (this.visualizza === 'Righe') {
        this.select = false;
      } else {
        this.selectOrders = false;
      }
      console.log(this.selected);
    }
    console.log($('#selected').find('.selected-table'));
    this.checkOrdersConfrimed();
  }

  checkOrdersConfrimed() {
    this.confirmed = true;
    this.selected.forEach( x => {
      this.rows.forEach(y => { if (y.id === x) {
        console.log('confirmed', y.status);
        if (y.status !== 'accepted') {
          this.confirmed = false;
          console.log('CONFIRMED MODIFICA: ', this.confirmed);
        }
      }});
    });
  }

  deleteRow(idOrder, idRow) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questa riga',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'La riga è stata eliminata con successo',
          'success'
        );
        this.ordiniservice.DeleteRows(idOrder, idRow).subscribe((res) => {
          this.getListRows('false');
        });
      }
    });
  }

  /* funzione per cambiare colore al value del datepicker
    datapickerStyle(input) {
      console.log(input.target.value);
      console.log(moment().format('YYYY-MM-DD'));
      if (moment(input.target.value).diff(moment().format('YYYY-MM-DD'), 'days') >= 0 ) {
        console.log('blcak');
        $(input.path[0]).removeClass('red-text');
        $(input.path[0]).addClass('black-text');
      } else {
        console.log('red');
        $(input.path[0]).removeClass('black-text');
        $(input.path[0]).addClass('red-text');
      }

    }
    */

  controlloView(date, view) {
    const ore = this.response_term * 24;
    const oreperc = (ore * 70) / 100;

    if (view) {
      this.iconVisibility = 'visibility';
    } else {
      this.iconVisibility = 'visibility_off';
    }

    if (moment(date).add(oreperc, 'hour').diff(moment(), 'hours') >= 0) {
      return '#2196f3';
    } else if (moment().diff(moment(date).add(this.response_term, 'days'), 'hours') <= 0) {
      return '#ffd908';
    } else {
      return '#9e1010';
    }

  }

  deleteRows() {
  }

  updateStatusOrders(status) {
    console.log(this.selected);
    const IDs = {ids: this.selected};
    this.ordiniservice.UpdateStatusOrder(IDs, status).subscribe((res) => {
      this.getListRows('true');
      this.getListOrders();
      this.selected = [];
      this.select = false;
    });
  }

  SendOrders(status) {
    const IDs = {ids: this.contatoreRighe.IDs};
    this.ordiniservice.UpdateStatusOrder(IDs, status).subscribe((res) => {
      this.getListRows('true')
      this.getListOrders();
    });
  }

  updateStatusRow(id, status) {
    console.log('ciaomodal');
    const ids = {
      ids: [id]
    };
    this.ordiniservice.UpdateStatusOrder(ids, status).subscribe((res) => {
      this.getListRows('true');
      this.getListOrders();
      this.selected = [];
      this.select = false;
   //   this.showNoteModal();
    });
  }

  annullaModifiche(idorders, idrow) {
    // api per annullamento
    this.ordiniservice.undoVersion(idorders, idrow, true).subscribe(() => {
        this.getListOrders();
        this.getListRows(false);
      }
    );
  }

  deleteOrder(id) {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare quest ordine',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'L ordine è stata eliminato con successo',
          'success'
        );
        this.ordiniservice.DeleteOrders(id).subscribe(() => {
          this.getListOrders();
          this.getListRows('false');
        });
      }
    });
  }

  deleteOrdersSelected() {
    console.log(this.selected);
    this.ordiniservice.MassDeleteOrders({IDs: this.selected}).subscribe((res: any) => {
      this.getListOrders();
      this.getListRows('false');
      this.selected = [];
      this.selectOrders = false;
    });
  }

  deleteRowsSelected() {
    console.log(this.selected);
    this.ordiniservice.MassDeleteRows({IDs: this.selected}).subscribe((res: any) => {
      this.getListOrders();
      this.getListRows('false');
      this.selected = [];
      this.select = false;
    });
  }

  acceptRows() {
  }

  tooltipView(date, icon) {
    let text = 'ciao';
    const ore = this.response_term * 24;
    const oreperc = (ore * 70) / 100;
    if (icon === 'visibility') {
      text = 'visualizzato, ';
    } else {
      text = 'non visualizzato, ';
    }

    if (moment(date).add(oreperc, 'hour').diff(moment(), 'hours') >= 0) {
      return text += 'dentro i tempi di risposta';
    } else if (moment().diff(moment(date).add(this.response_term, 'days'), 'hours') <= 0) {
      return text += 'tempi di risposta quasi scaduti';
    } else {
      return text += 'tempo di risposta non rispettato';
    }

  }

  ColorStatus(stato) {
    if (stato === 'accepted') {

      return 'status-accepted';

    } else if (stato === 'canceled') {

      return 'status-canceled';

    } else if (stato === 'inserted') {

      return 'status-inserted';

    } else if (stato === 'proposed_buyer') {

      return 'status-proposed_buyer';

    } else if (stato === 'edited_buyer' || stato === 'edited_supplier') {

      return 'status-edited_buyer';

    } else if (stato === 'proposed_supplier') {

      return 'status-edited_supplier';

    } else if (stato === 'received') {

      return 'status-received';

    }
  }

  tooltipStatus(stato) {
    if (stato === 'accepted') {

      return 'accettato';

    } else if (stato === 'canceled') {

      return 'cancellato';

    } else if (stato === 'inserted') {

      return 'inserito';

    } else if (stato === 'proposed_buyer') {

      return 'proposto dal compratore';

    } else if (stato === 'edited_buyer') {

      return 'modificato dal compratore';

    } else if (stato === 'proposed_supplier') {

      return 'proposto dal fornitore';

    } else if (stato === 'received') {

      return 'ricevuto';

    }
  }

  RowColor(stato) {
    if (stato === 'accepted') {

      return 'row-accepted';

    } else if (stato === 'canceled') {

      return 'row-canceled';

    } else if (stato === 'inserted') {

      return 'row-inserted';

    } else if (stato === 'proposed_buyer') {

      return 'row-proposed_buyer';

    } else if (stato === 'edited_buyer') {

      return 'row-edited_buyer';

    } else if (stato === 'edited_supplier') {

      return 'row-edited_supplier';

    } else if (stato === 'received') {

      return 'row-received';

    }
  }
  prova1() {
    console.log('ciao');
  }

  emitSearch(event, table) {
    if (event.action !== 'delete') {
      if (table === 'rows') {
        if (this.filter.search[event.property] === undefined) {
          this.filter.search[event.property] = [];
        }
        this.filter.search[event.property].push(event.name);
        // this.filter.search[event.property] = event.name;
        this.Searchwithfilter(table);
      } else {
        if (this.filterOrders.search[event.property] === undefined) {
          this.filterOrders.search[event.property] = [];
        }
        this.filterOrders.search[event.property].push(event.name);
        // this.filter.search[event.property] = event.name;
        this.Searchwithfilter(table);
      }
    }
  }

  saveFilter(event, table) {
    if (table === 'rows') {
      if (event.value !== 'null') {
        if (event.selection === 'multi') {
          if (this.filter.filters[event.type] === undefined) {
            this.filter.filters[event.type] = [];
          }
          this.filter.filters[event.type].push(event.value);
        } else {
          this.filter.filters[event.type] = event.value;
        }
      } else {
        delete this.filter.filters[event.type];
      }
      this.Searchwithfilter(table, event.cat);
    } else {
      if (event.value !== 'null') {
        if (event.selection === 'multi') {
          if (this.filterOrders.filters[event.type] === undefined) {
            this.filterOrders.filters[event.type] = [];
          }
          this.filterOrders.filters[event.type].push(event.value);
        } else {
          this.filterOrders.filters[event.type] = event.value;
        }
      } else {
        delete this.filterOrders.filters[event.type];
      }
      this.Searchwithfilter(table, event.cat);
    }
  }

  Searchwithfilter(table, cat?) {
    console.log('apply');
    if (table === 'rows') {
      if (cat !== undefined) {
        sessionStorage.setItem('filterCatRows', JSON.stringify(cat));
      }
      sessionStorage.setItem('filterRows', JSON.stringify(this.filter));
      this.getListRows('false', this.filter);
    } else {
      if (cat !== undefined) {
        sessionStorage.setItem('filterCatOrders', JSON.stringify(cat));
      }
      sessionStorage.setItem('filterOrders', JSON.stringify(this.filterOrders));
      this.getListOrders('false', this.filterOrders);
    }
  }

  deleteFilter(items, table) {
    if (table === 'rows') {
      this.filter = {search: {}, filters: {}};
      let isSearch = false;
      const categories: Array<any> = this.categoriesFilter.search;
      const categoriesFilters: Array<any> = this.categoriesFilter.filters;
      if (items.length > 0) {
        items.forEach((item) => {
          categories.forEach((cat) => {
            if (cat.label === item.type) {
              isSearch = true;
              if (this.filter.search[cat.name] === undefined) {
                this.filter.search[cat.name] = [];
              }
              this.filter.search[cat.name].push(item.tag);
            }
          });
          if (isSearch === false) {
            categoriesFilters.forEach((filter) => {
              if (filter.options && filter.options !== null) {
                filter.options.forEach((option) => {
                  if (option.label !== item.tag && option.block !== true) {
                    option.active = false;
                  } else {
                    if (!option.block) {
                      option.active = true;
                      option.block = true;
                      if (item.type !== 'Stato riga') {
                        this.filter.filters[filter.name] = option.value;
                      } else {
                        if (this.filter.filters[filter.name] === undefined) {
                          this.filter.filters[filter.name] = [];
                        }
                        this.filter.filters[filter.name].push(option.value);
                      }
                    }
                  }
                });
              } else {
                // console.log('no option', filter.label);
                if (filter.label !== item.type && filter.block !== true) {
                  filter.active = false;
                } else {
                  if (!filter.block) {
                    filter.active = true;
                    filter.block = true;
                    const res = item.tag.split(' ');
                    this.filter.filters[filter.name] = {from: res[1], to: res[3]};
                  }
                }
              }
            });
          }
        });
        categoriesFilters.forEach((filter) => {
          if (filter.options) {
            filter.options.forEach((option) => {
              delete option.block;
            });
          } else {
            delete filter.block;
          }
        });
      } else {
        categoriesFilters.forEach((filter) => {
          if (filter.options) {
            filter.options.forEach((option) => {
              option.active = false;
              delete option.block;
            });
          } else {
            filter.active = false;
            delete filter.block;
          }
        });
      }
      this.Searchwithfilter(table, this.categoriesFilter);
    } else {
      this.filterOrders = {search: {}, filters: {}};
      let isSearch = false;
      const categories: Array<any> = this.categoriesFilterOrders.search;
      const categoriesFilters: Array<any> = this.categoriesFilterOrders.filters;
      if (items.length > 0) {
        items.forEach((item) => {
          categories.forEach((cat) => {
            if (cat.label === item.type) {
              isSearch = true;
              if (this.filterOrders.search[cat.name] === undefined) {
                this.filterOrders.search[cat.name] = [];
              }
              this.filterOrders.search[cat.name].push(item.tag);
            }
          });
          if (isSearch === false) {
            categoriesFilters.forEach((filter) => {
              if (filter.options && filter.options !== null) {
                filter.options.forEach((option) => {
                  if (option.label !== item.tag && option.block !== true) {
                    option.active = false;
                  } else {
                    if (!option.block) {
                      option.active = true;
                      option.block = true;
                      if (item.type !== 'Stato riga') {
                        this.filterOrders.filters[filter.name] = option.value;
                      } else {
                        if (this.filterOrders.filters[filter.name] === undefined) {
                          this.filterOrders.filters[filter.name] = [];
                        }
                        this.filterOrders.filters[filter.name].push(option.value);
                      }
                    }
                  }
                });
              } else {
                // console.log('no option', filter.label);
                if (filter.label !== item.type && filter.block !== true) {
                  filter.active = false;
                } else {
                  if (!filter.block) {
                    filter.active = true;
                    filter.block = true;
                    const res = item.tag.split(' ');
                    this.filterOrders.filters[filter.name] = {from: res[1], to: res[3]};
                  }
                }
              }
            });
          }
        });
        categoriesFilters.forEach((filter) => {
          if (filter.options) {
            filter.options.forEach((option) => {
              delete option.block;
            });
          } else {
            delete filter.block;
          }
        });
      } else {
        categoriesFilters.forEach((filter) => {
          if (filter.options) {
            filter.options.forEach((option) => {
              option.active = false;
              delete option.block;
            });
          } else {
            filter.active = false;
            delete filter.block;
          }
        });
      }
      this.Searchwithfilter(table, this.categoriesFilterOrders);
    }
  }

  viewRowsByOrder(code) {
   this.filter = {search: {}, filters: {}};
   this.filter.search['order.code'] = [];
   this.filter.search['order.code'].push(code);
   this.getListRows('false', this.filter, true);
   this.visualizza = 'Righe';
   this.icontext = 'list';
   this.selected = [];
   this.selectOrders = false;
   const type = {type: 'Order Code', tag: code};
   this.chips = [];
   this.chips.push(type);
  }

  pagination(page, type, idRows?, idOrders?) {
    console.log(page);
    if (type === 'rows') {
      this.filter['pagination'] = page;
      console.log(this.filter);
      this.getListRows('false', this.filter);
    } else if (type === 'orders') {
      this.filterOrders['pagination'] = page;
      console.log(this.filter);
      this.getListOrders('false', this.filterOrders);
    } else {
      const paginationlog: any = {};
      page['pageSize'] = 10;
      paginationlog['pagination'] = page;
      console.log(paginationlog);
      this.getReverseOrdersRows(idOrders, idRows, paginationlog);
    }
  }

  translateType(type) {
    if (type === 'status') {
      return this.translate.get('Orders.filter.status');
    } else if (type === 'supplier') {
      return this.translate.get('Orders.filter.supplier');
    } else if (type === 'order_code') {
      return this.translate.get('Orders.filter.order_code');
    } else if (type === 'allFields') {
      return this.translate.get('Orders.filter.allFields');
    }
  }

  translatebutton() {
    this.translate.get('Orders.List.Button.new').subscribe((x: string) => this.new = x);
    this.translate.get('Orders.List.Button.import').subscribe((x: string) => this.importa = x);
  }

  private lockFilters() {
    this.filtersLocked = !this.filtersLocked;
    sessionStorage.setItem('filtersLocked', this.filtersLocked.toString());
  }

  showStoricoRow(idorder, idrow?) {
    console.log(idorder);
    console.log(idrow);
    if (idrow) {
      const paginationlog: any = {pagination: {pageSize: 10}};
      this.getReverseOrdersRows(idorder, idrow, paginationlog, true);
    } else {
      console.log('ordini');
    }
  }

  getReverseOrdersRows(idorder, idrow, pagination?, open = false) {
    this.ordiniservice.getReverseOrdersRows(idorder, idrow, pagination).subscribe((res: any) => {
      this.reverseOrdersRow = res.data;
      this.totalPageLog = res.pagination.totalPages;
      if (open) {
        $('#StoricoModal').modal('open');
      }
    });
  }

  showStoricoOrder(idorder) {
    console.log(idorder);
    if (idorder) {
      this.ordiniservice.getReverseOrders(idorder).subscribe((res) => {
        this.reverseOrder = res.data;
        $('#StoricoOrderModal').modal('open');
      });
    } else {
      console.log('ordini');
    }
  }

  ShowAllegati(type, idrow?) {
    if (type === 'allegati') {
      // api per allegati
    } else {
      // api per revisioni
    }
    $('#AllegatiModal').modal('open');
  }

  ShowModalOrder(order) {
    this.CodeOrderDetail = order.code;
    console.log(order);
    this.DetailOrder = order;
    $('select').formSelect(); // da fare nella direttiva
    $('#DetailOrderModal').modal('open');
  }

  showNoteModal(row) {
    this.updateRow.orderid = row.ordersId;
    this.updateRow.rowId = row.id;
    this.note = row.notes;
    this.statusRowNoteModal = row.status;
    $('#NoteModal').modal({
      dismissible: false,
    }
    );
    $('#NoteModal').modal('open');
    $('#note').focus();
  }
  ShowModalUpload(name) {
    this.idAllegato = name;
    $('#UploadModal').modal('open');
  }

  ShowModalAllegatoStorico(filerev) {
    this.storicorev = filerev.revisioni;
    $('#StoricoAllegati').modal('open');
  }

  Showdescription() {
    setTimeout(() => { M.textareaAutoResize($('#textareaDescription')); }, 10);
  }

  onFilesAdded(files, type) {
    console.log('Qui', files);
    if (type === 'add') {
      files.addedFiles.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.addedFiles.forEach(file => {
        this.file.forEach( f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }

    console.log(this.file);
  }

  SaveFile() {
   // chiamata api per Salvare
    this.filename = [];
    console.log(this.checkRevisione);
  }

  discardFile(i) {
    this.file.splice(i, 1);
    // bug materialize quando viene aperto un tooltip
    // e succesivamente scompare l'icona da cui è stato aperto, esso non scompare quindi bisogna chiuderlo da codice
    $('.tooltiped').tooltip('close');
    console.log(this.file);
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }
  CheckRevisione() {
    this.checkRevisione = !this.checkRevisione;
  }

  orderByHeader(element) {
    // Todo
    if (element === this.orderby) {
      this.orderby = element + '_less';
    } else {
      this.orderby = element;
    }
  }
}

