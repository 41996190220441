import {NgModule} from '@angular/core';
import {ListaComponent} from '@app/dashboard/gare/lista/lista.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BtnAddModule} from '@app/shared/btnAdd/btnAdd.module';
import {DataTablesModule} from 'angular-datatables';
import {DropdownModule} from '@app/shared/dropdown/dropdown.module';
import {DirectiveModule} from '@app/shared/directive/directive.module';
import {RouterModule} from '@angular/router';
import {MzFeatureDiscoveryModule, MzValidationModule} from 'ngx-materialize';
import {AutocompleteModule} from '@app/shared/autocomplete/autocomplete.module';
import {DashboardModule} from '@app/dashboard/dashboard.module';
import {AngularResizedEventModule} from 'angular-resize-event';
import {MultifilterModule} from '@app/shared/multifilter/multifilter.module';
import {BreadCrumbsModule} from '@app/shared/breadCrumbs/breadCrumbs.module';
import {DetailsNavModule} from '@app/shared/detailsNavigation/detailsNav.module';
import {FormComponent} from '@app/dashboard/gare/form/form.component';
import {CustomPipeModule} from '@app/shared/customPipes/customPipe.module';
import {PipeModule} from '@app/shared/pipe/pipe.module';
import {PaginationModule} from '@app/shared/pagination/pagination.module';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {StatusModule} from '@app/shared/status/status.module';

@NgModule({
  declarations: [ListaComponent, FormComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BtnAddModule,
    DataTablesModule,
    DropdownModule,
    DirectiveModule,
    RouterModule,
    MzFeatureDiscoveryModule,
    MzValidationModule,
    AutocompleteModule,
    DashboardModule,
    AngularResizedEventModule,
    MultifilterModule,
    BreadCrumbsModule,
    DetailsNavModule,
    CustomPipeModule,
    PipeModule,
    PaginationModule,
    NgxDropzoneModule,
    StatusModule
  ]
})
export class GareModule {}
