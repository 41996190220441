import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormComponent} from "@app/dashboard/utenti/form/form.component";
import {ListaComponent} from "@app/dashboard/utenti/lista/lista.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ChecklistModule} from "angular-checklist";
import {RouterModule} from "@angular/router";
import {BtnAddModule} from "@app/shared/btnAdd/btnAdd.module";
import {DirectiveModule} from "@app/shared/directive/directive.module";
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule} from "@ngx-translate/core";
import {AutocompleteModule} from "@app/shared/autocomplete/autocomplete.module";
import {PipeModule} from "@app/shared/pipe/pipe.module";
import {DashboardModule} from "@app/dashboard/dashboard.module";
import {AngularResizedEventModule} from "angular-resize-event";
import {DataTablesModule} from "angular-datatables";
import {MzDropdownModule} from "ngx-materialize";
import {NgxDropzoneModule} from "ngx-dropzone";
import {BreadCrumbsModule} from "@app/shared/breadCrumbs/breadCrumbs.module";
import {MultifilterModule} from "@app/shared/multifilter/multifilter.module";
import {DetailsNavModule} from "@app/shared/detailsNavigation/detailsNav.module";
import {PaginationModule} from "@app/shared/pagination/pagination.module";
import {ReportComponent} from "@app/dashboard/report/report/report.component";
import {ChartsModule} from "ng2-charts";
import {ReportTableModule} from "@app/shared/report-table/report-table.module";
import {BarchartModule} from "@app/shared/barchart/barchart.module";
import {DoughnutchartModule} from "@app/shared/doughnutchart/doughnutchart.module";


@NgModule({
  declarations: [ReportComponent ],
  imports: [
    FormsModule,
    ChecklistModule,
    ChartsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    BtnAddModule,
    ReactiveFormsModule,
    DirectiveModule,
    HttpClientModule,
    TranslateModule,
    AutocompleteModule,
    PipeModule,
    DashboardModule,
    AngularResizedEventModule,
    DataTablesModule,
    MzDropdownModule,
    NgxDropzoneModule,
    BreadCrumbsModule,
    MultifilterModule,
    DetailsNavModule,
    PaginationModule,
    ReportTableModule,
    BarchartModule,
    DoughnutchartModule
  ]
})
export class ReportModule { }
