import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UtilityService} from '@app/shared/utility.service';
import {AuthenticationService} from '@app/auth/login/login.service';
import { map } from 'rxjs/operators';



@Injectable()
export class LoggedGuard implements CanActivate {

  policies: any;
  user: any;
  section: any;

  constructor( private router: Router,
               private utilityService: UtilityService,
               private authenticationService: AuthenticationService ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.section = route.data.role;
    this.user =  this.utilityService.user;

    console.log('user', this.user);

    if (!this.user) {
      console.log('ciaoooooooo');
      return this.authenticationService.GetUserLoggato().pipe(map( res => {
        this.user = res;
        if (this.user.group === 'supplier') {
          // TODO
          this.user.permissions = ['order:read', 'order:detail', 'competitions:read', 'competitions:detail', 'deliveries:read', 'rdps', 'rdps:read', 'suppliers:update'];
          this.utilityService.user = this.user;
        }
        this.utilityService.user = this.user;

        return this.check();

      }));

    } else {
      if (this.user.isDefaultPass === true) {
        this.router.navigate(['/login/set-password']);
      } else {
        return this.check();
      }
    }

  }



  check() {

    console.log(this.section);

    if (this.user && this.section[0] === 'public') {
      return true;
    } else {
      let trovato = false;
      let trovato2 = false;

      this.user.permissions.forEach(element => {
      if (element === this.section[0]) {
        console.log(element);
        trovato = true;
      }
    });

      if (this.section.length === 2) {
        console.log('quiii');
    this.user.permissions.forEach(element => {
      if (element === this.section[1]) {
        trovato2 = true;
      }
    });
    }

      if (this.user.permissions[0] === '*.*' || this.user.permissions[0] === '*:*') {
        trovato = true;
      }


      console.log("trovato: ", trovato, "trovato2: ", trovato2);

      return trovato || trovato2;
    }
  }
}
