import {Injectable} from "@angular/core";
import {BaseService} from "@app/base.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ServerBaseResponse} from "@app/shared/models/server-base-response";
import {OrdiniListaModel} from "@app/shared/models/db_tables/ordini-lista.model";
import {environment} from "../../../environments/environment";
import {RowsModel} from '@app/shared/models/db_tables/.gare.model';

declare const moment: any;

@Injectable({
  providedIn: 'root'
})
export class RdpService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super();
  }

  GetListRdp(loader = 'false', filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps', filters , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetRdpByid(loader = 'false', id, filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + id, filters, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  CreateRdp(loader = 'false', Rdp): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/new', Rdp , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  DeleteRdp(loader = 'false', id): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .delete <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + id , {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  DeleteRdpRow(loader = 'false', rdpId, rowId): Observable<ServerBaseResponse<any>> {
   return this.httpClient
    .delete <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + rdpId + '/rows/' + rowId, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  GetHistoryRdp(loader = 'false', id, filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + id + '/history', filters, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  UpdateRow(row, rdpId, rowId): Observable<ServerBaseResponse<RowsModel>> {
    return this.httpClient
      .patch <ServerBaseResponse<RowsModel>>( environment.apiUrl + '/rdps/' + rdpId + '/rows/' + rowId,
        row , { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': 'true'})});
  }

  UpdateRdp(rdp, rdpId): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + rdpId, rdp, { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': 'false'})});
  }
  changeStatus(rdpId, status, rdp = {}): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + rdpId + '/set-status/' + status, rdp ,{ withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': 'false'})});
  }
  convertRdpToOrder(order, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/rdps-to-order', order, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  convertRowsToOrder(order, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/rows-to-order', order, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  MassDeleteRdps(rdpsId, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/bulk-delete', rdpsId, {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
  downloadPDF(rdpsId, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .get <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/' + rdpsId + '/pdf',  {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }

}
