import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {RdaService} from "@app/dashboard/rda/rda.service";
import Swal from "sweetalert2";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {UtilityService} from "@app/shared/utility.service";
import {ListinoService} from "@app/dashboard/listino/listino.service";
import {FornitoriService} from "@app/dashboard/fornitori/fornitori.service";
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormBuilder, FormGroup} from "@angular/forms";
import * as moment from 'moment';
import {forkJoin} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {SettingsService} from "@app/dashboard/settings/settings.service";
import {FornitoriListaModel} from "@app/shared/models/db_tables/fornitori-lista.model";
import {el} from "@angular/platform-browser/testing/src/browser_util";
import {forEach} from "@angular/router/src/utils/collection";

declare const $: any;
declare const  M: any; // usata per i toast

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @ViewChild('emit_date') emit_date: ElementRef;
  @ViewChild('deliverydate') deliverydate: ElementRef;
  @ViewChild('endate') endate: ElementRef;
  @ViewChild('startdate') startdate: ElementRef;
  refresh = true;
  idNuovo = 0;
  addRows = {
    id: 0,
    productsId: null,
    notes: '',
    qty: 0,
    budget: 0,
    preferredSuppliers: [],
    requestedDeliveryDate: '',
  };
  modificata = false;
  formRda: FormGroup = null;
  selected = [];
  selectedSupp = [];
  checkrow = false;
  supplierSelected = [];
  select = false;
  nuovo = false;
  rows = [];
  Rdaid = null;
  dettaglio = false;
  modifica = false;
  disable = false;
  posBreadcrumbs: any;

  prodotti = [];
  listaProdotti: any;
  suplliers: any;
  listRowsRda = [];
  rda: any;
  DMFornitori = [];
  idRwoSupp;

  user: any;

  addresses: any;

  // allegati
  file = [];
  filename = [];
  idAllegato;
  commentoRev;

  listardainvariata = [];
  rdapatch = [];
  chip = [];


  currencies = [];
  convertForm: FormGroup = null;
  competitionForm: FormGroup = null;
  terminiresa;
  terminipagamento;
  suppliersAutoComplete = [];
  suppPref = null;
  curr = null;
  convertType = null;


  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private rdaService: RdaService,
              private utilityService: UtilityService,
              private prodottiSevice: ListinoService,
              private translate: TranslateService,
              private fornitoriService: FornitoriService,
              private settingsService: SettingsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.user = this.utilityService.user;
    this.activatedRoute
      .paramMap
      .subscribe((paramsMap: ParamMap) => {
        // se l'url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
        this.Rdaid = +paramsMap.get('id');
        if (this.Rdaid) {
          this.disable = true;
          this.dettaglio = true;
          this.initForm();
          this.getDetailRda('start');
        } else {
          this.initForm();
          this.utilityService.Navigation.push({title: 'Nuovo Rda', path: '/rdas/' + this.Rdaid});
        }
      });
    this.getListProduct();
    this.getListSupplier();
    this.getListAddress();

  }

  initForm() {
    if (!this.dettaglio) {
      this.formRda = this.formBuilder.group({
        emit_date: this.utilityService.standardFormControl(true, false),
        addressesId: this.utilityService.standardFormControl(true, false),
        notes: this.utilityService.standardFormControl(true, false)
      });
    } else {
      this.formRda = this.formBuilder.group({
        emit_date: this.utilityService.standardFormControl(true, true),
        addressesId: this.utilityService.standardFormControl(true, true),
        notes: this.utilityService.standardFormControl(true, true),
        Rows: this.utilityService.standardFormControl(true, true)
      });
    }
    this.convertForm = this.formBuilder.group({
      currency: this.utilityService.standardFormControl(false, false),
      delivery_term: this.utilityService.standardFormControl(false, false),
      payment_term: this.utilityService.standardFormControl(false, false),
      suppliersId: this.utilityService.standardFormControl(false, false),
    });
    this.competitionForm = this.formBuilder.group({
      endDate: this.utilityService.standardFormControl(false, false),
      startDate: this.utilityService.standardFormControl(false, false),
      suppliersId: this.utilityService.standardFormControl(false, false),
    });
  }

  formControlName(abstractControl: AbstractControl, modificata) {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  getDetailRda(command = '') {
    this.rdaService.GetRdaByid(this.Rdaid).subscribe((res) => {
      // TODO al posto di rda-code ci andrebbe il codice dell rda che riceviamo da questa chiamata ma ancora non c'e il campo
      this.rda = res.data;
      this.utilityService.Navigation.push({title: this.rda.code, path: '/rdas/' + this.Rdaid});
      this.rows = res.data.Rows;
      this.rda.emit_date = this.rda.emit_date.slice(0, 10);
      this.formRda.patchValue(this.rda);
      this.formRda.disable();
      console.log(moment(this.rda.emit_date, 'YYYY-MM-DD'));
      this.rows.forEach((r) => {
        console.log('frefasfregafsdegrdf', r);
        this.DMFornitori[r.id] = {supplier: []};
        this.DMFornitori[r.id].supplier = this.formatChip(r.preferredSuppliers);
      });
      console.log(this.DMFornitori);
      // TODO sistemare e togliere set timeout perchè codice sporco
      setTimeout(() => {
        this.selectActivation();
      }, 400);
      if (command === 'start') {
        this.listardainvariata = JSON.parse(JSON.stringify(this.rows));
      }
    });
  }

  formatChip(supp) {
    console.log(supp);
    const prefered = [];
    supp.forEach((s) => {
      prefered.push({type: 'rda', tag: s.r_sociale, value: s.id});
    });
    return prefered;
  }

  deleteChip(chip, row) {
    if (row !== 'convert') {
      row.preferredSuppliers = [];
      chip.forEach((c) => {
        row.preferredSuppliers.push({r_sociale: c.tag, id: c.value});
      });
      this.rows.find(r => r.id === this.idRwoSupp).preferredSuppliers = row.preferredSuppliers;
    } else {
      const supp = [];
      console.log(chip);
      chip.forEach((c) => {
        supp.push(c.value);
      });
      setTimeout(() => {
        this.competitionForm.controls.suppliersId.setValue(supp);
        this.suppPref = chip;
        console.log(this.suppPref);
        console.log(this.competitionForm.controls.suppliersId.value);
      }, 0);
    }
  }

  modificaRows() {
    console.log(this.DMFornitori);
    this.disable = false;
    this.modifica = true;
    this.formRda.enable();
  }

  getListProduct() {
    this.prodottiSevice.GetListProducts(this.translate.currentLang).subscribe((res) => {
      this.listaProdotti = res.data;
      for (const prodotti of this.listaProdotti) {
        this.prodotti.push({label: prodotti.description, value: prodotti.id, code: prodotti.internal_sku});

      }
    });
  }

  getListSupplier() {
    this.fornitoriService.getListaTable('false', this.translate.currentLang).subscribe((res) => {
      this.suplliers = res.data;
    });
  }

  getListAddress() {
    this.rdaService.GetCurrentCompanies().subscribe((res: any) => {
      console.log('res: ', res);
      this.addresses = res.data.Addresses;
      console.log('addresses: ', this.addresses);
    });
  }

  selectActivation() {
    $('select').formSelect();
  }

  Prodotto(event, action, mod) {
    // event.value = id, event.name = sku
    if (action === 'add') {
      this.addRows.productsId = event.value;
    } else {
      mod = event.value;
    }
  }

  showModalFornitori(idRows, i) {
    this.idRwoSupp = idRows;
    $('#fornitoriModal').modal('open');
  }

  patchRows(id, label, value) {
    // Modifica di una riga chiamata dal tablerowcomponent
    const patch = {};
    patch[label] = value;
    // chiamata patch rows
    if (this.dettaglio) {
      if (this.rdapatch.length > 0) {
        this.rdapatch.forEach((ID) => {
          if (ID.id !== id) {
            this.rdapatch.push({id: id, action: 'update'});
          }
        });
      } else {
        this.rdapatch.push({id: id, action: 'update'});
      }
      this.rdaService.UpdateRdaRow(this.Rdaid, id, patch).subscribe((res: any) => {
        this.DMFornitori[res.data.id] = {supplier: []};
        this.DMFornitori[res.data.id].supplier = this.formatChip(res.data.preferredSuppliers);
        console.log(this.DMFornitori);
      });
    }

  }

  DataPicker(command = 'row') {
    if (command === 'row') {
      this.addRows.requestedDeliveryDate = this.deliverydate.nativeElement.value;
    } else if (command === 'Form') {
      this.formRda.controls.emit_date.setValue(this.emit_date.nativeElement.value);
      this.formRda.controls.emit_date.markAsDirty();
    } else if (command === 'end') {
      this.competitionForm.controls.endDate.setValue(this.endate.nativeElement.value);
      this.competitionForm.controls.endDate.markAsDirty();
    } else if (command === 'start') {
      this.competitionForm.controls.startDate.setValue(this.startdate.nativeElement.value);
      this.competitionForm.controls.startDate.markAsDirty();
    }
  }

  deleteRows(id) {
    // cancella una riga d'ordine

    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questa voce del listino',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'La voce del listino è stata eliminata con successo',
          'success'
        );
        this.rdapatch.push({id: id, action: 'delete'});
        this.rdaService.DeleteRdaRow(this.Rdaid, id).subscribe(() => {
          this.getDetailRda();
        });
      }
    });
  }

  blurInserimento() {
    if (
      this.addRows.productsId === '' &&
      this.addRows.budget === null &&
      this.addRows.notes === '' &&
      this.addRows.qty === null &&
      this.addRows.requestedDeliveryDate === '') {
      this.nuovo = false;
    }
  }

  addRow() {
    if (this.addRows.productsId !== '' &&
      this.addRows.budget !== 0 &&
      this.addRows.notes !== '' &&
      this.addRows.qty !== 0 &&
      this.addRows.requestedDeliveryDate !== '') {
      if (this.dettaglio) {
        this.rdaService.CreateRdaRow(this.Rdaid, this.addRows).subscribe((res) => {
          this.rdapatch.push({id: res.data.id, action: 'add'});
          this.disable = true;
          this.modifica = false;
          this.getDetailRda();
        });
      } else {
        this.addRows.id = this.idNuovo;
        this.rows.push(this.addRows);
      }
      this.nuovo = false;
      setTimeout(() => {
        $('select').formSelect();
        $('.tooltipped').tooltip();
      }, 1);
      this.addRows = {
        id: 0,
        productsId: null,
        notes: '',
        qty: 0,
        budget: 0,
        preferredSuppliers: [],
        requestedDeliveryDate: '',
      };
    } else {
      console.log(this.addRows);
      M.toast({html: 'Inserisci tutti i campi'});
    }
  }

  ViewAddRows() {
    this.idNuovo = this.idNuovo + 1;
    this.nuovo = true;
    this.DMFornitori[this.idNuovo] = {supplier: []};
  }

  parseValue(id) {
    return parseInt(id, 10);
  }

  UpdateRda() {
    // TODO api per salvare modifiche
    this.formRda.controls.Rows.setValue(this.rows);
    this.rdaService.UpdateRda(this.Rdaid, this.formRda.value).subscribe(() => {
      this.formRda.disable();
      this.router.navigate(['/dashboard/rdas/' + this.Rdaid]);
    });
  }

  saveRda() {
    this.modificata = true;
    if (this.rows.length > 0) {
      const rows = this.formRda.value;
      this.rows.forEach((r) => {
        delete r.id;
      });
      rows['Rows'] = this.rows;
      this.rdaService.CreateRda(rows).pipe(mergeMap((res) => this.rdaService.UpdateRda(res.data.id, {status: 'pending'})))
        .subscribe( (res) => {
          console.log(res);
        this.modificata = false;
        this.router.navigate(['/dashboard/rdas/' + res.data.id]);
      });
    } else {
      M.toast({html: 'inserire delle righe prima di procedere alla creazione di un rda'});
    }
  }

  SendRda() {
    // TODO Adesso è in mock poi sarà un API
    this.rdaService.UpdateRda(this.Rdaid, {status: 'pending'}).subscribe(() => {
      this.getDetailRda();
    });
  }

  AcceotedRda() {
    // TODO Adesso è in mock poi sarà un API
    this.rdaService.UpdateRda(this.Rdaid, {status: 'approved'}).subscribe(() => {
      this.getDetailRda();
    });
  }

  denyRDA() {
    // TODO Adesso è in mock poi sarà un API
    this.rdaService.UpdateRda(this.Rdaid, {status: 'denied'}).subscribe(() => {
      this.getDetailRda();
    });
  }


  annulla() {
    this.addRows = {
      id: 0,
      productsId: null,
      notes: '',
      qty: 0,
      budget: 0,
      preferredSuppliers: [],
      requestedDeliveryDate: '',
    };
    this.getDetailRda();
    this.modifica = false;
    this.disable = true;

    if (this.rdapatch.length > 0) {
      this.listardainvariata.forEach((banche) => {
        this.rdapatch.forEach((id) => {
          if (banche.id === id.id && id.action === 'update') {
            this.rdaService.UpdateRdaRow(this.Rdaid, id.id, banche).subscribe();
            this.getDetailRda();
            this.modifica = false;
            this.disable = true;
          }
          if (banche.id === id.id && id.action === 'delete') {
            this.rdaService.CreateRdaRow(this.Rdaid, banche).subscribe();
            this.getDetailRda();
            this.modifica = false;
            this.disable = true;
          }
          if (id.action === 'add') {
            this.rdaService.DeleteRdaRow(this.Rdaid, id.id).subscribe();
            this.getDetailRda();
            this.modifica = false;
            this.disable = true;
          }
        });
      });
      this.rdapatch = [];
    }
    this.formRda.disable();
  }

  // Allegati

  ShowAllegati(type, idrow?) {
    $('#AllegatiModal').modal('open');
  }


  ShowModalUpload(name) {
    this.idAllegato = name;
    $('#UploadModal').modal('open');
  }

  onFilesAdded(files, type) {
    if (type === 'add') {
      files.addedFiles.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.addedFiles.forEach(file => {
        this.file.forEach(f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }
  }

  onRemove(event) {
    this.file.splice(this.file.indexOf(event), 1);
  }

  changeAllCheckBox(type) {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      if (type === 'supp') {
        this.selectedSupp = [];
      } else {
        this.selected = [];
      }
      this.suplliers.forEach((row) => {
        if (type === 'supp') {
          this.selectedSupp.push(row.id);
        } else {
          this.selected.push(row.id);
        }
      });
      $('#tablefornitori').find('tbody').find('tr').addClass('selected-table');
      this.select = true;
    } else {
      if (type === 'supp') {
        this.selectedSupp = [];
      } else {
        this.selected = [];
      }
      $('tbody').find('tr').removeClass('selected-table');
      this.select = false;
    }
  }

  checkBoxChange(input, idrow, type) {
    // funzione per cambiare background alla riga selezionata
    if (input.target.checked) {
      if (type === 'supp') {
        this.selectedSupp.push(idrow);
      } else {
        this.selected.push(idrow);
      }
      $(input.path[3]).addClass('selected-table');
      this.select = true;
    } else {
      const index = this.selected.indexOf(idrow);
      if (type === 'supp') {
        this.selectedSupp.splice(index, 1);
      } else {
        this.selected.splice(index, 1);
      }
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
        this.select = false;
      }
    }
  }

  GetFornitori() {
    // this.hiddenFilter = false;
    // TODo fare due array diversiuna per chip uno per salvattagio
    let temp;
    let row: any;
    this.selectedSupp.forEach((f: any) => {
      temp = this.suplliers.filter(supp => supp.id === f)[0];
      if (this.idRwoSupp !== 'Salva' && this.idRwoSupp !== 'convert') { // idrowSupp è salva quando si crea una nuova riga
        row = this.rows.find(r => r.id === this.idRwoSupp);
        if (!row.preferredSuppliers.find(s => s.id === temp.id)) {
          row.preferredSuppliers.push({r_sociale: temp.r_sociale, id: temp.id});
        }
      } else if (this.idRwoSupp === 'convert') {
        // TODO
        const supplier = this.competitionForm.controls.suppliersId.value;
        console.log('prima if', temp.id);
        /*setTimeout(() => {
          if (!supplier.find(s => s === temp.id)) {
            supplier.push(temp.id);
            this.suppPref.push({type: 'rda', tag: temp.r_sociale, value: temp.id});
          }
          this.competitionForm.controls.suppliersId.setValue(supplier);
          this.refresh = !this.refresh;
          console.log(this.suppPref);
          }, 0);*/
        console.log(supplier);
        if (!supplier.find(s => s === temp.id)) {
          console.log('dentro');
          supplier.push(temp.id);
          this.suppPref.push({type: 'rda', tag: temp.r_sociale, value: temp.id});
        }
        this.competitionForm.controls.suppliersId.setValue(supplier);
        this.refresh = !this.refresh;
        console.log(this.suppPref);
        console.log(this.competitionForm.controls.suppliersId.value);



      } else {
        if (this.addRows.preferredSuppliers.length > 0) {
          if (!this.addRows.preferredSuppliers.find(s => s.id === temp.id)) {
            this.addRows.preferredSuppliers.push({r_sociale: temp.r_sociale, id: temp.id});
          }
        } else {
          this.addRows.preferredSuppliers.push({r_sociale: temp.r_sociale, id: temp.id});
        }
      }
    });
    if (this.idRwoSupp !== 'Salva' && this.idRwoSupp !== 'convert') {
      this.rows.find(r => r.id === this.idRwoSupp).preferredSuppliers = row.preferredSuppliers;
    } else if (this.idRwoSupp !== 'convert') {
      this.DMFornitori[this.idNuovo].supplier = this.formatChip(this.addRows.preferredSuppliers);
    }
  }

  // NGCLASS

  StatusSelected(stato, label) {
    if (stato === label) {
      return 'selected';
    }
  }

  disableIconAllegati() {
    if (this.disable) {
      return 'disable';
    }
  }

  activateLabel() {
    if (this.dettaglio) {
      return 'active';
    }
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  showModalConvert(type) {

    this.convertType = type;

    /*const supp = this.suppliers.find(supp => supp.id === this.rdp.suppliersId);
    console.log(supp);
    this.convertForm.patchValue(supp);
    this.convertForm.controls.currency.setValue(supp.price_list.currency);*/
    if (this.selected.length === 1 && this.DMFornitori[this.selected[0]].supplier.length === 1) {
      const supp = this.suplliers.find((sup: any) => sup.id === this.DMFornitori[this.selected[0]].supplier[0].value);
      if (type === 'order') {
        this.convertForm.patchValue(supp);
        this.convertForm.controls.currency.setValue(supp.price_list.currency);
        this.curr = supp.price_list.currency;
      }
      this.convertForm.controls.suppliersId.setValue(supp.id);
      this.suppPref = supp.id;
    }
    console.log(this.DMFornitori);
    this.suppliersAutoComplete = this.suplliers.map((s) => {
      return {value: s.id, label: s.r_sociale};
    });

    if (type === 'order') {
      forkJoin(
        this.fornitoriService.GetDDlterminiConsegna(),
        this.fornitoriService.GetDDlTerminiPagamento(),
        this.settingsService.getCurrentCompany().pipe(mergeMap((res: any) => this.settingsService.getCurrencyList(res.data.id))))
        .subscribe((result: any) => {

          this.terminiresa = result[0].data;

          this.terminipagamento = result[1].data;

          this.currencies = result[2].data.map((r) => {
            return {value: r.id, label: r.label};
          });
          $('#ConvertModal').modal('open');
          this.selectActivation();
        });
    } else {
      console.log('ciao');
      $('#ConvertModal').modal('open');
    }
  }


  convert() {
    const convert = {};
    if (this.select) {
      convert['ids'] = this.selected;
      if (this.convertType === 'order') {
        convert['data'] = this.convertForm.value;
        this.rdaService.convertToOrder(convert, 'true').subscribe((res) => {
          console.log(res.data.id);
          this.router.navigate(['/dashboard/orders/' + res.data[0].id]);
        });
      } else {
        convert['data'] = {suppliersId: this.convertForm.controls.suppliersId.value};
        this.rdaService.convertToRdp(convert, 'true').subscribe((res) => {
          console.log(res.data.id);
          this.router.navigate(['/dashboard/orders/' + res.data[0].id]);
        });
      }
    }
    this.reset();
    console.log(convert);
  }

  reset() {
    this.suppPref = null;
    this.curr = null;
    this.convertForm.reset();
    this.competitionForm.reset();
    console.log('reset', this.convertForm.value);
    this.selectActivation();
  }


  Termini(event, type) {
    // funzione per prendere il valore della currency dall'autocomplete
    if (type === 'supplier') {
      if (type.includes('competition')) {

      } else {
        this.convertForm.controls.suppliersId.setValue(event.value);
        this.convertForm.controls.suppliersId.markAsDirty();
      }
    }
    if (type === 'currency') {
      this.convertForm.controls.currency.setValue(event.value);
      this.convertForm.controls.currency.markAsDirty();
    }

  }


  showModalCompetition() {
    this.suppPref = [];
    let supplier = [];
    console.log(JSON.stringify(this.selected));
    console.log(this.selected);
    this.selected.forEach((ids) => {
      console.log(this.rows);
      this.rows.forEach(row => {
        if (row.id === ids ) {
          row.preferredSuppliers.forEach((supp) => {
            if (!this.suppPref.find(p => p.value === supp.id)) {
              this.suppPref.push({type: 'rda', tag: supp.r_sociale, value: supp.id});
              supplier.push(supp.id);
            }
          });
        }
      });
    });
    this.competitionForm.controls.suppliersId.setValue(supplier);
    $('#CompetitionModal').modal('open');
    console.log( this.suppPref);
    console.log(JSON.stringify(this.selected));
  }

  convertCompetition() {
    const convert = {};
    if (this.select) {
      convert['ids'] = this.selected;
      convert['data'] = this.competitionForm.value;
      this.rdaService.convertToCompetition(convert, 'true').subscribe((res) => {
        console.log(res.data.id);
        this.reset();
        this.router.navigate(['/dashboard/competitions/' + res.data[0].id]);
      });
    }
    console.log(convert);
  }
}
