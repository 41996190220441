import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard/dashboard.component';

import { ListaComponent as ListaOrdini } from './dashboard/ordini/lista/lista.component';
import { FormComponent as FormOrdini } from './dashboard/ordini/form/form.component';
import { DettaglioComponent as DettaglioOrdini } from './dashboard/ordini/dettaglio/dettaglio.component';

import { ListaComponent as ListaFornitori } from './dashboard/fornitori/lista/lista.component';
import { FormComponent as FormFornitori } from './dashboard/fornitori/form/form.component';

import { ListaComponent as ListaListino } from './dashboard/listino/lista/lista.component';
import { FormComponent as FormListino } from './dashboard/listino/form/form.component';

import { ListaComponent as ListaStatistiche } from './dashboard/statistiche/lista/lista.component';
import { LoginComponent } from './auth/login/login.component';
import {PublicComponent} from '@app/public/public.component';

import {ImportcsvComponent} from '@app/dashboard/importcsv/importcsv.component';
import {TablerowComponent} from '@app/shared/tableRow/tablerow.component';
import {Reset_passwordComponent} from '@app/auth/reset_password/reset_password.component';
import {CompaniesSettingsComponent} from '@app/dashboard/settings/companies/companies.component';
import {LoggedGuard} from '@app/shared/Guards/Logged.guard';

import { ListaComponent as listaUtenti } from './dashboard/utenti/lista/lista.component';
import { FormComponent as FormUtenti } from './dashboard/utenti/form/form.component';
import {TrattativaComponent} from '@app/dashboard/ordini/trattativa/trattativa.component';
import {SetpasswordComponent} from '@app/auth/set_password/setpassword.component';

import { ListaComponent as ListaGare } from './dashboard/gare/lista/lista.component';
import { FormComponent as FormGare } from './dashboard/gare/form/form.component';

import { ListaComponent as ListaRdps } from './dashboard/Rdp/lista/list.component';
import { ListaComponent as ListaRdas } from './dashboard/rda/lista/lista.component';
import { FormComponent as FormRdas } from './dashboard/rda/form/form.component';
import { FormComponent as FormRdp } from './dashboard/Rdp/form/form.component';

import  {ListaComponent as ListaConsegne} from '@app/dashboard/Consegne/Lista/lista.component';
import {FormComponent as FormConsegne} from './dashboard/listino/form/form.component';
import {ReportComponent} from "@app/dashboard/report/report/report.component";

const routes: Routes = [{
    path: 'dashboard', component: DashboardComponent, children: [

      // Ordini
      { path: '', redirectTo: 'orders',  pathMatch: 'full' },
      { path: 'orders', component: ListaOrdini, canActivate: [LoggedGuard], data: { role: ['order:read']}},
      { path: 'orders/new', component: FormOrdini, canActivate: [LoggedGuard], data: { role: ['order:create']}},
      { path: 'orders/:id', component:  FormOrdini, canActivate: [LoggedGuard], data: { role: ['order:detail', 'order:read']}},
      { path: 'orders/:id/:action', component:  FormOrdini, canActivate: [LoggedGuard], data: { role: ['order:detail', 'order:read']}},

      // Consegne
      { path: '', redirectTo: 'orders',  pathMatch: 'full' },
      { path: 'deliveries', component: ListaConsegne, canActivate: [LoggedGuard], data: { role: ['deliveries:read']}},
      { path: 'deliveries/new', component: FormConsegne, canActivate: [LoggedGuard], data: { role: ['deliveries:create']}},
      { path: 'deliveries/:id', component:  FormConsegne, canActivate: [LoggedGuard], data: { role: ['deliveries:detail', 'deliveries:read']}},

      // Fornitori
      { path: 'suppliers', component: ListaFornitori, canActivate: [LoggedGuard], data: { role: ['suppliers:read']}},
      { path: 'suppliers/new', component: FormFornitori, canActivate: [LoggedGuard], data: { role: ['suppliers:create']}},
      { path: 'suppliers/:id', component: FormFornitori, canActivate: [LoggedGuard], data: { role: ['public']}}, // 'suppliers:detail', 'suppliers:read'

      // Listino
      { path: 'list', component: ListaListino, canActivate: [LoggedGuard], data: { role: ['products:read']}},
      { path: 'list/new', component: FormListino, canActivate: [LoggedGuard], data: { role: ['products:create']}},
      { path: 'list/:id', component: FormListino, canActivate: [LoggedGuard], data: { role: ['products:detail', 'products:read']}},

      // Statistiche
      { path: 'statistics', component: ListaStatistiche, canActivate: [LoggedGuard], data: { role: ['statistics']}},

      // import
      { path: 'import', component: ImportcsvComponent, canActivate: [LoggedGuard], data: { role: ['public']}},

      // Gare
      {path: 'competitions', component: ListaGare, canActivate: [LoggedGuard], data: {role: ['competitions:read']}},
      {path: 'competitions/new', component: FormGare, canActivate: [LoggedGuard], data: {role: ['competitions:create']}},
      {path: 'competitions/:id', component: FormGare, canActivate: [LoggedGuard], data: {role: ['competitions:read', 'competitions:detail']}},

      // Rdps
      {path: 'rdps', component: ListaRdps , canActivate: [LoggedGuard], data: {role: ['rdps']}},
      {path: 'rdps/new', component: FormRdp, canActivate: [LoggedGuard], data: {role: ['rdps']}},
      {path: 'rdps/:id', component: FormRdp, canActivate: [LoggedGuard], data: {role: ['rdps']}},
      {path: 'rdps/:id/:action/:from', component: FormRdp, canActivate: [LoggedGuard], data: {role: ['rdps']}},

      // Rdas
      {path: 'rdas', component: ListaRdas , canActivate: [LoggedGuard], data: {role: ['rdas']}},
      {path: 'rdas/new', component: FormRdas , canActivate: [LoggedGuard], data: {role: ['rdas']}},
      {path: 'rdas/:id', component: FormRdas , canActivate: [LoggedGuard], data: {role: ['rdas']}},


      // utenti
      { path: 'users', component: listaUtenti, canActivate: [LoggedGuard], data: { role: ['users:read']}},
      { path: 'users/new', component: FormUtenti, canActivate: [LoggedGuard], data: { role: ['users:create']}},
      { path: 'users/:id', component: FormUtenti, canActivate: [LoggedGuard], data: { role: ['users:detail', 'users:read']}},


      // report
      { path: 'report', component: ReportComponent, canActivate: [LoggedGuard], data: { role: ['report:read']}},


      // settings
      {path: 'settings', children: [
        {path: 'companies', component: CompaniesSettingsComponent, canActivate: [LoggedGuard], data: { role: ['public']}},
        {path: 'profile/:id', component: FormUtenti, canActivate: [LoggedGuard], data: { role: ['public']}}
      ]
    },
    ]
  },
  { path: 'public', component: PublicComponent, children: [
    { path: 'negotation/:token', component: TrattativaComponent, canActivate: [LoggedGuard], data: { role: ['negotiation']}},
    { path: 'negotation/:token/orders/:id', component: FormOrdini, canActivate: [LoggedGuard], data: { role: ['negotiation']}},
    ]
   },
  { path: 'login', component: LoginComponent},
  {path: 'login/resetpassword', component: Reset_passwordComponent},
  {path: 'login/resetpassword/:token', component: Reset_passwordComponent},
  {path: 'login/set-password', component: SetpasswordComponent},
  { path: '**', redirectTo: '/dashboard/orders', pathMatch: 'full' },
];
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
