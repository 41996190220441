import {Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { load } from '@angular/core/src/render3';
import { destroyView } from '@angular/core/src/view/view';

@Component({
  selector: 'DM-pagination',
  templateUrl: './pagination.component.html',
})
export class  PaginationComponent implements OnInit {

  @Input() totalPage = 30;
  @Output() getList = new EventEmitter<any>();
  selectedPage = 1;
  numbers: any;
  disableAvanti = false;

  ngOnInit() {
    this.numbers = Array.from(Array(this.totalPage), (x, i) => i + 1);
    console.log(this.numbers);
  }

  selectPage(numb) {
    this.selectedPage = numb;
    this.getList.emit({pageNumber: this.selectedPage});
  }









  // ngClass

  disableBack() {
    if (this.selectedPage === this.numbers[0]) {
      return 'disable';
    } else {
      return 'waves-effect';
    }
  }
  disableForth() {
    if (this.selectedPage === this.totalPage) {
      this.disableAvanti = true;
      return 'disable';
    } else {
      return 'waves-effect';
    }
  }
  activePage(numb) {
    if (this.selectedPage === numb) {
      return 'active';
    } else {
      return 'waves-effect';
    }
  }
}
