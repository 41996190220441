import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component, ElementRef, OnChanges,
  OnInit, SimpleChanges,
  ViewChild
} from '@angular/core';
import {UtilityService} from '@app/shared/utility.service';
import {FormGroup, FormBuilder, AbstractControl} from '@angular/forms';
import {ListinoService} from '@app/dashboard/listino/listino.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ProdottiListaModel} from '@app/shared/models/db_tables/prodotti-lista.model';
import Swal from 'sweetalert2';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ResizedEvent} from 'angular-resize-event';
import {HeightTabDirective} from '@app/shared/directive/heightTab.directive';
import {NumberPipe} from '@app/shared/customPipes/customPipe.component';

declare const $: any;
declare const  M: any; // usata per i toast

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @ViewChild('chip') chips: ElementRef;
  @ViewChild('select') select: ElementRef;
  @ViewChild(HeightTabDirective) heightDirective: HeightTabDirective;

  constructor(private formBuilder: FormBuilder,
              private utilityservice: UtilityService,
              private listinoService: ListinoService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private utilityService: UtilityService,
              private translate: TranslateService,
              private numberPipe: NumberPipe) {
                this.translate.onLangChange
                .subscribe((event: LangChangeEvent) => {
                  this.translate.get('breadcrumbs.catalog.list').subscribe((app: string) => {
                    utilityService.Navigation[this.basePosBreadcrumbs] = {title: app, path: '/list'};
                  });

                  if (!this.prodottoid) {
                    this.translate.get('breadcrumbs.catalog.new').subscribe((app: string) => {
                      utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/list/new'};
                    });
                  }
                });
              }

  @ViewChild('date') dataemissione: ElementRef;

  prodottoForm: FormGroup = null;

  prodotto: ProdottiListaModel; // form di base del prodotto
  appProdotto: ProdottiListaModel;
  modifica = false;
  dettaglio = false;

  idAllegato;
  file = [];
  filename = [];
  commentoRev;
  checkRevisione = false;

  appFornitori = [];

  // oggetto per inserimento nuovo listino
  listino = {
    suppliersId: null,
    PriceList: {suppliersId: null},
    min_qty: null,
    price: '',
    lead_time: null
  };

  // id del prodotto
  prodottoid = null;

  CatProdotto: any = {};
  // this.CatProdotto[tag.label] = null;

  categories = [];

  modificata = false;
  modListino = false;
  aziende;
  aggiunto = false;

  InsSupplier = false;
  InsPrice = false;
  InsQty = false;
  InsLead = false;

  validSupp = false;
  validPrice = false;
  validQty = false;
  validLead = false;

  listaListino = [];
  nuovo = false;

  Disable = true;

  posBreadcrumbs;
  basePosBreadcrumbs;

  user;
  productsnav = [];
  index;

  listaListinoInvariata = [];
  listinopatch = [];

  ngOnInit() {
    $('.tabs').tabs();
    this.user =  this.utilityService.user;
    this.initForms();
    this.SuppliersList();
    this.SetCatProdotto();
/*
    this.translate.get('breadcrumbs.catalog.list').subscribe((app: string) => {
      this.utilityService.Navigation.push({title: app, path: '/list'});
    });
*/
    this.activatedRoute.paramMap.subscribe(
        (paramsMap: ParamMap) => {
          // se l’url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
          this.prodottoid = +paramsMap.get('id');
          if (this.prodottoid) {
            this.listinoService.GetProductsById(this.prodottoid).subscribe((res: any) => {
              this.prodotto = $.extend(true, {}, res.data);
              this.appProdotto = $.extend(true, {}, this.prodotto);
              this.productsnav = res.stats.resultSetIDs;
              this.index = res.stats.resultSetIDs.indexOf(this.prodottoid) + 1;
              console.log(this.appProdotto);
              this.prodottoForm.patchValue(this.appProdotto);
              this.formatValue();
              this.prodottoForm.disable();


              this.basePosBreadcrumbs = this.utilityService.Navigation.length;
              this.utilityService.Navigation.push({title: res.data.internal_sku, path: '/list/' + res.data.id});

              this.dettaglio = true;
              this.prodotto.categories.forEach((tag: any) => {
                this.categories.push({tag: tag.label});
                console.log(this.prodotto);
              });
              console.log(this.CatProdotto);

              this.getListino('start');
              this.aggiunto = true;
            });
           } else {

            this.translate.get('breadcrumbs.catalog.new').subscribe((app: string) => {
              this.posBreadcrumbs = this.utilityService.Navigation.length;
              this.utilityService.Navigation.push({title: app, path: '/list/new'});
            });
          }
        });
  }

  getListino(command?) {
    this.listinoService.getListinoProdotto(this.prodottoid).subscribe((res) => {
      this.listaListino = res.data;
      this.appFornitori = this.listaListino;
      console.log(this.listaListino);
      setTimeout(() => {this.selectActivation(); }, 1);
      if (command === 'start') {
        this.listaListinoInvariata = JSON.parse(JSON.stringify(this.listaListino));
      }
    });
  }

  // lista dei fornitori per la select
  SuppliersList() {
    this.listinoService.companiesRequest().subscribe((res: any) => {
      this.aziende = res.data;
    });
  }

  SetCatProdotto() {
    this.listinoService.getCategoriesProducts().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatProdotto[cat.label] = null;
      });
    });
  }

  initForms() {
    this.prodottoForm = this.formBuilder.group({
      description: this.utilityservice.standardFormControl(true, false),
      internal_sku: this.utilityservice.standardFormControl(true, false),
      barcode: this.utilityservice.standardFormControl(false, false),
      type: this.utilityservice.standardFormControl(true, false),
      price: this.utilityservice.standardFormControl(false, false),
      categories: this.utilityservice.standardFormControl(false, false),
      supplier_code: this.utilityService.standardFormControl(false, false),
      validity_date: this.utilityService.standardFormControl(false, false),
      suppliers_price_lists: this.utilityservice.standardFormControl(false, false)
    });
  }

  DataPicker() {
    // funzione per settere value datapicker
    this.prodottoForm.controls.validity_date.setValue(this.dataemissione.nativeElement.value);
    this.prodottoForm.controls.validity_date.markAsDirty();
  }

  invio() {
    this.modificata = true;

    this.prodottoForm.controls.suppliers_price_lists.setValue(this.listaListino);
    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    this.prodottoForm.controls.categories.setValue(chip);

    console.log(this.prodottoForm);

    if (this.prodottoForm.get('price').value === '') {
      this.prodottoForm.controls.price.setValue(0);
    }

    console.log(this.prodottoForm.value);
    if (this.prodottoForm.valid) {
        this.listinoService.postOrdine(this.prodottoForm.value).subscribe((res: any) => {
          console.log(res.data);
          console.log(this.prodottoForm.value);
          // this.prodottoForm.reset();
          // this.listaListino = [];

          this.listino = {
            suppliersId: null,
            price: null,
            min_qty: null,
            lead_time: null,
            PriceList: {suppliersId: null}
          };

          this.modificata = false;
          this.modListino = false;
          this.aggiunto = false;

          this.InsSupplier = false;
          this.InsPrice = false;
          this.InsQty = false;
          this.InsLead = false;

          this.validSupp = false;
          this.validPrice = false;
          this.validQty = false;
          this.validLead = false;
          this.Disable = true;

          console.log(this.prodottoForm);
          this.router.navigate(['/dashboard/list/' + res.data.id]);
        });
    } else {
      if (!this.aggiunto) {
        this.modListino = true;
      }
      M.toast({html: 'Inserisci tutti i campi del prodotto'});
    }
  }

  onChange(supplierID) {
    console.log(supplierID);
    this.listino.PriceList.suppliersId = this.select.nativeElement.options[this.select.nativeElement.selectedIndex].value;
    console.log(this.listino.PriceList.suppliersId);
    $('select').formSelect();
  }

  // controlli sugli input
  formControlName(abstractControl: AbstractControl, modificata) {
    return  this.utilityservice.FormClassValidator(abstractControl, modificata);
  }

  inputControlPrice(data, modListino) {
    if (this.InsPrice) {
      if (data !== 0 && data !== '') {
        this.validPrice = true;
      } else {
        this.validPrice = false;
        return 'valid invalid';
      }
    }
    if (modListino === true && (data === 0 || data === '')) {
      this.validPrice = false;
      return 'valid invalid';
    }
  }

  inputControlQty(data, modListino) {
    if (this.InsQty) {
      if (data !== 0 && data !== '' && data !== null) {
        this.validQty = true;
      } else {
        this.validQty = false;
        return 'valid invalid';
      }
    }
    if (modListino === true && (data === 0 || data === '')) {
      this.validQty = false;
      return 'valid invalid';
    }
  }
  inputControlLead(data, modListino) {
    if (this.InsLead) {
      if (data !== 0 && data !== '' && data !== null) {
        this.validLead = true;
      } else {
        this.validLead = false;
        return 'valid invalid';
      }
    }
    if (modListino === true && (data === 0 || data === '')) {
      this.validLead = false;
      return 'valid invalid';
    }
  }

  inputControlSupp(data, modListino) {
    if (this.InsSupplier) {
      if (data !== '') {
        this.validSupp = true;
      } else {
        this.validSupp = false;
        return 'valid invalid';
      }
    }
    if (modListino === true && (data === 0 || data === '')) {
      this.validSupp = false;
      return 'valid invalid';
    }
  }

  cambio(zone) {
    if (zone === 'supplier') {this.InsSupplier = true; }
    if (zone === 'price') {this.InsPrice = true; }
    if (zone === 'quantity') {this.InsQty = true; }
    if (zone === 'lead_time') {this.InsLead = true; }
  }

  // attivazione e disattivazione parti html
  activateLabel() {
    if (this.dettaglio) {
      return 'active';
    }
  }

  selectActivation() {
    $('select').formSelect();
    if (this.dettaglio) {
      if (!this.modifica) {
        $('select').prop('disabled', true);
        $('select').formSelect();
      } else {
        $('select').removeAttr('disabled');
        $('select').formSelect();
      }
    }
  }

  buttonActivation() {
    if (this.dettaglio && !this.modifica) {
        return 'disable';
    }
  }


  // gestione prodotto
  modificaProdotto() {
    this.modifica = true;
    this.Disable = false;
    this.prodottoForm.enable();

    const children = Array.from(this.chips.nativeElement.children);
    console.log(this.chips.nativeElement.children);
    console.log(children);
    children.forEach( (res: any) => { res.disabled = this.Disable; console.log(res.disabled); });
    this.selectActivation();
  }

  patchSend() {
    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    this.prodottoForm.controls.categories.setValue(chip);
    this.prodottoForm.controls.categories.markAsDirty();
    this.prodottoForm.controls.suppliers_price_lists.setValue(this.listaListino);
    this.prodottoForm.controls.suppliers_price_lists.markAsDirty();
    if (this.prodottoForm.valid && this.aggiunto) {
        const dirtyFields = {};
        for (const control in this.prodottoForm.controls) {
          if (this.prodottoForm.get(control).dirty) {
            if (control === 'price') {
              dirtyFields[control] = parseFloat(this.prodottoForm.get(control).value);
              console.log('ciao PArse');
            } else {
              dirtyFields[control] = this.prodottoForm.get(control).value;
            }
          }
        }
        console.log('type', typeof dirtyFields['price']);
        this.listinoService.patchOrdine(this.prodottoid, dirtyFields).subscribe((res: any) => {
          if (dirtyFields['internal_sku']) {
            this.utilityService.Navigation.pop();
            this.utilityService.Navigation.push({title: dirtyFields['internal_sku'], path: '/list/' + this.prodottoid});
          }
          this.router.navigate(['/dashboard/list/' + this.prodottoid]);
          this.modifica = false;
          this.selectActivation();
          this.prodottoForm.disable();
          this.dettaglio = true;
          this.Disable = true;
        });
      } else {
        M.toast({html: 'Inserisci tutti i campi del prodotto'});
      }
  }

  cancel() {
    this.dettaglio = true;
    this.listinoService.GetProductsById(this.prodottoid).subscribe((res: any) => {
      this.appProdotto = $.extend(true, {}, this.prodotto);
      this.prodottoForm.reset(this.appProdotto);
      this.formatValue();
      this.prodottoForm.disable();
    });

    for (const control in this.chips.nativeElement.children) {
      if (this.chips.nativeElement.children[control].tagName === 'INPUT') {
        this.chips.nativeElement.children[control].value = null;
      }
    }

    this.categories = [];
    this.listinoService.GetProductsById(this.prodottoid).subscribe((res: any) => {
      res.data.categories.forEach((tag: any) => {
        this.categories.push({tag: tag.label});
        console.log(this.categories);
      });
    });

    this.getListino();
    this.modifica = false;
    this.Disable = true;
    const children = Array.from(this.chips.nativeElement.children);
    children.forEach( (res: any) => res.disabled = this.Disable);
    console.log(this.Disable);
    setTimeout(() => {this.selectActivation(); }, 1);

    if (this.listinopatch.length > 0) {
      this.listaListinoInvariata.map(e => {
        e.suppliersId = e.PriceList.suppliersId;
        return e;
      });
      console.log(this.listaListinoInvariata);
      this.listaListinoInvariata.forEach(L => {
        this.listinopatch.forEach(l => {
          if (L.id === l.id && l.action === 'update') {
            this.listinoService.patchListino(L, this.prodottoid, l.id).subscribe(() => {
              this.getListino();
            });
          }
          if (L.id === l.id && l.action === 'delete') {
            console.log(L);
            this.listinoService.addListino(L, this.prodottoid).subscribe(() => {
              this.getListino();
            });
          }
          if (l.action === 'add') {
            this.listinoService.deleteListino(this.prodottoid, l.id).subscribe(() => {
              this.getListino();
            });
          }
        });
      });
      this.listinopatch = [];
    }
  }

  clear() {
    $('.chips').chips({
      data: []
    });
    this.prodottoForm.reset();
    this.listaListino = [];
    this.listino = {
      suppliersId: null,
      min_qty: null,
      price: '',
      lead_time: null,
      PriceList: {suppliersId: null}
    };
  }

  // gestione listino
  Add() {
    if (!this.dettaglio) {
     this.addListino();
    } else {
      this.callAddListino();
    }
  }

  callDeleteListino(id, i) {
    if (id) {
      Swal.fire({
        title: 'Sei sicuro?',
        text: 'Non sarai in grado di recuperare questa voce del listino',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Elimina',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Eliminata!',
            'La voce del listino è stata eliminata con successo',
            'success'
          );
          this.listinopatch.push({id: id, action: 'delete'});
          this.listinoService.deleteListino(this.prodottoid, id).subscribe(() => {
            this.listaListino.splice(i, 1);
            if (this.listaListino.length === 0) {
              this.aggiunto = false;
            }
          });
        }
      });
    } else {
      this.listaListino.splice(i, 1);
    }
  }

  addListino() {
    this.modListino = true;
    if (this.listino.suppliersId !== '' && this.listino.price !== '' && this.listino.min_qty !== null && this.listino.lead_time !== null) {
      this.listaListino.push(this.listino);
      console.log(this.listaListino);
      this.listino = {
        suppliersId: null,
        price: null,
        min_qty: null,
        lead_time: null,
        PriceList: {suppliersId: null}
      };

      this.InsSupplier = false;
      this.InsPrice = false;
      this.InsQty = false;
      this.InsLead = false;

      this.validSupp = false;
      this.validPrice = false;
      this.validQty = false;
      this.validLead = false;

      setTimeout(() => { $('select').formSelect(); }, 1);

      // this.selectActivation();

      this.aggiunto = true;
      this.nuovo = false;
    } else {
      M.toast({html: 'Inserisci tutti i campi del listino'});
    }
    this.modListino = false;

  }

  callAddListino() {
    console.log(this.listino);
    if (this.listino.suppliersId !== '' && this.listino.price !== '' && this.listino.min_qty !== null && this.listino.lead_time !== null) {
      this.listino.suppliersId = parseInt(this.listino.suppliersId, 10);

      this.validSupp = false;
      this.validPrice = false;
      this.validQty = false;
      this.validLead = false;

      this.listinoService.addListino(this.listino, this.prodottoid).subscribe((res: any) => {
        console.log(this.listino);
        this.listinopatch.push({id: res.data.id, action: 'add'});
        this.listaListino.push(this.listino);

        this.aggiunto = true;

        this.listino = {
          suppliersId: null,
          price: null,
          min_qty: null,
          lead_time: null,
          PriceList: {suppliersId: null},
        };
        this.nuovo = false;
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi del listino'});
    }
  }

  ViewAdd() {
    this.nuovo = true;
  }

  listinoPatch(idListino, label, value) {
    let patch = {};
    if (label !== 'all') {
      patch[label] = value;
    } else {
      patch = value;
    }
    if (this.listinopatch.length > 0) {
      this.listinopatch.forEach((ID) => {
        if (ID.id !== idListino) {
          this.listinopatch.push({id: idListino, action: 'update'});
        }
      });
    } else {
      this.listinopatch.push({id: idListino, action: 'update'});
    }
    console.log(patch);
    this.listinoService.patchListino(patch, this.prodottoid, idListino).subscribe(() => {
      this.getListino();
    });
  }
  parseValue(id) {
    return parseInt(id, 10);
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  onResize(resize: ResizedEvent) {
    console.log('ciao', resize);
    this.heightDirective.setHeight(resize.newHeight);
  }

  ShowModalUpload(name?) {
    if (name) {
      this.idAllegato = name;
    }
    $('#ModalUpAllegati').modal('open');
  }

  Showdescription() {
    setTimeout(() => { M.textareaAutoResize($('#textareaDescription')); }, 10);
  }

  discardFile(i) {
    this.file.splice(i, 1);
    $('.tooltiped').tooltip('close');
    console.log(this.file);
  }

  ShowAllegati(type, idrow?) {
    if (type === 'allegati') {
      // api per allegati
    } else {
      // api per revisioni
    }
    $('#AllegatiModal').modal('open');
  }

  onFilesAdded(files: File[], type) {
    console.log(files);
    if (type === 'add') {
      files.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.forEach(file => {
        this.file.forEach( f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }

    console.log(this.file);
  }

  SaveFile() {
    // chiamata api per Salvare
    this.filename = [];
    console.log(this.checkRevisione);
  }

  check() {
    this.checkRevisione = !this.checkRevisione;
  }


  formatValue() {
    if (this.prodottoForm.get('price').value !== null) {
    this.prodottoForm.patchValue({price: this.numberPipe.transform(this.prodottoForm.get('price').value)});
    }
  }

  disable() {
    if (this.Disable) {
      return 'dotted';
    }
  }
}
