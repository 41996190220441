import {Injectable} from '@angular/core';
import {BaseService} from '@app/base.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServerBaseResponse} from '@app/shared/models/server-base-response';
import {FornitoriListaModel} from '@app/shared/models/db_tables/fornitori-lista.model';
import {environment} from '../../../environments/environment';
import {GareListaModel} from '@app/shared/models/db_tables/.gare-lista.model';
import {GareModel, RowsModel} from '@app/shared/models/db_tables/.gare.model';
import {IndirizziModel} from '@app/shared/models/db_tables/.indirizzi.model';

@Injectable({
  providedIn: 'root'
})
export class GareService extends BaseService {

  data;
  constructor(translate: TranslateService,
              private httpClient: HttpClient, ) {
    super();
  }

  getCompetitionById(competitionId?: number, loader = 'false', filters?): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/competitions/' + competitionId, filters,
        { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }

  deleteCompetitionByid(id: number): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .delete <ServerBaseResponse<any>>(environment.apiUrl + '/competitions/' + id, {withCredentials: true});
  }

  getLista(language?, datatableparams?): Observable<ServerBaseResponse<GareListaModel>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>( environment.apiUrl + '/competitions', datatableparams, { withCredentials: true, headers: new HttpHeaders({'x-lang': language})});
  }

  createCompetition(data): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/competitions/new', data, {withCredentials: true});
  }

  getRDProws(competitionId: number, filters?, loader = 'false'): Observable<ServerBaseResponse<RowsModel>> {
    return this.httpClient
      .post <ServerBaseResponse<RowsModel>>(environment.apiUrl + '/competitions/' + competitionId + '/rdp-rows', filters ,
        { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }

  updateCompetition(data, competitionId: number): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>( environment.apiUrl + '/competitions/' + competitionId, data, {withCredentials: true});
  }
  updateCompetitionStatus(status, competitionId: number, data = {}): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .patch <ServerBaseResponse<any>>( environment.apiUrl + '/competition/' + competitionId + '/set-status/' + status, data, {withCredentials: true});
  }

  MassDeleteCompetitions(competitionsIDs): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/competitions/bulk-delete', competitionsIDs, {withCredentials: true});
  }

  UpdateRDProw(row, rdpId, rowId): Observable<ServerBaseResponse<RowsModel>> {
    return this.httpClient
      .patch <ServerBaseResponse<RowsModel>>( environment.apiUrl + '/rdps/' + rdpId + '/rows/' + rowId,
        row , { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': 'true'})});
  }

  convertToOrder(data, loader = 'false'): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .post <ServerBaseResponse<any>>(environment.apiUrl + '/rdps/batch-transition-to-order', data,
        { withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': loader})});
  }
}
