import {ApplicationRef, Component, ElementRef, OnInit} from '@angular/core';
import {OrdiniListaModel} from '@app/shared/models/db_tables/ordini-lista.model';
import {OrdiniService} from '@app/dashboard/ordini/ordini.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FornitoriService} from '@app/dashboard/fornitori/fornitori.service';
import {UtilityService} from '@app/shared/utility.service';
import {FormBuilder} from '@angular/forms';
import {OrdiniRigheListaModel} from '@app/shared/models/db_tables/ordini-righe-lista.model';
import {ConsegneListaModel} from '@app/shared/models/db_tables/.consegne-lista.model';
import {ConsegneService} from '@app/dashboard/Consegne/consegne.service';

declare const $: any;
@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  constructor(
    private ordiniservice: OrdiniService,
    private translate: TranslateService,
    private fornitoriService: FornitoriService,
    private appref: ApplicationRef,
    private consegneService: ConsegneService,
    private utilityService: UtilityService,
    private dropzone: ElementRef,
    private fornitoriservice: FornitoriService) {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.translate.use(event.lang);
        this.refresh = false;
        this.translate.get('breadcrumbs.deliveries.list').subscribe((app: string) => {
          this.utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/deliveries'};
        });
        setTimeout(() => {
          this.refresh = true;
        }, 0);
      });
  }

  user;
  refresh;
  posBreadcrumbs;
  orderby = 'none';
  categoriesFilter: any;
  categoriesFilterConsegne: any;
  chips: any = [];
  chipsOrders: any = [];
  totalPageconsegne: number;
  totalPageRows: number;
  totalPageLog: number;
  select = false;
  checkrow = false;
  visualizza = 'Globale';
  raggruppamento = [
    {label: 'Fornitori', active: false },
    {label: 'Globale', active: true }
  ];

  listfilter = [];
  filter = {search: {}, filters: {}};
  filterConsegne = {search: {}, filters: {}};
  reverseOrdersRow: any;
  reverseOrder: any;

  consegne: Array<OrdiniListaModel> = [];
  ordini: Array<OrdiniListaModel> = [];

  selected = [];

  ngOnInit(): void {
     this.user = this.utilityService.user;
     this.utilityService.Navigation = [];
     this.translate.get('breadcrumbs.deliveries.list').subscribe((app: string) => {
      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: app, path: '/deliveries'});
    });
     this.getDeliveries('false');
  }

  getDeliveries(loader, filter = {}, stop = false) {
    if (sessionStorage.getItem('filterCatConsegne') && sessionStorage.getItem('filterCatConsegne') !== 'undefined' && !stop) {
      const filterControl = JSON.parse(sessionStorage.getItem('filterConsegne'));
      this.categoriesFilter = JSON.parse(sessionStorage.getItem('filterCatConsegne'));
      this.chips = JSON.parse(sessionStorage.getItem('filterChipconsegne'));
      if (Object.keys(filterControl.search).length > 0 || Object.keys(filterControl.filters).length > 0) {
        filter = filterControl;
        this.filter = filterControl;
        this.getDeliveries(false, JSON.parse(sessionStorage.getItem('filterConsegne')), true);
      }
    }
    this.consegneService.getList(loader, filter).subscribe((res: any) => {
      console.log('CONSEGNE: ', res.data);
      this.consegne = res.data;
      if (!sessionStorage.getItem('filterCatConsegne') || sessionStorage.getItem('filterCatConsegne') === 'undefined') {
        // this.chips = JSON.parse(sessionStorage.getItem('filterChipconsegne'));
        this.categoriesFilter = res.filtersList;
      }
      this.totalPageconsegne = res.pagination.totalPages;
    });
  }

  SetFilter(supplier: string) {

  }

  ChangeView(type) {
    this.visualizza = type;
    this.selected = [];
    this.select = false;
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare backgorund alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      console.log(this.selected);
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
          this.select = false;
      }
    }
  }

  pagination(page, type, idconsegne?, idOrders?) {
    console.log(page);
    if (type === 'consegne') {
      this.filter['pagination'] = page;
      console.log(this.filter);
      this.getDeliveries('false', this.filter);
    } else if (type === 'orders') {
      this.filterConsegne['pagination'] = page;
      console.log(this.filter);
       this.getDeliveries('false', this.filterConsegne);
    } else {
      const paginationlog: any = {};
      page['pageSize'] = 10;
      paginationlog['pagination'] = page;
      console.log(paginationlog);
      // this.getReverseOrdersconsegne(idOrders, idconsegne, paginationlog);
    }
  }

  emitSearch(event) {
    if (event.action !== 'delete') {
      if (this.filter.search[event.property] === undefined) {
        this.filter.search[event.property] = [];
      }
      this.filter.search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter();
    }
  }

  saveFilter(event) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        if (this.filter.filters[event.type] === undefined) {
          this.filter.filters[event.type] = [];
        }
        this.filter.filters[event.type].push(event.value);
      } else {
        this.filter.filters[event.type] = event.value;
      }
    } else {
      delete this.filter.filters[event.type];
    }
    this.Searchwithfilter(event.cat);
  }

  Searchwithfilter(cat?) {
    console.log('apply');
    sessionStorage.setItem('filterCatConsegne', JSON.stringify(cat));
    sessionStorage.setItem('filterConsegne', JSON.stringify(this.filter));
    this.getDeliveries(false, this.filter);
  }

  deleteFilter(items) {
    this.filter = {search: {}, filters: {}};
    let isSearch = false;
    const categories: Array<any> = this.categoriesFilter.search;
    const categoriesFilters: Array<any> = this.categoriesFilter.filters;
    if (items.length > 0) {
      items.forEach((item) => {
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter.search[cat.name] === undefined) {
              this.filter.search[cat.name] = [];
            }
            this.filter.search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  option.active = false;
                } else {
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Competition status') {
                      this.filter.filters[filter.name] = option.value;
                    } else {
                      if (this.filter.filters[filter.name] === undefined) {
                        this.filter.filters[filter.name] = [];
                      }
                      this.filter.filters[filter.name].push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type  && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  const res = item.tag.split(' ');
                  this.filter.filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    this.Searchwithfilter(this.categoriesFilter);
  }

  orderByHeader(element) {
    if (element === this.orderby) {
      this.orderby = element + '_less';
    } else {
      this.orderby = element;
    }
  }

  ColorStatus(stato) {
    if (stato === 'accepted') {

      return 'status-accepted';

    } else if (stato === 'canceled') {

      return 'status-canceled';

    } else if (stato === 'inserted') {

      return 'status-inserted';

    } else if (stato === 'proposed_buyer') {

      return 'status-proposed_buyer';

    } else if (stato === 'edited_buyer') {

      return 'status-edited_buyer';

    } else if (stato === 'proposed_supplier') {

      return 'status-edited_supplier';

    } else if (stato === 'received') {

      return 'status-received';

    }
  }
}
