import { Component, OnInit } from '@angular/core';
import {UtilityService} from "@app/shared/utility.service";
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {Color, Label, MultiDataSet} from 'ng2-charts';
import {Route, Router} from "@angular/router";
import {StatisticheService} from "@app/dashboard/statistiche/statistiche.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {

  posBreadcrumbs;
  doughnutChartLabelsRows: Label[];
  doughnutChartDataRows: ChartDataSets[];
  doughnutChartTypeRows: ChartType;
  doughnutChartColorsRows;
  barChartColor;
  doughnutChartLabelsSupp: Label[];
  doughnutChartDataSupp: ChartDataSets[];
  doughnutChartTypeSupp: ChartType;
  doughnutChartColorsSupp;

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40, 30, 95, 55, 35], label: 'in tempo' },
    { data: [28, 48, 40, 19, 86, 27, 90, 13, 19, 5, 30], label: 'in ritardo' }
  ];


  Statistics;

  constructor(private utilityService: UtilityService, private router: Router, private statisticheService: StatisticheService) { }

  lineChartData: ChartDataSets[];
  lineChartDataMail: ChartDataSets[];
  lineChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
  lineChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        stacked: true
      }]
    },
    pointStyle: 'line',
    cubicInterpolationMode: 'monotone',
    borderCapStyle: 'square'
  };
  lineChartColors: Color[] = [
    { // grey
      backgroundColor: '#86c7f3',
    },
  ];
  lineChartType = 'line';
  //#endregion 'linechart'

  ngOnInit() {
    this.utilityService.Navigation = [];
    this.posBreadcrumbs = this.utilityService.Navigation.length;
    this.utilityService.Navigation.push({title: 'Statistiche', path: '/statistics'});
    this.statisticheService.getStatistiche().subscribe((res) => {
      this.Statistics = res;
      this.doughnutChartLabelsRows = this.Statistics.rows.status;
      this.doughnutChartDataRows = [{ data: this.Statistics.rows.value}];
      this.doughnutChartTypeRows = 'doughnut';
      this.barChartColor = [ {
        backgroundColor: ['#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368', '#1db368'],
      },
        {
          backgroundColor: ['#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f', '#c5210f'],
        },
      ];

      this.doughnutChartLabelsSupp = this.Statistics.supplier.categories;
      this.doughnutChartDataSupp = [{ data: this.Statistics.supplier.value}];
      this.doughnutChartTypeSupp = 'doughnut';
      this.doughnutChartColorsSupp = [ {
        backgroundColor: ['#1db368', '#ffa938', '#c5210f'],
      },
      ];
      this.doughnutChartColorsRows = [ {
        backgroundColor: ['#1db368', '#c5210f'],
      },
      ];
      this.lineChartData = [
        this.Statistics.orders.value
      ];
      this.lineChartDataMail = [
        this.Statistics.mail.value
      ];

    });
  }

  navigate(path, stato) {
    console.log(path);
    this.router.navigate([path], {queryParams: {status: stato}});
  }

  emailTest() {
    // Swal.fire({
    //   title: 'Confermato',
    //   type: 'success',
    //   html: '<p class="text-list">Il suo ordine è andato a buon fine, riceverà entro qualche minuto un\'email con tutte le istruzioni per portare a termine il pagamento.</p>' +
    //     '<p><b>Si assicuri di controllare anche la cartella della SPAM.</b></p>' +
    //     '<p><b>NOTA BENE</b> ' +
    //     '<br>Qualora desideri effettuare una variazione al suo ordine o necesiti di informazioni scriva a info@forjesi.it<b></b></p>'}
    // ).then((result) => {
    //   this.router.navigate(['dashboard/mosaico']);
    // });
    this.statisticheService.emailTest('it').subscribe();
  }

}
