import {Component, Input, OnInit} from '@angular/core';
import {Label, MultiDataSet} from "ng2-charts";
import {ChartType} from "chart.js";

@Component({
  selector: 'DM-doughnutchart',
  templateUrl: './doughnutchart.component.html',
  styleUrls: ['./doughnutchart.component.scss']
})
export class DoughnutchartComponent implements OnInit {

  @Input() settings;

  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [];
  doughnutChartType: ChartType = 'doughnut';

  constructor() { }

  ngOnInit() {
    this.doughnutChartLabels = this.settings.label;
    this.settings.value.forEach((data) => {
      this.doughnutChartData.push(data.data);
    });
  }

}
