import {Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';

@Component({
  selector: 'DM-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class  StatusComponent implements OnInit, OnChanges {

  @Input() status;
  @Input() page;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status.currentValue !== changes.status.previousValue) {
      console.log(this.status);
    }
  }

  getStatus(value, value2?) {
    if (value2) {
      if (this.status === value || this.status === value2) {
        return 'selected';
      }
    } else {
      if (this.status === value) {
        return 'selected';
      }
    }

  }
}
