import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'DM-Navigation',
  templateUrl: './detailsNav.component.html',
  styleUrls: ['./detailsNav.component.scss'],
})
export class DetailsNavComponent implements OnInit, OnChanges {

  constructor(
    private router: Router
  ) {}

  @Input() Link: string;
  @Input() ID: number;
  @Input() Navigation: any;

  pos;
  lastid;

  ngOnInit() {

    this.lastid = this.Navigation.length;
    console.log(this.Navigation);
  }

  changeDetail() {
    this.router.navigate([this.Link + this.Navigation[this.ID - 1]]);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  checkPosition(direction) {
    if ((direction === 'back' && this.ID === 1) || (direction === 'forward' && this.ID === this.lastid)) {
      return 'disable';
    }
  }

  prova(prova) {
    console.log(this.lastid);
    console.log(prova);
  }
}
