import { Injectable } from '@angular/core';
import { BaseService } from '@app/base.service';
import { map } from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServerBaseResponse} from "@app/shared/models/server-base-response";
import {environment} from "../../../environments/environment";

declare const moment: any;

@Injectable({
  providedIn: 'root'
})
export class StatisticheService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super();
  }

  getStatistiche(): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .get <ServerBaseResponse<any>>( environment.apiUrl + '/statistics', {withCredentials: true} );
  }
  emailTest(language): Observable<ServerBaseResponse<any>> {
    return this.httpClient
      .get <ServerBaseResponse<any>>(environment.apiUrl + '/test/mail', {withCredentials: true, headers: new HttpHeaders({'X-META-NOLOADER': 'true', 'x-lang': language})});
  }


}
