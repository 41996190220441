import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {UtilityService} from '@app/shared/utility.service';
import {GareService} from '@app/dashboard/gare/gare.service';
import {NumberPipe} from '@app/shared/customPipes/customPipe.component';
import {ListinoService} from '@app/dashboard/listino/listino.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {FornitoriService} from '@app/dashboard/fornitori/fornitori.service';
import {ProdottiListaModel} from '@app/shared/models/db_tables/prodotti-lista.model';
import * as moment from 'moment';
import {SettingsService} from '@app/dashboard/settings/settings.service';
import Swal from 'sweetalert2';
import {FornitoriListaModel} from '@app/shared/models/db_tables/fornitori-lista.model';
import {GareModel, RowsModel} from '@app/shared/models/db_tables/.gare.model';
import {RDPModel} from '@app/shared/models/db_tables/.rdp.model';
import {RdpService} from '@app/dashboard/Rdp/rdp.service';
import {TranslateService} from '@ngx-translate/core';

declare const M: any;
declare const $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private utilityService: UtilityService,
              private competitionService: GareService,
              private rdpService: RdpService,
              private numberPipe: NumberPipe,
              private translate: TranslateService,
              private settingsService: SettingsService,
              private prodottiService: ListinoService,
              private fornitoriService: FornitoriService,
              private router: Router) {
  }

  @ViewChild('qta') qta: ElementRef;
  @ViewChild('startdate') startdate: ElementRef;
  @ViewChild('endate') endate: ElementRef;
  @ViewChild('chip') chips: ElementRef;
  @ViewChild('label') label: ElementRef;
  @ViewChild('autocomplete') autocomplete;
  @ViewChild('chipMercio') chipMercio: ElementRef;
  @ViewChild('chipProdotti') chipProdotti: ElementRef;

  prodottoForm: FormGroup = null;
  fornitoreForm: FormGroup = null;
  garaForm: FormGroup = null;
  orderForm: FormGroup = null;

  nuovoRDP = false;
  Disable = false;
  competitionId;
  selected = [];
  supplierSelected = [];
  competition: GareModel;
  currencies = [];
  fornitore: FornitoriListaModel;
  DMFornitori = [];
  listaprodotti: Array<ProdottiListaModel> = [];
  products = [];
  categories = [];
  prodotti = [];
  prodotto = {
    productsId: 0,
    internal_sku: '',
    description: '',
    requested_qty: 0,
    requested_delivery_date: '',
    categories: []
  };
  RDPs = [];
  filteredRDPs = [];
  RDP = {
    suppliersId: null,
    Rows: {
      productsId: null,
      price: 0,
      qty: 0,
      notes: '',
      delivery_date: ''
    }
  };
  order: [];
  orders: {
    suppliersId: 0;
    currency: 0;
    delivery_terms: '';
    payment_terms: '';
    notes: '';
    address: '';
    referent: '',
  };
  note;
  fornitori = [];
  suppliers = [];
  filteredFornitori = [];
  competitionav;
  index;
  message = false;
  nuovoProdotto = false;
  nuovoFornitore = false;
  modificata = false;
  checkrow = false;
  select = false;
  terminiresa;
  terminipagamento;
  catFornitori = {};

  status;
  command;
  titleButton;
  title;
  dettaglio = false;
  modifica = false;
  refresh = false;
  user;
  rowId;
  idAllegato;
  file = [];
  commentoRev;
  filename = [];

  CatMerciologiche = {};
  CatProdotto = {};
  categoriesFilterRdp: any;
  categoriesFilterFornitori: any;
  Chips = [];
  filter = {
    fornitore: {
      search: {},
      filters: {}
    },
    rdp: {
      search: {},
      filters: {}
    }
  };
  filterRdp = {search: {}, filters: {}};
  hiddenFilter = false;

  totalPage: number;

  rdpsUpdate = [];
  
  ngOnInit() {
    this.user = this.utilityService.user;
    console.log(this.user);
    this.initForms();
    this.getCategories();

    this.activatedRoute
      .paramMap
      .subscribe(
        (paramsMap: ParamMap) => {
          // se l'url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
          this.competitionId = +paramsMap.get('id');
          if (this.user.group !== 'supplier') {
            if (this.competitionId) {
              this.getCompetition();
              /*
              this.competitionService.getCompetitionById(this.competitionId).subscribe((res: any) => {
                this.competition = res.data;
                this.competitionav = res.stats.resultsSetIDs;
                this.utilityService.Navigation.push({title: this.competition.code, path: '/suppliers/' + this.competitionId});
              })
              */
              this.getProducts();
              this.getCurrencies();
            } else {
              this.utilityService.Navigation.push({title: 'Nuova gara', path: '/competitions/new'});
              this.refresh = true;
              this.dettaglio = false;
              this.titleButton = 'Salva';
              this.status = 'inserted';
              this.command = 'start';
              this.title = 'Nuova gara';
              this.startdate.nativeElement.value = moment().format('YYYY-MM-DD');
              this.garaForm.controls.startDate.setValue(this.startdate.nativeElement.value);
              this.label.nativeElement.classList.add('active');
              this.getProducts();
              this.getSuppliers();
              this.getCurrencies();
            }
          } else {
            if (this.competitionId) {
              this.titleButton = 'Modifica';
              this.competitionService.getCompetitionById(this.competitionId).subscribe((res: any) => {
                this.dettaglio = true;
                this.refresh = true;
                this.competition = res.data;
                this.competitionService.getRDProws(this.competitionId).subscribe((row: any) => {
                  this.competition.RDPsrows = row.data;
                });
                this.garaForm.patchValue(this.competition);
                this.startdate.nativeElement.value = this.competition.startDate;
                this.endate.nativeElement.value = this.competition.endDate;
                this.status = this.competition.status;
                this.garaForm.disable();
                this.Disable = true;
                this.utilityService.Navigation.push({title: this.competition.code, path: '/competitions/' + this.competitionId});
              });
            }
          }
        });
  }

  initForms() {
    this.prodottoForm = this.formBuilder.group({
      description: this.utilityService.standardFormControl(true, false),
      internal_sku: this.utilityService.standardFormControl(true, false),
      barcode: this.utilityService.standardFormControl(true, false),
      type: this.utilityService.standardFormControl(true, false),
      price: this.utilityService.standardFormControl(true, false),
      categories: this.utilityService.standardFormControl(true, false)
    });

    this.fornitoreForm = this.formBuilder.group({
      r_sociale: this.utilityService.standardFormControl(true),
      piva: this.utilityService.standardFormControl(false),
      indirizzo_consegna: this.utilityService.standardFormControl(false),
      tel: this.utilityService.telephoneFormControl(true, 10, '', true),
      email: this.utilityService.emailFormControl(true),
      website: this.utilityService.standardFormControl(false),
      delivery_term: this.utilityService.standardFormControl(false),
      payment_term: this.utilityService.standardFormControl(false),
      currency: this.utilityService.standardFormControl(false),
      fax: this.utilityService.telephoneFormControl(true, 10, '', true),
    });

    if (this.user.group !== 'supplier') {
      this.garaForm = this.formBuilder.group({
        startDate: this.utilityService.standardFormControl(true, false),
        endDate: this.utilityService.standardFormControl(true, false),
        Rows: this.utilityService.standardFormControl(true, false),
        suppliers: this.utilityService.standardFormControl(true, false),
        notes: this.utilityService.standardFormControl(false, false)
      });
    } else {
      this.garaForm = this.formBuilder.group({
        startDate: this.utilityService.standardFormControl(true, false),
        endDate: this.utilityService.standardFormControl(true, false),
        Rows: this.utilityService.standardFormControl(true, false),
        notes: this.utilityService.standardFormControl(false, false)
      });
    }
    this.orderForm = this.formBuilder.group({
      currency: this.utilityService.standardFormControl(),
      delivery_term: this.utilityService.standardFormControl(),
      payment_term: this.utilityService.standardFormControl(),
      indirizzo_consegna: this.utilityService.standardFormControl(),
    });
  }

  getCompetition(loader?) {
    this.competitionService.getCompetitionById(this.competitionId, loader).subscribe((res: any) => {
      this.competition = res.data;
      this.competitionav = res.stats.resultSetIDs;
      this.index = res.stats.resultSetIDs.indexOf(this.competitionId) + 1;
      this.dettaglio = true;
      this.refresh = true;
      this.status = this.competition.status;
      this.titleButton = 'Modifica';
      this.command = 'modifica';

      this.garaForm.patchValue(this.competition);
      this.startdate.nativeElement.value = this.competition.startDate;
      this.endate.nativeElement.value = this.competition.endDate;
      this.getRDP(null, null, loader);
      this.garaForm.disable();
      this.title = this.competition.code;

      this.Disable = true;
      this.utilityService.Navigation.push({title: this.competition.code, path: '/competitions/' + this.competitionId});
    });
  }

  getRDP(filters?, cat = null, loader = 'false') {
    this.products = [];
    this.competitionService.getRDProws(this.competitionId, filters, loader).subscribe((row: any) => {
      this.competition.RDPsrows = row.data;
      if (this.competition.RDPsrows.length > 0) {
        this.competition.RDPsrows.forEach((r: any) => {
          r.Product.defined_qty = r.ordered_qty;
          r.Product.requested_qty = r.requested_qty;
          r.Product.requested_delivery_date = r.requested_delivery_date;
          if (this.products.length === 0) {
            // qui setto le categorie da usare come filtri per i fornitori
            this.products.push(r.Product);
          } else {
            if (this.products.find(p => p.id === r.Product.id || p.productsId === r.Product.id) === undefined) {
              this.products.push(r.Product);
            }
          }
        });
      }
      if (cat) {
        this.categoriesFilterRdp = cat;
      } else {
        this.categoriesFilterRdp = row.filtersList;
      }
      this.products = this.products.map(e => {
        e.productsId = e.id;
        e.total = 0;
        delete e.id;
        return e;
      });

     /* this.competition.RDPsrows.map(e => {
        e.ordered_qty = 0;
        return e;
      });*/

      if (this.user.group !== 'supplier') {
        this.DMFornitori = [];
        this.getReply();
      }
      this.getSuppliers(null, null, loader);
    });
  }

  getProducts() {
    this.prodottiService.GetListProducts(this.translate.currentLang).subscribe((res: any) => {
      this.listaprodotti = res.data;
      this.listaprodotti.forEach(p => {
        this.prodotti.push({label: p.internal_sku, value: p.id});
      });
    });
  }

  getCategories() {
    this.fornitoriService.getCategoriesCommercia().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatMerciologiche[cat.name] = null;
      });
    });
    this.fornitoriService.getCategoriesProducts().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatProdotto[cat.label] = null;
      });
    });
  }

  getSuppliers(filters?, cat = null, loader = 'false') {
    console.log(this.products);
    console.log('filteeeeeeeeeeeeeeeers supplier', filters);
    this.fornitoriService.getListaTable(loader, this.translate.currentLang, filters).subscribe((res: any) => {
      this.fornitori = res.data;
      this.suppliers = res.data;
      if (cat) {
        this.categoriesFilterFornitori = cat;
        console.log('cat', this.categoriesFilterFornitori);
      } else {
        this.categoriesFilterFornitori = res.filtersList;
        console.log('res', this.categoriesFilterFornitori);
      }
      this.totalPage = res.pagination.totalPages;
      console.log(this.fornitori);
      this.filteredFornitori = [];
      for (const fornitori of res.data) {
        this.filteredFornitori.push({label: fornitori.r_sociale, value: fornitori.id});
        this.catFornitori[fornitori.r_sociale] = null;
      }
    });
  }

  getFilteredSupplier(params?) {
    this.fornitoriService.getListaTable( params).subscribe((res: any) => {
      this.suppliers = res.data;
    });
  }

  getCurrencies() {
    this.settingsService.getCurrentCompany().subscribe((res: any) => {
      this.settingsService.getCurrencyList(res.data.id).subscribe((val: any) => {
        val.data.forEach((v: any) => {
          this.currencies.push({value: v.id, label: v.label});
        });
      });
    });
  }

  getReply() {
    this.competition.RDPs.forEach((r: any) => {
      if (this.DMFornitori.length === 0) {
        if (this.competition.RDPsrows.find(R => R.RDP.suppliersId === r.Supplier.id).RDP.status === 'answered') {
          this.DMFornitori.push({tag: r.Supplier.r_sociale, value: r.Supplier.id, reply: 'risposto'});
          this.supplierSelected.push(r.Supplier.id);
        } else {
          this.DMFornitori.push({tag: r.Supplier.r_sociale, value: r.Supplier.id});
          this.supplierSelected.push(r.Supplier.id);
        }
      } else {
        if (this.DMFornitori.find(f => f.tag === r.Supplier.r_sociale) === undefined) {
          if (this.competition.RDPsrows.find(R => R.RDP.suppliersId === r.Supplier.id).RDP.status === 'answered') {
            this.DMFornitori.push({tag: r.Supplier.r_sociale, value: r.Supplier.id, reply: 'risposto'});
            this.supplierSelected.push(r.Supplier.id);
          } else {
            this.DMFornitori.push({tag: r.Supplier.r_sociale, value: r.Supplier.id});
            this.supplierSelected.push(r.Supplier.id);
          }
        }
      }
    });
  }

  updateBySupplier() {
    if (this.titleButton === 'Modifica') {
      this.titleButton = 'Salva';
    } else {

      this.titleButton = 'Modifica';
      // api per salvare
      console.log(this.rdpsUpdate);
      /*this.competitionService.UpdateRDProw(patch, rpdId, id).subscribe( () => {
      this.getCompetition('true');
      });*/
      this.rdpService.UpdateRdp(this.rdpsUpdate, this.rdpsUpdate[0].rdpId).subscribe(() => {
        this.getCompetition('true');
      });

    }
  }

  Competition(command) {
    console.log('command', command);
    if (command !== 'modifica') {
      this.garaForm.controls.Rows.setValue(this.products);
      this.garaForm.controls.suppliers.setValue(this.supplierSelected);
      console.log(this.garaForm);
      if (this.garaForm.valid) {
        if (new Date(this.garaForm.get('startDate').value) < new Date(this.garaForm.get('endDate').value) ||
          new Date(this.garaForm.get('startDate').value) < new Date(moment().format('YYYY-MM-DD'))) {
          if (command === 'start') {
            this.competitionService.createCompetition(this.garaForm.value).subscribe((res) => {
              this.router.navigate(['/dashboard/competitions/' + res.data.id]);
            });
          }
          if (command === 'Salva') {
            this.competitionService.updateCompetition(this.garaForm.value, this.competitionId).subscribe(() => {
              this.dettaglio = true;
              this.modifica = false;
              this.Disable = true;
              this.products = [];
              this.getCompetition();
              M.toast({html: 'Gara modificata con successo'});
            });
          }
        } else {
          M.toast({html: 'La data di termine non può essere precendente a quella di inizio'});
        }
      } else {
        M.toast({html: 'Inserisci tutti i campi'});
      }
    }

    if (this.titleButton === 'Modifica') {
      this.Disable = false;
      this.modifica = true;
      this.garaForm.enable();
      this.titleButton = 'Salva';
      this.command = 'Salva';
    }
  }

  endCompetition() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Sicuro di voler chiudere questa gara?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Termina gara',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Chiusa!',
          'La gara è stata chiusa con successo',
          'success'
        );
        this.competitionService.updateCompetitionStatus('ended', this.competitionId).subscribe(() => {
          this.getCompetition();
        });
      }
    });
  }

  openCompetition() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Sicuro di voler riaprire questa gara?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Riapri',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Riaperta!',
          'La gara è stata riaperta con successo',
          'success'
        );
        this.competitionService.updateCompetitionStatus('inserted', this.competitionId).subscribe(() => {
          this.getCompetition();
        });
      }
    });
  }

  cancelCompetititon() {
    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Sicuro di voler annullare questa gara?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Annulla',
      cancelButtonText: 'Chiudi'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Annullata!',
          'La gara è stata annullata con successo',
          'success'
        );
        this.competitionService.deleteCompetitionByid(this.competitionId).subscribe(() => {
          this.router.navigate(['/dashboard/competitions']);
        });
      }
    });
  }

  convertOrder() {
    this.competitionService.updateCompetitionStatus('orders', this.competitionId).subscribe(() => {
      this.getCompetition();
    });
    // api per convertire in ordini
  }

  checkQta(max, quantita) {
    if (quantita > max) {
      this.message = true;
      return 'alert';
    }
  }

  parse(quantita, row?, max?) {
    if (quantita) {
      if (parseFloat(quantita) <= max || !max) {
        if (row) {
          row.ordered_qty = parseFloat(quantita);
        }
        return parseFloat(quantita);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  Total(quantita: number, max: number, prezzo: number, prodotto: string) {
    if (quantita) {
      this.products.filter(x => x.description === prodotto)[0].total += this.parse(quantita, null, max) * prezzo;
    } else {
      this.products.filter(x => x.description === prodotto)[0].total += 0;
    }
  }

  removeTotal(prodotto: string, quantita: number, max: number, prezzo: number) {
    this.products.filter(x => x.description === prodotto)[0].total -= this.parse(quantita, null, max) * prezzo;
  }

  filterRDP(product?, supplierId?) {
    if (product) {
      this.filteredRDPs = this.competition.RDPsrows.filter(r => r.Product.description === product);
      this.filteredRDPs.map(e => {
        if (e.price === null) {
          e.price = 0;
        }
        if (e.qty === null) {
          e.qty = 0;
        }
        if (e.RDP.suppliersId) {
          e.supplierId = e.RDP.suppliersId;
          e.r_sociale = this.fornitori.find(f => f.id === e.RDP.suppliersId).r_sociale;
          e.supp_email_type =  this.fornitori.find(f => f.id === e.RDP.suppliersId).email_type;
        } else {
          e.r_sociale = e.RDP.supplier_rsociale_text;
        }

      });
      return this.filteredRDPs;
    }

    if (supplierId) {
      let temp = this.competition.RDPsrows.filter(r => r.RDP.suppliersId === supplierId);
      temp.map(e => {
        if (e.price === null) {
          e.price = 0;
        }
        if (e.qty === null) {
          e.qty = 0;
        }
        return e;
      });
      return temp;
    }
  }

  ViewAdd(type) {
    if (type === 'product') {
      this.nuovoProdotto = true;
    }
    if (type === 'supplier') {
      this.nuovoFornitore = true;
    }
    if (type === 'RDP') {
      this.nuovoRDP = true;
    }
  }

  showOrderModal() {
    this.orderForm.enable();
    this.fornitoriService.GetDDlterminiConsegna().subscribe((res) => {
      this.terminiresa = res.data;
    });
    this.fornitoriService.GetDDlTerminiPagamento().subscribe((res) => {
      this.terminipagamento = res.data;
    });
    this.getOrders();
    $('#orderModal').modal('open');
  }

  showNoteModal() {
    $('#NoteModal').modal({
        dismissible: false,
      }
    );
    $('#NoteModal').modal('open');
  }

  showModalProdotto(command) {
    if (command.action === 'Lista') {
      $('#prodottiModal').modal('open');
      $('#prodottiModal').height($('#tableprodotti').height());
    } else {
      this.prodottoForm.controls.internal_sku.setValue(command.value);
      $('#modalProdotto').modal('open');
    }
  }

  showModalFornitori(command, tag: boolean) {
    console.log('categorie', this.categories);
    this.hiddenFilter = true;
    $('#fornitoriModal').modal({
        dismissible: false,
      }
    );

    if (command === 'Lista' || command.action === 'Lista') {
      if (tag) {
        $('#fornitoriModal').modal('open');
        this.setCategories();
      } else {
        $('#suppliersModal').modal('open');
      }
    } else {
      this.fornitoreForm.enable();
      this.fornitoriService.GetDDlterminiConsegna().subscribe((res) => {
        this.terminiresa = res.data;
        console.log(this.terminiresa);
      });
      this.fornitoriService.GetDDlTerminiPagamento().subscribe((res) => {
        this.terminipagamento = res.data;
      });
      this.fornitoreForm.controls.r_sociale.setValue(command.value);
      $('#modalFornitore').modal('open');
    }
  }

  showRDPModal() {
    $('#modalRDP').modal({
        dismissible: false,
      }
    );
    $('#modalRDP').modal('open');
  }

  ShowAllegati(idrow?) {
    $('#AllegatiModal').modal('open');
  }

  ShowModalUpload(name?) {
    this.idAllegato = name;
    $('#UploadModal').modal('open');
  }

  closeModal(type?) {
    $('#prodottiModal').modal('close');
    if (type === 'RDP') {
      $('#modalRDP').modal('close');
    }
  }

  discardFile(i) {
    this.file.splice(i, 1);
    $('.tooltiped').tooltip('close');
  }

  SendRDP() {
    this.RDPs.map(e => {
      e.response_date = moment().format('YYYY-MM-DD');
      return e;
    });
    this.competitionService.updateCompetitionStatus('started', this.competitionId).subscribe(() => {
      this.getCompetition();
    });
  }

  getProdotto(event) {
    this.prodotto.internal_sku = event.name;
    this.prodotto.productsId = event.value;
    this.prodottiService.GetProductsById(event.value).subscribe((p: any) => {
      this.prodotto.description = p.data.description;
      p.data.categories.forEach((c: any) => {
        this.prodotto.categories.push(c.label);
      });
    });
  }

  updateProdotto(event, id) {
    this.prodottiService.GetProductsById(event.value).subscribe((p: any) => {
      this.deleteCategories(id);
      this.products[id].internal_sku = event.name;
      this.products[id].productsId = event.value;
      this.products[id].description = p.data.description;
      p.data.categories.forEach((c: any) => {
        this.products[id].categories.push(c.label);
      });
      console.log(this.products);
      this.setCategories();
    });
  }

  setCategories() {
    let trovato = false;
    const chip = [];
    console.log('quiiiii', this.products);
    this.products.forEach((p: any) => {
      if (p.categories && p.categories.length > 0) {
        p.categories.forEach((c: any) => {
          console.log(c);
          if (this.categories.length > 0) {
            this.categories.forEach((res: any) => {
              if (res === c) {
                trovato = true;
                if (!this.dettaglio) {
                  chip.push({type: 'Categoria prodotto', tag: c, icon: 'filter_list'});
                }
              }
            });
            if (!trovato) {
              if (!this.dettaglio) {
                this.categories.push(c);
                chip.push({type: 'Categoria prodotto', tag: c, icon: 'filter_list'});
              } else {
                this.categories.push(c.label);
                chip.push({type: 'Categoria prodotto', tag: c.label, icon: 'filter_list'});
              }
              console.log('primo if', chip);
            }
            trovato = false;
          } else {
            if (!this.dettaglio) {
              this.categories.push(c);
              chip.push({type: 'Categoria prodotto', tag: c, icon: 'filter_list'});
            } else {
              this.categories.push(c.label);
              chip.push({type: 'Categoria prodotto', tag: c.label, icon: 'filter_list'});
            }
            console.log('Secondo if', chip);
          }
          const categories: Array<any> = this.categoriesFilterFornitori.filters;
          categories.forEach((filter) => {
            if (filter.name === 'categories') {
              filter.options.forEach((option) => {
                if (!this.dettaglio) {
                  if (option.label === c) {
                    option.active = true;
                  }
                } else {
                  if (option.label === c.label) {
                    option.active = true;
                  }
                }
              });
            }
          });
        });
      }
    });
    if (this.categories.length > 0) {
      this.filter.fornitore.filters['categories'] = this.categories;
     // this.filter.fornitore['pagination'] = {pageSize: 4};
      console.log(chip);
      this.Chips = chip;
    }
    this.getSuppliers(this.filter.fornitore, this.categoriesFilterFornitori);
  }

  deleteCategories(id) {
    this.products[id].categories.forEach((c: any) => {
      if (this.categories.indexOf(c) !== -1) {
        this.categories.splice(this.categories.indexOf(c), 1);
      }
    });
  }

  AddProdotto() {
    console.log(this.prodotto);
    if (this.prodotto.internal_sku !== '' && this.prodotto.description !== '' && this.prodotto.requested_qty !== 0 && this.prodotto.requested_delivery_date !== '') {
      this.nuovoProdotto = false;
      if (this.products.find(p => p.productsId === this.prodotto.productsId) === undefined) {
        this.products.push(this.prodotto);
        this.setCategories();
        this.getFilteredSupplier();
      }
      this.prodotto = {
        productsId: 0,
        internal_sku: '',
        description: '',
        requested_qty: 0,
        requested_delivery_date: '',
        categories: []
      };
      console.log('Categorieeeeeee1e1', this.categories);
    }
  }

  addProdotto() {
    this.modificata = true;
    const chip = this.chips.nativeElement.textContent.split('close');
    chip.pop();
    this.prodottoForm.controls.categories.setValue(chip);

    if (this.prodottoForm.valid) {
      this.prodottiService.postOrdine(this.prodottoForm.value).subscribe((res: any) => {
        $('#modalProdotto').modal('close');
        this.modificata = false;
        this.getProducts();
      });
    } else {
      M.toast({html: 'Inserisci tutti i campi del prodotto'});
    }
  }

  getFornitore(event) {
    if (this.suppliers.findIndex(x => x.id === event.value) === -1) {
      this.suppliers.push(this.filteredFornitori[this.filteredFornitori.findIndex(x => x.id === event.value)]);
      this.nuovoFornitore = false;
    } else {
      M.toast({html: 'Fornitore già presente nella lista'});
    }
  }

  deleteProdotto(idProdotto) {
    // api per dlete prodotto
    this.deleteCategories(idProdotto);
    this.products.splice(idProdotto, 1);
    this.setCategories();
  }

  formControlName(abstractControl: AbstractControl, modificata) {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  changeAllCheckBox() {
    // funzione per cambiare background quando selezione tutte le rgihe
    this.checkrow = !this.checkrow;
    if (this.checkrow) {
      this.selected = [];
      this.suppliers.forEach((row) => {
        this.selected.push(row.id);
      });
      $('#tablefornitori').find('tbody').find('tr').addClass('selected-table');
      this.select = true;
      console.log(this.selected);
    } else {
      this.selected = [];
      $('tbody').find('tr').removeClass('selected-table');
      this.select = false;
      console.log(this.selected);
    }
  }

  checkBoxChange(input, idrow) {
    // funzione per cambiare background alla riga selezionata
    if (input.target.checked) {
      this.selected.push(idrow);
      $(input.path[3]).addClass('selected-table');
      this.select = true;
    } else {
      const index = this.selected.indexOf(idrow);
      console.log(index);
      this.selected.splice(index, 1);
      $(input.path[3]).removeClass('selected-table');
      if ($('tbody').find('.selected-table').length === 0) {
        this.select = false;
      }
    }
  }

  AddRDP() {
    if (this.RDP.suppliersId && this.RDP.Rows.price > 0 && this.RDP.Rows.productsId && this.RDP.Rows.qty > 0 && this.RDP.Rows.notes !== '' && this.RDP.Rows.delivery_date !== '') {
      console.log('Funziona?');
      this.RDP = {
        suppliersId: null,
        Rows: {
          productsId: null,
          price: 0,
          qty: 0,
          notes: '',
          delivery_date: ''
        }
      };
      this.nuovoRDP = false;
    }
  }

  hidefilter() {
    this.hiddenFilter = false;
  }

  deleteRDP(idRDP) {
    this.RDPs.splice(idRDP, 1);
    console.log(this.RDPs);
  }

  GetFornitori() {
    // this.hiddenFilter = false;
    let trovato = false;
    let temp;

    console.log('fucking selected', this.selected);

    this.selected.forEach((f: any) => {
      temp = this.fornitori.filter(supp => supp.id === f)[0];
      console.log(f);
      console.log('temp', temp);
      if (this.DMFornitori.length === 0) {
        console.log('primo if');
        this.DMFornitori.push({tag: temp.r_sociale, value: temp.id});
        this.supplierSelected.push(f);
      } else {
        console.log('else');
        if (this.DMFornitori.find(s => s.value === temp.id) !== undefined) {
          trovato = true;
          console.log('trovato true');
        }

        if (!trovato) {
          console.log('not trovato');
          this.DMFornitori.push({tag: temp.r_sociale, value: temp.id});
          this.supplierSelected.push(f);
        }
        trovato = false;
      }

    });

    $('#fornitoriChips').chips({
      data: this.DMFornitori,
      onChipDelete: (e, data) => {
        console.log(data.childNodes[0].textContent);
        this.deleteFornitori(data.childNodes[0].textContent);
      },
    });
  }

  SaveFornitore() {
    // creazione fornitore tramite Modal
    this.modificata = true;
    // inserimento
    const currency = {
      currency: this.fornitoreForm.controls.currency.value
    };
    this.fornitore = this.fornitoreForm.value;
    this.fornitore.price_list = currency;
    const chipmercio = this.chipMercio.nativeElement.textContent.split('close');
    chipmercio.pop();
    this.fornitore.commercial_categories = chipmercio;

    const chiprodotto = this.chipProdotti.nativeElement.textContent.split('close');
    chiprodotto.pop();
    this.fornitore.categories = chiprodotto;

    if (this.fornitoreForm.valid) {
      this.fornitoriService.CreateSupplier(this.fornitore).subscribe(() => {
        $('#modalFornitore').modal('close');
        this.getSuppliers();
      });
    }
  }

  Termini(event, type) {
    // funzione per prendere il valore della currency dall'autocomplete
    if (type === 'currency supplier') {
      this.fornitoreForm.controls.currency.setValue(event.value);
      this.fornitoreForm.controls.currency.markAsDirty();
    }
  }

  validateFormControl(abstractControl: AbstractControl, modificata): object {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  LabelActivation() {
    if (this.dettaglio || !this.dettaglio && this.modifica) {
      return 'active';
    }
  }

  DataPicker(data) {
    if (data === 'start') {
      this.garaForm.controls.startDate.setValue(this.startdate.nativeElement.value);
      this.garaForm.controls.startDate.markAsDirty();
      console.log(this.garaForm.value);
    }
    if (data === 'end') {
      this.garaForm.controls.endDate.setValue(this.endate.nativeElement.value);
      this.garaForm.controls.endDate.markAsDirty();
      console.log(this.garaForm.value);
    }
    // funzione per settere value datapicker
  }

  checkDate(start, newdate?, oldate?) {
    if (newdate) {
      if (new Date(start) < new Date(newdate)) {
        return newdate;
      } else {
        M.toast({html: 'Inserire una data dopo quella di inizio'});
        return oldate;
      }
    } else {
      let error = false;
      this.products.forEach((p: any) => {
        if (new Date(p.data_richiesta) < new Date(start)) {
          error = true;
        }
      });
      if (error) {
        M.toast({html: 'Alcune date di richiesta dei prodotti sono precedenti alla data di inizio della gara'});
      }
    }
  }

  searchfornitori(event) {
    this.suppliers = this.fornitori.filter(f => f.r_sociale.toLowerCase().includes(event.toLowerCase()));
  }

  clean() {
    if (this.user.group === 'supplier') {
      this.competitionService.getCompetitionById(this.competitionId).subscribe((res: any) => {
        this.competition = res.data;
      });
    }
    this.garaForm.reset();
    this.suppliers = [];
    this.products = [];
    this.selected = [];
    this.startdate.nativeElement.value = moment().format('YYYY-MM-DD');
    this.endate.nativeElement.value = '';
    this.nuovoFornitore = false;
    this.nuovoProdotto = false;
    this.DMFornitori = [];
  }

  cleanSupp() {
    this.getCompetition(true);
  }

  AnnullaModifche() {
    this.titleButton = 'Modifica';
    this.command = 'Modifica';
    this.modifica = false;
    this.products = [];
    this.getCompetition();
  }

  Note(notes) {
    if (this.rowId) {
      this.competition.RDPsrows.filter(r => r.id === this.rowId)[0].notes = notes;
      if (this.user.group === 'supplier') {
        this.updateRow(this.rowId, this.competition.RDPsrows.filter(r => r.id === this.rowId)[0].rdpId, 'notes', notes);
      } else {
        this.updateRow(this.rowId, this.competition.RDPsrows.filter(r => r.id === this.rowId)[0].rdpId, 'notes', notes, 'supplier');
      }

    } else {
      this.RDP.Rows.notes = notes;
    }
  }

  formatValue() {
    if (this.prodottoForm.get('price').value !== null) {
      this.prodottoForm.patchValue({price: this.numberPipe.transform(this.prodottoForm.get('price').value)});
    }
  }

  deleteFornitori(event) {
    for (let i = 0; i < this.fornitori.length; i++) {
      if (this.fornitori[i].r_sociale === event) {
        this.supplierSelected.splice(this.selected.indexOf(this.fornitori[i].id), 1);
        this.select = false;
      }
    }
    console.log(this.selected);
    // api per eliimina delle RDP del fornitore
  }

  disable() {
    if (this.Disable) {
      return 'dotted';
    }
  }

  updateRow(id, rpdId, label, value, type = 'supplier') {
    console.log(id);
    if (this.dettaglio) {
      const patch = {};
      let stop = false;
      if (value) {
        patch[label] = value;
      } else {
        patch[label] = null;
      }
      if (this.rdpsUpdate.length > 0) {
          this.rdpsUpdate.forEach((rdp) => {
            if (rdp.id === id) {
              console.log(' update object');
              rdp[label] =  value;
              stop = true;
            }
          });
          if (stop === false) {
            console.log('new update object');
            patch['id'] = id;
            patch['rdpId'] = rpdId;
            this.rdpsUpdate.push(patch);
          }
      } else {
        console.log('new');
        patch['id'] = id;
        patch['rdpId'] = rpdId;
        this.rdpsUpdate.push(patch);
      }

      console.log(this.rdpsUpdate);
      if (type === 'notSupp') {
      this.competitionService.UpdateRDProw(patch, rpdId, id).subscribe( () => {
        this.getCompetition('true');
      });
      }

    }
  }


  // Funzioni per multifiltro
  emitSearch(event, filterName) {
    console.log('save Search');
    if (event.action !== 'delete') {
      if (this.filter[filterName].search[event.property] === undefined) {
        this.filter[filterName].search[event.property] = [];
      }
      this.filter[filterName].search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter(filterName);
    }
  }

  saveFilter(event, filterName) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        if (this.filter[filterName].filters[event.type] === undefined) {
          this.filter[filterName].filters[event.type] = [];
        }
        this.filter[filterName].filters[event.type].push(event.value);
      } else {
        this.filter[filterName].filters[event.type] = event.value;
      }
    } else {
      delete this.filter[filterName].filters[event.type];
    }
    this.Searchwithfilter(filterName, event.cat);
  }

  Searchwithfilter(filterName, cat?) {
    console.log('apply');
    console.log('apply filter', this.filter);
    console.log('apply filter', cat);
    if (filterName === 'fornitore') {
      this.getSuppliers(this.filter[filterName], cat);
    } else {
      this.getRDP(this.filter[filterName], cat);
    }
    //   sessionStorage.setItem('filterCat', JSON.stringify(cat));
    //   sessionStorage.setItem('filter', JSON.stringify(this.filter));
  }

  deleteFilter(items, filterName) {
    console.log('delete chip');
    let categories: Array<any>;
    let categoriesFilters: Array<any>;
    this.filter[filterName] = {search: {}, filters: {}};
    let isSearch = false;
    if (filterName === 'fornitore') {
      this.Chips = items;
      categories = this.categoriesFilterFornitori.search;
      categoriesFilters = this.categoriesFilterFornitori.filters;
    } else {
      categories = this.categoriesFilterRdp.search;
      categoriesFilters = this.categoriesFilterRdp.filters;
    }
    console.log('lenght items', items.length);
    console.log('items', items);
    if (items.length > 0) {
      items.forEach((item) => {
        console.log('items foreach', item.tag);
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter[filterName].search[cat.name] === undefined) {
              this.filter[filterName].search[cat.name] = [];
            }
            this.filter[filterName].search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  console.log('false');
                  option.active = false;
                } else {
                  console.log('trueeeeeeeeee');
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Categoria merceologica' && item.type !== 'Categoria prodotto') {
//  per ora in questa lista non ci sono filtri a selezione multipla, quall'ora ci fossero mettere la stringa euqivalente a filter.label
                      this.filter[filterName].filters[filter.name] = option.value;
                      console.log('dentro if', this.filter);
                    } else {
                      if (this.filter[filterName].filters[filter.name] === undefined) {
                        this.filter[filterName].filters[filter.name] = [];
                        console.log('status init');
                      }
                      console.log(option.value);
                      this.filter[filterName].filters[filter.name].push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  console.log('no option', item.tag);
                  const res = item.tag.split(' ');
                  this.filter[filterName].filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    console.log('ciaooooo', this.categoriesFilterFornitori);
    console.log(this.filter);
    this.filter.fornitore['pagination'] = {pageSize: 4};
    if (filterName === 'fornitore') {
      this.Searchwithfilter(filterName, this.categoriesFilterFornitori);
    } else {
      this.Searchwithfilter(filterName, this.categoriesFilterRdp);
    }
  }

  getOrders() {
    this.competition.RDPsrows.forEach(r => {
      if (r.ordered_qty > 0) {
        this.order['r.RDP.id'] = this.orders;
        /*
        orders: {
          suppliersId: 0;
          currency: 0;
          delivery_terms: '';
          payment_terms: '';
          notes: '';
          address: '';
          referent: '',
        };
        */
      }
    });
    console.log(this.order);
  }

  pagination(page) {
    console.log(page);
    page['pageSize'] = 4;
    console.log(page);
    this.filter.fornitore['pagination'] = page;
    console.log(this.filter);
    this.getSuppliers(this.filter.fornitore);
  }

  onFilesAdded(files, type) {
    console.log(files);
    if (type === 'add') {
      files.addedFiles.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.addedFiles.forEach(file => {
        this.file.forEach(f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }
  }

  onRemove(event) {
    console.log('elemento rimossso');
    this.file.splice(this.file.indexOf(event), 1);
  }
}
