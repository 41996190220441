import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoughnutchartComponent } from './doughnutchart.component';
import {ChartsModule} from "ng2-charts";

@NgModule({
  declarations: [DoughnutchartComponent],
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [DoughnutchartComponent]
})
export class DoughnutchartModule { }
