import {Component, OnInit, ViewChild, ElementRef, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import { FornitoriService } from '@app/dashboard/fornitori/fornitori.service';
import {FormGroup, FormControl, Validators, AbstractControl, FormBuilder} from '@angular/forms';
import {Router, ActivatedRoute, RouterStateSnapshot, ParamMap} from '@angular/router';
import {OrdiniService} from '@app/dashboard/ordini/ordini.service';
import {UtilityService} from '@app/shared/utility.service';
import {ResizedEvent} from 'angular-resize-event';
import {HeightTabDirective} from '@app/shared/directive/heightTab.directive';
import * as moment from 'moment';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import {OrdiniListaModel} from '@app/shared/models/db_tables/ordini-lista.model';
import { DatePipe } from '@angular/common';
import { any } from 'codelyzer/util/function';
import Swal from 'sweetalert2';
import {SettingsService} from '@app/dashboard/settings/settings.service';
import {el} from '@angular/platform-browser/testing/src/browser_util';

declare const $: any;
declare const  M: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges {

  constructor( private fornitoriservice: FornitoriService,
               private router: Router,
               private ordersService: OrdiniService,
               private utilityService: UtilityService,
               private ngZone: NgZone,
               private activatedRoute: ActivatedRoute,
               private settingsService: SettingsService,
               private formBuilder: FormBuilder,
               private translate: TranslateService, ) {
                this.translate.onLangChange
                .subscribe((event: LangChangeEvent) => {
                  this.translate.use(event.lang);
                  this.translate.get('datatables', {value: 'world'}).subscribe((x: any) => {
                    console.log('ciao subscribe', x);
                    this.dtOptions = {
                      responsive: true,
                      autoWidth: false,
                      language: x,
                    };
                    this.dtOptionsLogTable = {
                      responsive: true,
                      autoWidth: false,
                      language: x,
                    };
                    this.refresh = false;
                  });
                  setTimeout(() => {
                    this.refresh = true;
                  }, 0);

                  this.translate.get('breadcrumbs.orders.list').subscribe((app: string) => {
                      utilityService.Navigation[this.basePosBreadcrumbs] = {title: app, path: '/orders'};
                    });

                  if (this.orderid) {
                    this.translate.get('breadcrumbs.orders.details').subscribe((app: string) => {
                      utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/orders/' + this.orderid};
                    });
                  } else {
                    this.translate.get('breadcrumbs.orders.new').subscribe((app: string) => {
                      utilityService.Navigation[this.posBreadcrumbs] = {title: app, path: '/orders/new'};
                    });
                  }

                  this.translate.get('Orders.common.supplier').subscribe((app: string) => {
                    this.translations.fornitore = app;
                  });

                  this.translate.get('Orders.common.type').subscribe((app: string) => {
                    this.translations.tipo = app;
                  });

                  this.translate.get('Orders.common.currency').subscribe((app: string) => {
                    this.translations.valuta = app;
                  });
                });
               }

  @ViewChild('fornitorinput') autocompletefornitori: ElementRef;
  @ViewChild('autocomplete') autocomplete: any;
  @ViewChild('date') dataemissione: ElementRef;
  @ViewChild('supplier') Supplier;
  @ViewChild('Currency') Currency;
  @ViewChild('ordertype') ordertype;
  @ViewChild('chipMercio') chipMercio: ElementRef;
  @ViewChild('chipProdotti') chipProdotti: ElementRef;
  @ViewChild(HeightTabDirective) heightDirective: HeightTabDirective;

  datatableElement: DataTableDirective;
  dtOptions: any = {};

  dtOptionsLogTable: any = {};
  fornitori: any;
  suppliers = [];
  app: any;
  data = { righe: []};
  fornitoriform: FormGroup = null;
  modificata = false;
  terminiresa;
  terminipagamento;
  addresses: any;
  refresh = true;
  rowData = [];
  rows = {
    righe: []
  };

  categoriesFilterFornitori: any;
  filter = {
    fornitore: {
      search: {},
      filters: {}
    }
  };
  terminiConsegna;
  terminiPagamento;
  fornitoreId;
  addordine = {
    productsId: null,
    sku: '',
    qty: null,
    price: null,
    descr: '',
    delivery_date: '',
    action: ''
  };

  referenti = [];

  fornitore;

  supplierId = -1;

  currencies = [];
  currency: any = '';

  type = [];

  listafornitori = [];
  filename = [];
  file = [];
  idAllegato;
  commentoRev;
  checkRevisione = false;


  orderid = null;

  dettaglio = false;

  order: OrdiniListaModel;

  // variabili per sezione public
  tokenpublic; // token per sezione pubblica
  disableTableRow = false;
  // fine variaibili per sezioni public

  reverseOrders = [];

  stato = '';

  ordini = [];

  user;

  intestazione: string;

  actionSupp: any;


  posBreadcrumbs;
  basePosBreadcrumbs;

  translations = {
    fornitore: '',
    tipo: '',
    valuta: '',
  };

  CatMerciologiche =  {};
  CatProdotto =  {};

  ordiniform = new FormGroup({
    suppliersId: this.utilityService.standardFormControl(true, false),
    emit_date: this.utilityService.standardFormControl(false, false),
    delivery_address: this.utilityService.standardFormControl(true, false),
    delivery_term: this.utilityService.standardFormControl(true, false),
    payment_term: this.utilityService.standardFormControl(true, false),
    packaging: this.utilityService.standardFormControl(false, false),
    currency: this.utilityService.standardFormControl(true, false),
    referentsId: this.utilityService.standardFormControl(true, false),
    orderType: this.utilityService.standardFormControl(true, false),
    rows: this.utilityService.standardFormControl(true, false),
    notes: this.utilityService.standardFormControl(false, false)
  });

  ordersnav;
  index;
  listaordiniModificata = [];
  ordinipatch = [];

ngOnInit() {

  this.user =  this.utilityService.user;
  if (!this.checkPermission('order:create')) {
    this.ordiniform.disable();
  }

  this.initForms();
  this.getCategories();

  this.getDDl();

  this.activatedRoute
      .paramMap
      .subscribe((paramsMap: ParamMap) => {
        // se l'url ha un id lo inserisce nelle variabile, se questo avviene vuol dire che si sta navigando nella pagina di modifica
        this.orderid = +paramsMap.get('id');
        this.tokenpublic = +paramsMap.get('token');
        this.actionSupp = paramsMap.get('action');
        if (this.tokenpublic) {
          this.ordiniform.disable();
          this.disableTableRow = true;
        }
        console.log(this.orderid);
        if (this.orderid) {

         this.getOrder(null, 'start');

        } else {
          this.translate.get('breadcrumbs.orders.new').subscribe((app: string) => {
            this.posBreadcrumbs = this.utilityService.Navigation.length;
            this.utilityService.Navigation.push({title: app, path: '/orders/new'});
          });

          this.settingsService.getCurrentCompany().subscribe((res: any) => {
            let trovato = false;
            res.data.Addresses.forEach( (ind) => {
              if (ind.default === true) {
                this.ordiniform.controls.delivery_address.setValue(ind.id);
                trovato = true;
              }
            });
            if (!trovato) {
              this.ordiniform.controls.delivery_address.setValue(res.data.Addresses[0].id);
            }
            console.log(this.ordiniform.controls);
            $('select').formSelect();
          });
           // setto la data emissione ad oggi
          this.ordiniform.controls.emit_date.setValue(moment().format('YYYY-MM-DD'));
          this.dettaglio = false;
          this.intestazione = 'Nuovo ordine';
        }

        this.translate.get('Orders.common.supplier').subscribe((app: string) => {
          this.translations.fornitore = app;
        });

        this.translate.get('Orders.common.type').subscribe((app: string) => {
          this.translations.tipo = app;
        });

        this.translate.get('Orders.common.currency').subscribe((app: string) => {
          this.translations.valuta = app;
        });

        this.stato = 'Draft';
      });


  this.getSuppliers();

  $('.tabs').tabs();
  $('.tooltipped').tooltip();

  this.setPrint();
  this.disableButtons(this.stato);
  $('select').formSelect();
  }

  getDDl() {
  // prende i valori per le select e gli autocomplete
    this.ordersService.GetCurrentCompanies().subscribe((res: any) => {
      console.log('res: ', res);
      this.addresses = res.data.Addresses;
      console.log('addresses: ', this.addresses);
    });

    this.ordersService.GetDDlterminiConsegna().subscribe((res: any) => {
      this.terminiConsegna = res.data;
    });
    this.ordersService.GetDDlTerminiPagamento().subscribe((res: any) => {
      this.terminiPagamento = res.data;
    });

    this.settingsService.getCurrentCompany().subscribe((res: any) => {
      console.log('LE CURRENCY VANNP: ', res);
      this.settingsService.getCurrencyList(res.data.id).subscribe((val: any) => {
        val.data.forEach((v: any) => {
          this.currencies.push({value: v.id, label: v.label});
        });
        console.log(this.currencies);
      });
    });

    this.ordersService.GetType().subscribe((res: any) => {
      this.type = res.data;
    });
  }

  getOrder(loader = 'false', command?) {
    this.ordersService.getOrdine(this.orderid).subscribe((res: any) => {
      this.order = res.data;
      this.order.emit_date = this.order.emit_date.slice(0, 10);
      this.rowData = this.order.rows;
      if (this.actionSupp) {
        let ids = [];
        this.rowData.forEach((row) => {
          ids = row.id;
        });
        console.log(ids);
        if (this.actionSupp === 'accepted') {
          this.ordersService.UpdateStatusOrder(ids, 'accepted').subscribe(() => {
            this.getOrder();
          });
        } else if (this.actionSupp === 'canceled') {
          this.ordersService.UpdateStatusOrder(ids, 'canceled').subscribe(() => {
            this.getOrder();
          });
        }
      }
      this.stato = res.data.status;
      if (command === 'start') {
        this.listaordiniModificata = JSON.parse(JSON.stringify(this.rowData));
        this.listaordiniModificata.map(e => {
          e.action = null
          return e;
        });
      }
      this.rows.righe = this.listaordiniModificata;
      this.ordersnav = res.stats.resultSetIDs;
      this.index = res.stats.resultSetIDs.indexOf(this.orderid) + 1;

      this.posBreadcrumbs = this.utilityService.Navigation.length;
      this.utilityService.Navigation.push({title: this.order.code, path: '/orders/' + this.orderid});

      this.ordiniform.patchValue(this.order);
      this.GetReverseOrder();
      this.dettaglio = true;
      this.intestazione = this.order.code;
    });
  }

  DataPicker() {
  // funzione per settere value datapicker
    this.ordiniform.controls.emit_date.setValue(this.dataemissione.nativeElement.value);
    this.ordiniform.controls.emit_date.markAsDirty();
  }

  getSuppliers(filters?, cat = null) {
  // riceve lista fornitori ma struttura l'array in modo tale che venga acccettato dall'autocomlpete
    this.fornitoriservice.getListaTable('false', this.translate.currentLang, filters).subscribe((x: any) => {
      this.suppliers = [];
      x.data.map(
        (y: any) => (
          this.app = {label: y.r_sociale, value: y.id},
            this.suppliers.push(this.app)
        )
      );

      this.listafornitori = x.data;
      x.data.map(
        (y: any) => (
          this.app = {id: y.id, referents: y.referents},
            this.referenti.push(this.app)
        )
      );
      if (this.dettaglio) {
        this.supplierId = this.referenti.findIndex(x => x.id === this.order.suppliersId);
      }
      if (cat) {
        this.categoriesFilterFornitori = cat;
        console.log('cat', this.categoriesFilterFornitori);
      } else {
        this.categoriesFilterFornitori = x.filtersList;
        console.log('res', this.categoriesFilterFornitori);
      }
    });
  }

  GetReverseOrder() {
  // prende tutto lo storico delle modifiche dell'ordine
    this.ordersService.getReverseOrders(this.orderid).subscribe((res: any) => {
      this.reverseOrders = res.data;
    });
  }

  infoFornitore(type, id) {
    if (type === 'add') {
      // se il fornitore che selezioniamo ha gia settati termini di pagamento e di consegna vengono direttamente settati anche essi
      this.fornitoriservice.getSupplierById(id).subscribe((res: any) => {
        this.fornitori = res.data;
        this.ordiniform.controls.suppliersId.setValue(this.fornitori.id);
        this.ordiniform.controls.suppliersId.markAsDirty();
        if (this.order) {
          this.order.suppliersId = this.fornitori.id;
        }
        this.currency = this.fornitori.price_list.currency;
        this.ordiniform.controls.delivery_term.setValue(this.fornitori.delivery_term);
        this.ordiniform.controls.delivery_term.markAsDirty();
        this.ordiniform.controls.payment_term.setValue(this.fornitori.payment_term);
        this.ordiniform.controls.payment_term.markAsDirty();
        if (this.fornitori.referents.length === 1) {
          this.ordiniform.controls.referentsId.setValue(this.fornitori.referents[0].id);
          this.ordiniform.controls.referentsId.markAsDirty();
        }
        $('select').formSelect();
      });
    }
    if (type === 'delete') {
      // this.ordiniform.controls.term_resa.reset();
      // this.ordiniform.controls.term_pagamento.reset();
    }
  }


 /* setStatus(stato) {
    if (stato = '') {
>>>>>>> 8a5955d5e418f20379af8694a6de48d4fa98f0c7
      return 'Bozza';
    } else {
      return stato;
    }
  }*/

  Fornitore(event) {
    this.fornitoreId = event.value;
    this.infoFornitore(event.action, event.value);
    // foreach per cercare il supplierID per select fornitori
    this.supplierId = this.referenti.findIndex(x => x.id === event.value);

    if (this.supplierId === -1) {
      $('.referenti').prop('disabled', true);
    } else {
      $('.referenti').removeAttr('disabled');
    }
    setTimeout(() => {
      $('select').formSelect();
    }, 10);
  }

  /*
  Termini(event, type) {
    console.log('termini');

    if (type === 'delivery') {
      this.ordiniform.controls.delivery_term.setValue(event.name);
    }

    if (type === 'payment') {
      this.ordiniform.controls.payment_term.setValue(event.name);
    }
  }
*/
  Termini(event, type) {
    // funzione per prendere il valore della currency dall'autocomplete
    if (type === 'currency') {
      this.ordiniform.controls.currency.setValue(event.value);
      this.ordiniform.controls.currency.markAsDirty();
    } else if (type === 'currency supplier') {
      this.fornitoriform.controls.currency.setValue(event.value);
      this.ordiniform.controls.currency.markAsDirty();
    }
  }

  Type(event, type) {
    if (type === 'type') {
      this.ordiniform.controls.orderType.setValue(event.value);
    }
  }


  SaveOrdine() {
    if (this.dettaglio) {
      // modifica ordine
      console.log('dettaglio');
      this.UpdateOrdine();
    } else {
      // Crea nuovo Ordine
      this.CreateOrder();
    }
  }

  CreateOrder() {

    this.ordiniform.controls.rows.setValue(this.data.righe);
    //  this.ordiniform.controls.currency.setValue('EUR');

    if (this.ordiniform.valid) {
      this.ordersService.CreateOrders(this.ordiniform.value).subscribe((res: any) => {
        this.router.navigate( ['/dashboard/orders/' + res.data.id]);
        });
    } else {
      M.toast({html: 'Compila tutti i campi'});
    }
  }

  UpdateOrdine() {
    // Modifica ordine
    const dirtyFields = {};
    for (const control in this.ordiniform.controls) {
      if (this.ordiniform.get(control).dirty) {
        console.log(this.ordiniform.get(control));
        dirtyFields[control] = this.ordiniform.get(control).value;
      }
    }
    this.listaordiniModificata.forEach(L => {
      if (L.action === 'update') {
        this.ordersService.UpdateRows(this.orderid, L.id, L).subscribe(() => {
          this.getOrder();
        });
      }
      if (L.action === 'add') {
        this.ordersService.CreateRows(this.orderid, L).subscribe(() => {
          this.getOrder();
        });
      }
      if (L.action === 'delete' && (L.id !== null || undefined)) {
        this.ordersService.DeleteRows(this.orderid, L.id).subscribe(() => {
          this.getOrder();
        });
      }
    });

    this.ordersService.UpdateOrders(this.orderid, dirtyFields).subscribe((res) => {
      this.router.navigate(['/dashboard/orders/' + this.orderid]);
    });

  }

  sendOrdine() {
    /*this.ordersService.CreateOrders(this.ordini).subscribe();*/
    const ids = {
      ids: this.rowData.map(r => r.id)
    };
    if (this.user.group === 'supplier') {
      this.ordersService.UpdateStatusOrder(ids, 'proposed_supplier').subscribe((res) => {
        this.getOrder();
      });
    } else {
      this.ordersService.UpdateStatusOrder(ids, 'proposed_buyer').subscribe((res) => {
        this.getOrder();
      });
    }
    console.log('culo');
  }

  acceptedOrder() {
    const ids = {
      ids: this.rowData.map(r => r.id)
    };
    if (this.user.group === 'supplier' && this.stato === 'proposed_supplier') {
      this.ordersService.UpdateStatusOrder(ids, 'proposed_supplier').subscribe((res) => {
        this.getOrder();
      });
    } else {
      this.ordersService.UpdateStatusOrder(ids, 'proposed_buyer').subscribe((res) => {
        this.getOrder();
      });
    }
  }

  clear() {
    if (this.dettaglio) {
      this.ordersService.getOrdine(this.orderid).subscribe((res: any) => {
        this.order = res.data;
        console.log(this.order);
        this.order.emit_date = this.order.emit_date.slice(0, 10);
        console.log(this.order.emit_date);
        this.rowData = this.order.rows;
        this.listaordiniModificata = this.rowData;
        this.listaordiniModificata.map(e => {
          e.action = null;
          return e;
        });
        this.rows.righe = this.listaordiniModificata;
        this.ordiniform.patchValue(this.order);
      });
    } else {
      // this.ordiniform.reset();
      // $('select').formSelect();
      // this.Supplier.clear();
      // this.Currency.clear();
      // this.ordiniform.controls.emit_date.setValue(moment().format('YYYY-MM-DD'));
      console.log(this.ordiniform);
      if (this.ordiniform.dirty || this.rows !== null) {
        Swal.fire({
          title: 'Sicuro di voler procedere?',
          text: 'Non sarai in grado di recuperare le modifiche fatte',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.value) {
            Swal.fire(
              'Annullata!',
              '',
              'success'
            );
            this.router.navigate(['/dashboard/orders']);
          }
        });
      } else {
        this.router.navigate(['/dashboard/orders']);
      }
    }
  }

  AddOrdine() {
    // crea una riga d'ordine se siamo in dettaglio la invia al server.
    // In fase di creazione non viene creata tramite server ma viene messe in un oggetto che poi sarà inviato insieme alla creazione dell'intero ordine.
    if (this.dettaglio) {
      this.addordine.action = 'add';
      console.log(this.addordine);
      this.listaordiniModificata.push(this.addordine);
      this.getOrder('true');
      this.addordine = {
        productsId: null,
        sku: '',
        qty: null,
        price: null,
        descr: '',
        delivery_date: '',
        action: ''
      };
    } else {
      let id = null;
      // ricavo id per nuova riga
      console.log(this.data);
      if (this.data !== undefined) {
        this.data.righe.forEach((riga) => {
          id = riga.id + 1;
        });
      } else {
        id = 0;
      }
      // this.addordine.sku = id;
      // carico il nuovo ordine
      console.log('aggiungtariga', this.addordine);
      this.data.righe.push(this.addordine);
      console.log(this.data);
      this.addordine = {
        productsId: null,
        sku: '',
        qty: null,
        price: null,
        descr: '',
        delivery_date: '',
        action: '',
      };
    }
  }

  deleteOrdine(id) {
    // cancella una riga d'ordine
    console.log(id);


    Swal.fire({
      title: 'Sei sicuro?',
      text: 'Non sarai in grado di recuperare questa voce del listino',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Elimina',
      cancelButtonText: 'Annulla'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminata!',
          'La voce del listino è stata eliminata con successo',
          'success'
        );
        this.listaordiniModificata[id].action = 'delete';
      }
    });
  }

  patchRow(data) {
    // Modifica di una riga chiamata dal tablerowcomponent

    console.log(data);
    console.log(this.listaordiniModificata);

    if (this.listaordiniModificata[data.i].action === 'update' || this.listaordiniModificata[data.i].action === null) {
      this.listaordiniModificata[data.i].action = 'update';
      this.listaordiniModificata[data.i][data.label] = data.value;
    }
  }

  onResize(resize: ResizedEvent) {
    // funzione per settare altezza card con la Tab piu alta
    console.log('ciao', resize);
    this.heightDirective.setHeight(resize.newHeight);
  }

  validateFormControl(abstractControl: AbstractControl, modificata): object {
    return this.utilityService.FormClassValidator(abstractControl, modificata);
  }

  initForms() {
    // inizializzazione Form
    this.fornitoriform = this.formBuilder.group({
      r_sociale: this.utilityService.standardFormControl(),
      piva: this.utilityService.standardFormControl(),
      indirizzo_consegna:  this.utilityService.standardFormControl(),
      tel: this.utilityService.telephoneFormControl(true, 10, '', true),
      email: this.utilityService.emailFormControl(),
      website: this.utilityService.standardFormControl(),
      delivery_term: this.utilityService.standardFormControl(),
      payment_term: this.utilityService.standardFormControl(),
      currency: this.utilityService.standardFormControl(),
      fax: this.utilityService.telephoneFormControl(true, 10, '', true),
    });
  }

  getCategories() {
    this.fornitoriservice.getCategoriesCommercia().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatMerciologiche[cat.name] = null;
      });
    });
    this.fornitoriservice.getCategoriesProducts().subscribe((res) => {
      res.data.forEach((cat) => {
        this.CatProdotto[cat.label] = null;
      });
    });
  }

  showModal(command) {
    if (command.action === 'Lista') {
      $('#fornitoriModal').modal('open');
    } else {
    this.fornitoriform.enable();
    this.fornitoriservice.GetDDlterminiConsegna().subscribe((res) => {
      this.terminiresa = res.data;
      console.log(this.terminiresa);
    });
    this.fornitoriservice.GetDDlTerminiPagamento().subscribe((res) => {
      this.terminipagamento = res.data;
      console.log(this.terminipagamento);
    });
    this.fornitoriform.controls.r_sociale.setValue( command.value);
    $('#modalFornitore').modal('open');
  }
  }

  SaveFornitore() {
    // creazione fornitore tramite Modal
    console.log('currency modal', this.fornitoriform.controls.currency.value);
    const currency = {
      currency: this.fornitoriform.controls.currency.value
    };

    this.fornitori = this.fornitoriform.value;
    this.fornitori.referents = [];
    this.fornitori.price_list = currency;

    const chipmercio = this.chipMercio.nativeElement.textContent.split('close');
    chipmercio.pop();
    this.fornitori.commercial_categories = chipmercio;

    const chiprodotto = this.chipProdotti.nativeElement.textContent.split('close');
    chiprodotto.pop();
    this.fornitori.categories = chiprodotto;

    /*
    if (this.check !== 'none') {
      if (this.comunication !== 'none') {
        this.fornitori.email_type = this.check;
      }
      this.fornitori.notification_level = this.comunication;
      if (this.comunication === 'secondary_mail') {
        this.fornitori.email2 = this.email.nativeElement.value;
      }
    }
*/
    this.fornitoriservice.CreateSupplier(this.fornitori).subscribe((res) => {
      $('#modalFornitore').modal('close');
      this.getSuppliers();
    });
  }

  onFilesAdded(files: File[], type) {
    console.log(files);
    if (type === 'add') {
      files.forEach(file => {
        this.file.push({name: file.name, last_update: file.lastModified, descr: null, revisioni: {}});
        this.filename.push(file.name);
      });
    } else {
      files.forEach(file => {
        this.file.forEach( f => {
          if (this.idAllegato === f.name) {
            f.revisioni = {name: file.name, commento: this.commentoRev, last_update: file.lastModified};
          }
        });
      });
    }

    console.log(this.file);
  }

  SaveFile() {
    // chiamata api per Salvare
     this.filename = [];
     console.log(this.checkRevisione);
   }

   ShowModalUpload(name?) {
    if (name) {
      this.idAllegato = name;
    }
    $('#ModalUpAllegati').modal('open');
  }

  discardFile(i) {
    this.file.splice(i, 1);
    $('.tooltiped').tooltip('close');
    console.log(this.file);
  }

  Showdescription() {
    setTimeout(() => { M.textareaAutoResize($('#textareaDescription')); }, 10);
  }

  ShowAllegati(type, idrow?) {
    if (type === 'allegati') {
      // api per allegati
    } else {
      // api per revisioni
    }
    $('#AllegatiModal').modal('open');
  }


  /* annulla() {
    console.log(this.order);
    this.ordiniform.patchValue(this.order);
    $('select').formSelect();
    console.log(this.ordiniform.value);
  } */

  printOrdine() {
    // Stampa Order
  }

  deleteOrder() {
    this.ordersService.DeleteOrders(this.order.id).subscribe(() => {
      this.router.navigate(['/dashboard/orders']);
      this.clear();
    });
  }

  ActivateLabel(dettaglio) {
    if (dettaglio) {
      return 'active';
    }
  }


  // da cambiare quando vediamo a simo e decidiamo i buttoni
  ngOnChanges(changes: SimpleChanges) {
    if (changes.stato.currentValue !== changes.stato.previousValue) {
      this.setPrint();
      this.disableButtons(this.stato);
    }
  }

  disableButtons(stato) {
    if (stato === 'Draft' || stato === 'Confirmed') {
      $('.bottoni').prop('disabled', true);
    } else {
      $('.bottoni').removeAttr('disabled');
    }
  }

  setPrint() {
    if (this.stato === 'Draft') {
      $('.stampa').prop('disabled', true);
    } else {
      $('.stampa').removeAttr('disabled');
    }
  }

  checkPermission(section) {
    const exist = this.user.permissions.find((element) => {
      return element === section;
    });
    if (this.user.permissions[0] === '*:*') {
      return true;
    }
    if (exist) {
      return true;
    } else {
      return false;
    }
  }

  setFornitore(id) {
    this.Fornitore({value: id, action: 'add'});
    $('#fornitoriModal').modal('close');
  }


  // Funzioni per multifiltro
  emitSearch(event, filterName) {
    console.log('save Search');
    if (event.action !== 'delete') {
      if (this.filter[filterName].search[event.property] === undefined) {
        this.filter[filterName].search[event.property] = [];
      }
      this.filter[filterName].search[event.property].push(event.name);
      // this.filter.search[event.property] = event.name;
      this.Searchwithfilter(filterName);
    }
  }

  saveFilter(event, filterName) {
    if (event.value !== 'null') {
      if (event.selection === 'multi') {
        if (this.filter[filterName].filters[event.type] === undefined) {
          this.filter[filterName].filters[event.type] = [];
        }
        this.filter[filterName].filters[event.type].push(event.value);
      } else {
        this.filter[filterName].filters[event.type] = event.value;
      }
    } else {
      delete this.filter[filterName].filters[event.type];
    }
    this.Searchwithfilter(filterName, event.cat);
  }

  Searchwithfilter(filterName, cat?) {
    console.log('apply');
    console.log('apply filter', this.filter);
    console.log('apply filter', cat);
    if (filterName === 'fornitore') {
      this.getSuppliers(this.filter[filterName], cat);
    }
    //   sessionStorage.setItem('filterCat', JSON.stringify(cat));
    //   sessionStorage.setItem('filter', JSON.stringify(this.filter));
  }

  deleteFilter(items, filterName) {
    console.log('delete chip');
    let categories: Array<any>;
    let categoriesFilters: Array<any>;
    this.filter[filterName] = {search: {}, filters: {}};
    let isSearch = false;
    if (filterName === 'fornitore') {
      categories = this.categoriesFilterFornitori.search;
      categoriesFilters = this.categoriesFilterFornitori.filters;
    }
    console.log('lenght items', items.length);
    console.log('items', items);
    if (items.length > 0) {
      items.forEach((item) => {
        console.log('items foreach', item.tag);
        categories.forEach((cat) => {
          if (cat.label === item.type) {
            isSearch = true;
            if (this.filter[filterName].search[cat.name] === undefined) {
              this.filter[filterName].search[cat.name] = [];
            }
            this.filter[filterName].search[cat.name].push(item.tag);
          }
        });
        if (isSearch === false) {
          categoriesFilters.forEach((filter) => {
            if (filter.options && filter.options !== null) {
              filter.options.forEach((option) => {
                if (option.label !== item.tag && option.block !== true) {
                  console.log('false');
                  option.active = false;
                } else {
                  console.log('trueeeeeeeeee');
                  if (!option.block) {
                    option.active = true;
                    option.block = true;
                    if (item.type !== 'Categoria merceologica' && item.type !== 'Categoria prodotto') {
//  per ora in questa lista non ci sono filtri a selezione multipla, quall'ora ci fossero mettere la stringa euqivalente a filter.label
                      this.filter[filterName].filters[filter.name] = option.value;
                      console.log('dentro if', this.filter);
                    } else {
                      if (this.filter[filterName].filters[filter.name] === undefined) {
                        this.filter[filterName].filters[filter.name] = [];
                        console.log('status init');
                      }
                      console.log(option.value);
                      this.filter[filterName].filters[filter.name].push(option.value);
                    }
                  }
                }
              });
            } else {
              // console.log('no option', filter.label);
              if (filter.label !== item.type && filter.block !== true) {
                filter.active = false;
              } else {
                if (!filter.block) {
                  filter.active = true;
                  filter.block = true;
                  console.log('no option', item.tag);
                  const res = item.tag.split(' ');
                  this.filter[filterName].filters[filter.name] = {from: res[1], to: res[3]};
                }
              }
            }
          });
        }
      });
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            delete option.block;
          });
        } else {
          delete filter.block;
        }
      });
    } else {
      categoriesFilters.forEach((filter) => {
        if (filter.options) {
          filter.options.forEach((option) => {
            option.active = false;
            delete option.block;
          });
        } else {
          filter.active = false;
          delete filter.block;
        }
      });
    }
    if (filterName === 'fornitore') {
      this.Searchwithfilter(filterName, this.categoriesFilterFornitori);
    }
  }
}
